<template>
  <span :class="$style.wrapper">
    <component :is="component" :class="$style.icon" />
  </span>
</template>
<script>
import { defineAsyncComponent } from 'vue'
const lookup = {
  academic: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/academic.svg?inline')),
  'airport-delays': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/airport-delays.svg?inline'),
  ),
  community: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/community.svg?inline')),
  concerts: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/concerts.svg?inline')),
  conferences: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/conferences.svg?inline')),
  'daylight-savings': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/daylight-savings.svg?inline'),
  ),
  disasters: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/disasters.svg?inline')),
  expos: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/expos.svg?inline')),
  festivals: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/festivals.svg?inline')),
  'health-warnings': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/health-warnings.svg?inline'),
  ),
  observances: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/observances.svg?inline')),
  'performing-arts': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/performing-arts.svg?inline'),
  ),
  politics: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/politics.svg?inline')),
  'public-holidays': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/public-holidays.svg?inline'),
  ),
  'school-holidays': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/school-holidays.svg?inline'),
  ),
  'severe-weather': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/event-cat/severe-weather.svg?inline'),
  ),
  sports: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/sports.svg?inline')),
  terror: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/terror-attacks.svg?inline')),
  tv: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/event-cat/tv.svg?inline')),
}

export const iconKeys = Object.keys(lookup)

export default {
  props: {
    icon: {
      type: String,
      required: true,
      validator: (value) => {
        return iconKeys.includes(value)
      },
    },
  },
  setup(props) {
    return {
      component: lookup[props.icon],
    }
  },
}
</script>

<style lang="scss" module>
@import '~@phq/scss/globals';

.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $icon-size;
  height: $icon-size;
}

.icon {
  width: $icon-size;
  height: $icon-size;
}
</style>
