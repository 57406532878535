<template>
  <div :class="bem('')" v-bg-change="{ color: 'pink' }">
    <AlertPanel
      v-if="!loading && !event"
      :class="bem('alert')"
      class="wrap v-space-mt-5"
      title="Event not found"
      variant="error"
    >
      This event is missing or otherwise unavailable.
    </AlertPanel>
    <AlertPanel
      v-else-if="!loading && event && event.state === 'predicted'"
      :class="bem('alert')"
      class="wrap v-space-mt-5"
      icon="brand/logo"
      title="This is a Predicted Event"
    >
      Predicted Events are pending confirmation. Details like location, start time and end time may not yet be
      finalized. When confirmed, they will become an Active event.
      <template #action>
        <AppLink variant="secondary" to="https://www.predicthq.com/support/predicted-events">
          Learn more
        </AppLink>
      </template>
    </AlertPanel>
    <AppCta class="sides v-space-mt-5 v-space-mb-5" :subheading="cta.title" align="left" fullWidth>
      <template #body>
        {{ cta.text }}
      </template>
      <template #links>
        <AppButton to="https://www.predicthq.com/contact/sales">Talk to an expert</AppButton>
        <AppButton variant="secondary" to="https://signup.predicthq.com/">Get Started</AppButton>
      </template>
    </AppCta>

    <div v-if="event" :class="bem('event')">
      <div class="wrap">
        <EventHeader
          :event="event"
          @upgrade="handleUpgrade"
          :isPolygonEvent="isPolygonEvent"
          :access="addons"
          sharable
        />

        <div :class="bem('event-body')">
          <div :class="bem('left')">
            <EventDetailsBlock :event="event" :access="addons" />
            <EventVenue :event="event" />
            <AppMap :class="bem('map')" :events="[event]" :location="location" :isPolygonEvent="isPolygonEvent" />
          </div>
          <div :class="bem('right')">
            <!-- Event Spend box -->
            <EventPredictedSpend
              v-if="typeof event.predictedSpend === 'number'"
              :predictedSpend="event.predictedSpend"
              :predictedSpendIndustries="event.predictedSpendIndustries"
            />

            <div :class="bem('calendar')">
              <Calendar :from="event.startLocal" :to="event.endLocal" :disabled="true" />
            </div>
            <!-- Replaced viewership section with Advert -->
            <div :class="bem('viewership')" ref="viewership">
              <Showcase />
              <AdvertSignup />
            </div>
          </div>
        </div>
      </div>

      <AppCta
        includeWhiteBg
        class="sides v-space-mt-12"
        title="Want to get instantly notified when events like this are happening?"
        fullWidth
      >
        <template #links>
          <AppButton to="https://www.predicthq.com/notifications">Learn about real-time alerts</AppButton>
        </template>
      </AppCta>
    </div>
    <LoadingEvent class="wrap" v-if="loading || !event" />
  </div>
</template>

<script>
// Utils
import { getDeepestCommonId } from '@/utils/getDeepestCommonId'
import { getSecondaryEventAddress } from '@/utils/getSecondaryEventAddress'
import dom from '@/app/dom'
import numeral from 'numeral'
import Events from '@/app/events'

// Modules (Sections)
import AdvertSignup from '@phq/advert-signup'
import AppCta from '@phq/app-cta'
import AppMap from '@phq/map'
import Calendar from '@phq/calendar'
import EventDetailsBlock from '@phq/event-details'
import EventHeader from '@phq/event-header'
import EventVenue from '@phq/event-venue'
import LoadingEvent from '@phq/loading-event'
import Showcase from '@phq/showcase'
import EventPredictedSpend from '@phq/event-predicted-spend'

// bit.dev components
import AlertPanel from '@predicthq/vue3.components.alert-panel'
import AppButton from '@predicthq/vue3.components.app-button'
import AppLink from '@predicthq/vue3.components.app-link'

export default {
  name: 'EventDetails',
  components: {
    AdvertSignup,
    AlertPanel,
    AppButton,
    AppCta,
    AppLink,
    Calendar,
    EventDetailsBlock,
    EventPredictedSpend,
    EventHeader,
    EventVenue,
    LoadingEvent,
    AppMap,
    Showcase,
  },
  metaInfo() {
    return {
      robots: {
        tag: 'meta',
        name: 'robots',
        content: `noindex, nofollow`,
      },
    }
  },
  data() {
    return {
      event: null,
      places: [],
      loading: false,
    }
  },
  computed: {
    cta() {
      return {
        title:
          this.event?.state === 'deleted'
            ? 'Archived Event'
            : 'You are viewing the public version of this PredictHQ event',
        text:
          this.event?.state === 'deleted'
            ? `This event has been archived because it has either been canceled, postponed, contains incorrect information, was
      detected as spam or was a duplicate event.`
            : `PredictHQ is the demand intelligence company combining real-world events into one global source of truth to
        help businesses better understand demand and plan for the future.`,
      }
    },
    isPolygonEvent() {
      return ['LineString', 'Polygon', 'MultiPolygon'].includes(this.event?.geometry?.type)
    },
    addons() {
      return ['phq', 'local', 'phq-attendance']
    },
    location() {
      return {
        ...this.event.location,
        address: this.event.singleLineAddress || this.event.secondarySingleLineAddress,
        geometry: {
          type: 'Point',
          coordinates: this.event.location.coords,
        },
        properties: {
          title: this.event.title,
          rankLevel: this.event.rank?.level,
        },
      }
    },
  },
  methods: {
    async getRegion(event) {
      // Pick deepest common id within event's places hierarchies
      const placeId = getDeepestCommonId(event)

      if (placeId === null) return null

      const { results } = await this.$store.dispatch('places/search', {
        id: placeId,
      })

      return getSecondaryEventAddress(results[0])
    },

    formatNumber(value) {
      if (!value && value !== 0) return ''
      return numeral(value).format('0,0')
    },
    handleUpgrade() {
      window
        .open(
          'https://www.predicthq.com/contact/sales?utm_source=phq_public_pages&utm_medium=public-event-upgrade&utm_campaign=organic',
          '_blank',
        )
        .focus()
    },
    async fetchEvent() {
      this.$progressBar.show()
      this.loading = true

      // Reset values
      this.event = null
      this.places = []

      try {
        this.$store
          .dispatch('publicEvents/get', {
            id: this.$route.params.id,
            state: 'active,deleted,predicted',
          })
          .then(async (searchResult) => {
            if (searchResult.results.length === 0) {
              throw new Error('Event not found')
            }
            const event = searchResult.results[0]

            dom.setPageTitle(event.title)
            /*
             * If no address present - request event's Region
             */
            if (event.singleLineAddress === '') {
              // Above API call is failing becuase ACCESS TOKEN used in K8s-prod helmrelease file doesn't have correct scopes to make this additional palce API call
              // TODO: Update the Access token in K8s-prod helmrelease then uncomment the below code and delete line below it
              // event.secondarySingleLineAddress = await this.getRegion(event)
              event.secondarySingleLineAddress = event.singleLineLocation || ''
            }

            window.scrollTo(0, 0)

            this.$progressBar.hide()
            this.loading = false

            this.event = event

            this.scrollToViewership()
          })
          .catch((err) => {
            this.$progressBar.hide()
            this.loading = false
            console.error(err)
            this.$messages.show('Event not found', {
              type: 'warning',
            })
          })
      } catch (err) {
        this.$progressBar.hide()
        this.loading = false
        console.error(err)
        this.$messages.show('Event not found', {
          type: 'warning',
        })
      }
    },
    // TODO: currently none of the property for viewership is used because viewership is not part of public event page
    scrollToViewership() {
      const hash = window.location.hash
      if (hash && hash === '#broadcasts') {
        /**
         * Scroll to the viewership panel in the event details page
         * Set timeout to get an accurate offset value after the page is rendered
         */
        setTimeout(() => {
          const top = this.$refs.viewership.offsetTop
          window.scroll({
            top: top,
            behavior: 'smooth',
          })
        }, 1000)
      }
    },
  },
  watch: {
    $route: 'fetchEvent',
  },
  created() {
    this.fetchEvent()
  },
}
</script>

<style lang="scss" module>
@import '~@phq/scss/globals';

.event-details {
  $root: &;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  --vertical-padding: 32px;

  &__event {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__event-body {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    flex-direction: column;
    flex: 2 1 100%;

    > * {
      flex: 0 0 auto;
    }

    @media (min-width: $br-l) {
      flex: 2 1 60%;
    }
  }

  &__map {
    flex: 1 1 auto;
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    justify-content: flex-start;

    @media (min-width: $br-l) {
      flex: 1 1 365px;
      margin-left: 8px;
      max-width: 365px;
    }
  }

  &__calendar {
    background: $c-white;
    flex: 0 1 auto;
    margin-bottom: 8px;
    padding: var(--vertical-padding) var(--side);
  }

  &__viewership {
    background-color: $c-white;
    flex: 1 1 auto;
    padding: var(--vertical-padding) var(--side);
  }

  &__alert {
    width: 100%;
  }
}
</style>
