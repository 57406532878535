<template>
  <ModalCard :open="open" :class="bem('')" @close="closeModal" heading="Share" :modalClass="bem('modal')">
    <div :class="bem('share')">
      <AppInput :id="elementId" ref="value" readonly :modelValue="shareUrl" />
      <AppButton type="button" ref="button" :data-clipboard-target="`#${elementId}`">Copy</AppButton>
    </div>
    <div :class="bem('social')">
      <BaseButton :to="facebookUrl">
        <AppIcon icon="social/facebook" v-tooltip="'Facebook'" />
      </BaseButton>
      <BaseButton :to="twitterUrl">
        <AppIcon icon="social/twitter" v-tooltip="'Twitter'" />
      </BaseButton>
      <BaseButton :to="linkedinUrl">
        <AppIcon icon="social/linkedin" v-tooltip="'LinkedIn'" />
      </BaseButton>
      <!-- Mailto url is currently not supported by ':to' prop, hence using programmatically navigation to open new tab -->
      <BaseButton @click="sendMail">
        <AppIcon icon="social/contact" v-tooltip="'Email'" />
      </BaseButton>
    </div>
  </ModalCard>
</template>

<script>
import Clipboard from 'clipboard'
// import config from '@/app/config'

import ModalCard from '@phq/modal-card'
import AppButton from '@predicthq/vue3.components.app-button'
import AppIcon from '@predicthq/vue3.components.icon'
import AppInput from '@predicthq/vue3.components.app-input'
import BaseButton from '@predicthq/vue3.components.base-button'


export default {
  name: 'ShareModal',
  data() {
    return {
      clipboard: null,
    }
  },
  components: {
    AppButton,
    AppIcon,
    AppInput,
    BaseButton,
    ModalCard,
  },
  props: {
    open: Boolean,
    title: String,
    variant: {
      type: String,
      default: 'event',
      validator: (val) => ['event', 'location'].includes(val),
    },
  },
  computed: {
    shareUrl() {
      return location.href
    },
    elementId() {
      return `share-${this.uuid}`
    },
    mailTo() {
      const body = encodeURI(
        `Hey!\r\n\r\nThought you would be interested in this ${this.variant} I found on PredictHQ:\r\n\r\n${this.shareUrl}`,
      )
      return `mailto:?subject=${this.text}&body=${body}`
    },
    facebookUrl() {
      return `http://www.facebook.com/share.php?u=${this.shareUrl}&title=${this.text}`
    },
    twitterUrl() {
      return `http://twitter.com/home?status=${this.text}+${this.shareUrl}`
    },
    linkedinUrl() {
      return `http://www.linkedin.com/shareArticle?mini=true&url=${this.shareUrl}&title=${this.text}&source=predicthq.com`
    },
    text() {
      return encodeURI(`Take a look at this ${this.variant} on PredictHQ: ${this.title}`)
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    sendMail() {
      window.open(this.mailTo, '_blank')
    },
  },
  mounted() {
    this.$refs.value.$refs.input.focus()
    this.$refs.value.$refs.input.setSelectionRange(0, this.$refs.value.$refs.input.value.length)
    this.clipboard = new Clipboard(this.$refs.button.$el)

    this.clipboard.on('success', (e) => {
      this.$messages.show('Link has been copied')

      e.clearSelection()
    })

    this.clipboard.on('error', () => {
      this.$messages.show('Link could not be copied sorry. Please use CTRL+C to copy.', { type: 'warning' })
    })
  },
  beforeUnmount() {
    if (this.clipboard !== null) {
      this.clipboard.destroy()
      this.clipboard = null
    }
  },
}
</script>
<style lang="scss" module src="./share-modal.scss" />
