import { ref } from 'vue'

export default function useExpand() {
  const expanded = ref(false)

  const toggleExpanded = () => {
    expanded.value = !expanded.value
  }

  return { expanded, toggleExpanded }
}
