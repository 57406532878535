<template>
  <a :href="linkUrl" :class="bem('', { hasHover, large: isLarge })">
    <span :class="bem('card-box')">
      <span :class="bem('customer-logo')">
        <picture v-if="typeof logoImage === 'object'">
          <source
            media="(min-width: 1025px) and (max-width: 1352px), (max-width: 380px) "
            :srcset="`${logoImage.small['1x']}, ${logoImage.small['2x']} 2x`"
          />
          <img :alt="logoImage.alt" :src="logoImage.normal['1x']" :srcset="`${logoImage.normal['2x']} 2x`" />
        </picture>

        <img
          v-if="typeof logoImage === 'string'"
          :alt="logoImageAlt"
          :src="logoImage"
          :style="{ width: logoImageWidth }"
        />
      </span>

      <span :class="bem('details')">
        <span :class="bem('spacer')" />
        <p :class="bem('text')">{{ text }}</p>
        <AppLink :class="bem('link')">{{ linkTitle }}</AppLink>
      </span>
    </span>
  </a>
</template>

<script>
import AppLink from '@predicthq/vue3.components.app-link'

export default {
  name: 'AppCustomerCard',
  components: {
    AppLink,
  },
  props: {
    logoImage: [Object, String],
    logoImageAlt: String,
    logoImageWidth: String,
    text: {
      type: String,
      default: '',
    },
    linkUrl: String,
    linkTitle: String,
    hasHover: {
      type: Boolean,
      default: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped src="./app-customer-card.scss" />
