import { defineAsyncComponent } from 'vue'
export default {
  docs: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/docs.svg?inline')),
  plans: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/docs.svg?inline')),
  teams: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/teams.svg?inline')),
  nav: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/menu.svg?inline')),
  'nav-close': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/close.svg?inline')),
  recent: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/recent.svg?inline')),
  refresh: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/refresh.svg?inline')),
  search: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/search.svg?inline')),
  settings: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/settings.svg?inline')),
  support: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/support.svg?inline')),
  error: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/form/error.svg?inline')),
  alert: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/alert.svg?inline')),
  chat: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/chat.svg?inline')),
  'contract-lg': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/contract-lg.svg?inline')),
  'contract-sm': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/contract-sm.svg?inline')),
  'expand-lg': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/expand-lg.svg?inline')),
  'expand-sm': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/expand-sm.svg?inline')),
  notifications: defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/products-tools/notifications.svg?inline'),
  ),
  export: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/export.svg?inline')),
  top: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/top.svg?inline')),
  'arrow-left': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/arrow-left.svg?inline')),
  'arrow-right': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/arrow-right.svg?inline')),
  arrow: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/bullet/arrow.svg?inline')),
  success: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/form/success.svg?inline')),
  share: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/share.svg?inline')),
  remove: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/remove.svg?inline')),
  'download-simple': defineAsyncComponent(() =>
    import('@predicthq/vue3.assets.icons/common/download-simple.svg?inline'),
  ),
  'refresh-lg': defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/common/refresh.svg?inline')),
  fullscreen: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/full-screen.svg?inline')),
  more: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/nav/more.svg?inline')),
  facebook: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/social/facebook.svg?inline')),
  twitter: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/social/twitter.svg?inline')),
  github: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/social/github.svg?inline')),
  linkedin: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/social/linkedin.svg?inline')),
  contact: defineAsyncComponent(() => import('@predicthq/vue3.assets.icons/social/contact.svg?inline')),
}
