<template>
  <header :class="[$style.header, { [$style.sticky]: sticky }]" v-scroll="handleScroll" id="main-header">
    <div class="wrap" :class="$style['header__inner']">
      <div :class="$style['header__logo']">
        <a :href="redir"><img src="/images/logo.svg" alt="PredictHQ Logo" /></a>
      </div>
      <div :class="$style['header__nav']">
        <ul v-if="breakpoints.l" :class="$style['header__nav-list']">
          <li :class="$style['header__nav-item']" v-for="(item, idx) in menu" :key="idx">
            <a :class="$style['header__nav-link']" :href="item.url">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div :class="$style['header__actions']">
        <a :class="$style['header__account-link']" :href="Login.url">{{ Login.title }}</a>
        <a :class="[$style['header__account-link'], $style['header__account-link--primary']]" :href="GetStarted.url">
          {{ GetStarted.title }}
          <Icon inline :class="$style['header__account-arrow']" color="current" icon="common/arrow-right" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import Icon from '@predicthq/vue3.components.icon'
import breakpoints from '../breakpoints'
import { Pages } from '@/data/pages.js'
import { ref } from 'vue'

export default {
  setup() {
    const redir = 'https://www.predicthq.com/'
    const sticky = ref(false)
    const { UseCase, Intelligence, Products, About, Docs, GetStarted, Login } = Pages

    const handleScroll = () => {
      sticky.value = window.scrollY > 80
    }

    /** menu */
    const menu = {
      UseCase,
      Intelligence,
      Products,
      About,
      Docs,
    }

    return {
      breakpoints,
      GetStarted,
      Login,
      handleScroll,
      menu,
      sticky,
      redir,
    }
  },
  components: {
    Icon,
  },
  props: {
    title: String,
  },
}
</script>

<style lang="scss" module>
@import '~@phq/scss/globals';

.header {
  z-index: $z-header;
  padding: 0 var(--side);
  position: -webkit-sticky; /* Safari */
  position: relative;
  top: 0;
  transition: ease background-color 0.3s, ease box-shadow 0.2s;
  $root: &;

  &.sticky {
    animation: slide-down-y 0.3s $ease-out-Cubic;
    background-color: $c-white;
    box-shadow: 0px 0px 16px rgba(156, 123, 123, 0.08);
    position: sticky;
    top: 0;
  }

  &__inner {
    border-bottom: 1px solid $c-line-10;
    height: var(--header-height);
    display: flex;
    align-items: center;

    .sticky & {
      border-bottom: 0;
    }
  }

  &__logo {
    display: inline-block;

    img {
      display: block;
      width: 122px; // 122 instead of 120 so looks more sharp

      @media (min-width: $br-600) {
        width: 152px;
      }
    }
  }

  &__nav {
    flex-grow: 1;
    padding-right: 20px;
  }

  &__nav-list {
    display: flex;
    flex-flow: row;
    list-style: none;
    justify-content: center;
  }

  &__nav-item {
    margin: 0 20px;
  }

  &__nav-link {
    color: $c-dark;
    position: relative;
    text-decoration: none;

    &:hover {
      color: $c-brand-purple;
    }

    &:active {
      color: $c-brand-dark-purple;
      top: 1px;
    }
  }

  &__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 20px;
    }
  }

  &__account-link {
    color: $c-dark;
    text-decoration: none;
    transition: ease color 0.2s;

    &:hover {
      color: $c-brand-purple;
    }

    &--primary {
      color: $c-brand-pink;
      font-weight: 600;
      position: relative;
      transition: ease color 0.2s, ease transform 0.3s;

      &:before {
        background-color: $c-brand-pink;
        bottom: -1px;
        content: '';
        left: 0;
        position: absolute;
        height: 2px;
        right: 8px;
      }

      &:hover {
        color: $c-brand-pink-light;

        &:before {
          background-color: $c-brand-pink-light;
        }
      }

      &:active {
        color: $c-brand-pink-dark;

        &:before {
          background-color: $c-brand-pink-dark;
        }
      }
    }
  }

  &__account-arrow {
    transition: ease transform 0.3s;
    bottom: -12px;
    position: absolute;
    right: 0;

    #{$root}__account-link:hover & {
      transform: translateX(2px);
    }
  }
}
</style>
