<template>
  <div :class="bem('')">
    <div :class="bem('inner')" class="sides">
      <div class="wrap">
        <div :class="bem('content')">
          <Typography variant="4xl">Well, we didn’t predict that...</Typography>
          <Typography v-if="error && errorType === 500" variant="p">{{ error.message }}</Typography>
          <Typography variant="p" v-else>The page you're trying to access doesn't appear to exist.</Typography>
          <AppButton to="/">Back home</AppButton>
        </div>
      </div>
    </div>
    <div :class="bem('banner')">
      <div :class="bem('banner-wrap')">
        <img :class="bem('banner-image')" src="/images/placeholder/error-404.jpg" alt="Error 404" />
      </div>
    </div>
  </div>
</template>

<script>
import { NotFoundError } from '@/app/error'
import AppButton from '@predicthq/vue3.components.app-button'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'error',
  props: {
    error: {
      type: Error,
      required: false,
    },
  },
  computed: {
    errorType() {
      let type = 404

      if (this.error instanceof NotFoundError) {
        // Type remains as 404
        type = 404
      } else if (this.error instanceof Error) {
        type = 500
      }

      return type
    },
  },
  components: {
    AppButton,
    Typography,
  },
}
</script>
<style lang="scss" scoped>
@import '~@phq/scss/globals';

.error {
  &__inner {
    @media (min-width: $br-l) {
      align-items: center;
      display: flex;
      min-height: 585px;
    }
  }

  &__content {
    padding-bottom: 48px;
    padding-top: 55px;
    position: relative;
    z-index: 1;

    h1 {
      margin-bottom: 24px;
    }

    p {
      color: $c-mid;
      margin: 24px 0;
    }
  }

  &__banner {
    overflow: hidden;
    width: 100%;

    @media (max-width: $br-l - 1) {
      mask-image: linear-gradient(0deg, #ebf6fb 30%, rgba(235, 246, 251, 0) 100%);
    }

    @media (min-width: $br-l) {
      position: absolute;
      top: -130px;
      bottom: 0;
      right: 0;
      mask-image: linear-gradient(0deg, #ebf6fb 50%, rgba(235, 246, 251, 0) 98%);
    }
  }

  &__banner-wrap {
    position: relative;
    height: 100%;

    @media (max-width: $br-l - 1) {
      $map: ($br-l - 1: 580px, $br-m: 375px, $br-320: 320px);
      @include fluid('height', $map);
    }
  }

  &__banner-image {
    object-fit: cover;
    display: block;
    z-index: -1;

    @media (max-width: $br-l - 1) {
      object-position: center;
      width: 100%;
      height: 100%;
    }

    @media (min-width: $br-l) {
      mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      top: 0;
      left: 25%;
      height: 100%;
      width: calc(25% + 50vw);
      object-position: center top;
    }
  }
}
</style>
