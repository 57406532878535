<template>
  <div :class="bem('')">
    <a
      href="https://www.predicthq.com/apis?utm_source=phq_public_pages&utm_medium=300x250&utm_campaign=organic"
      target="_blank"
      v-if="!breakpoints.t"
      rel="nofollow"
    >
      <img src="@/assets/img/api-2-300x250.png" />
    </a>
    <a
      href="https://www.predicthq.com/apis?utm_source=phq_public_pages&utm_medium=300x600&utm_campaign=organic"
      target="_blank"
      class="mobile-hide"
      v-if="breakpoints.t"
      rel="nofollow"
    >
      <img width="300" height="600" src="@/assets/img/api-2-300xx600.png" />
    </a>
  </div>
</template>

<script>
import breakpoints from '../breakpoints'

export default {
  name: 'Showcase',
  data() {
    return {
      breakpoints,
    }
  },
}
</script>

<style lang="scss" module src="./showcase.scss" />
