<template>
  <div :class="bem('')">
    <div :class="bem('header')">
      <Typography :class="bem('title')" v-if="title" variant="xs">{{ title }}</Typography>
      <div :class="bem('block-wrapper')">
        <div :class="bem('badge')"><AppBadge v-if="total !== null" :content="formattedTotal" /> {{ label }} </div>
        <Typography :class="bem('hint')" v-if="hint" variant="p-xs">{{ hint }}</Typography>
      </div>
      <div>
        <slot />
      </div>
    </div>
    <slot name="content" />
  </div>
</template>

<script>
import AppBadge from '@predicthq/vue3.components.app-badge'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'TableHeader',
  components: {
    AppBadge,
    Typography,
  },
  props: {
    hint: String,
    label: String,
    title: String,
    total: Number,
  },
  computed: {
    formattedTotal() {
      return typeof this.total === 'number' ? this.total.toLocaleString() : this.total
    },
  },
}
</script>

<style lang="scss" module src="./table-header.scss" />
