import config from '@/app/config.js'

const {
  PHQ_WEBSITE_URL: website,
  PHQ_API_DOCS_URL: techdocs,
  SIGNUP_URL: signup,
  CONTROL_CENTER_URL: controlcentre,
} = config

export const Pages = {
  /* Support
-------------------------------------------------- */
  Support: {
    title: 'Support',
    url: `${website}/support`,
    icon: 'common/support',
    clickable: true,
  },
  /* Docs
  -------------------------------------------------- */
  Docs: {
    title: 'Docs',
    url: `${techdocs}`,
    icon: 'common/docs',
  },

  Documentation: {
    title: 'Documentation',
    url: `${techdocs}`,
    icon: 'common/docs',
  },

  DevelopersQuickstart: {
    title: 'Developers Quickstart',
    url: `${techdocs}/guides/quickstart/`,
  },

  DataScienceQuickstart: {
    title: 'Data Science Quickstart',
    url: `${techdocs}/datascience/introduction/`,
  },

  SystemStatus: {
    title: 'System Status',
    url: 'http://www.predicthqstatus.com',
  },
  GetStarted: {
    title: 'Get Started',
    url: signup,
  },
  Login: {
    title: 'Login',
    url: controlcentre,
  },
  /* Use Cases
-------------------------------------------------- */
  UseCase: {
    title: 'Use Cases',
    url: `${website}/use-cases`,
  },

  DemandForecasting: {
    title: 'Demand Forecasting',
    url: `${website}/use-cases/demand-forecasting`,
  },
  Visualizations: {
    title: 'Visualizations Insights',
    url: `${website}/use-cases/visualizations-insights`,
  },
  EventVisibility: {
    title: 'Event Visibility',
    url: `${website}/use-cases/event-visibility`,
  },
  DynamicPricing: {
    title: 'Dynamic Pricing',
    url: `${website}/use-cases/dynamic-pricing`,
  },
  WorkforceOptimization: {
    title: 'WorkforceOptimization',
    url: `${website}/use-cases/workforce-optimization`,
  },
  InventoryManagement: {
    title: 'Inventory Management',
    url: `${website}/use-cases/inventory-management`,
  },
  CustomerStories: {
    title: 'Demand Forecasting',
    url: `${website}/use-cases/demand-forecasting`,
  },

  /* About
-------------------------------------------------- */
  About: {
    title: 'About',
    url: `${website}/company`,
  },
  OurTeam: {
    title: 'Our Team',
    url: `${website}/company`,
    icon: 'common/team',
  },

  Careers: {
    title: 'Careers',
    url: `${website}/careers`,
    icon: 'common/careers',
  },

  Press: {
    title: 'Press',
    url: `${website}/press`,
    icon: 'common/press',
  },

  OurStory: {
    title: 'Our Story',
    url: `${website}/our-story`,
    icon: 'common/press',
  },

  Blog: {
    title: 'Blog',
    url: `${website}/blog`,
    icon: 'common/blog',
  },
  ContactUs: {
    title: 'Contact Us',
    url: `${website}/contact`,
    icon: 'social/contact',
  },

  PrivacyPolicy: {
    title: 'Privacy Policy',
    url: `${website}/legal/privacy`,
  },

  TermsService: {
    title: 'Terms of Service',
    url: `${website}/legal/terms`,
  },

  /* Products
  -------------------------------------------------- */
  Products: {
    title: 'Products',
    url: `${website}/apis`,
  },
  Rankings: {
    title: 'Rankings',
    subheading: 'Identify event impact based on attendance, location, and air travel',
    url: `${website}/features/rankings`,
    icon: 'rank/ranking',
  },

  PhqRank: {
    title: 'PHQ Rank',
    url: `${website}/features/rankings/phq-rank`,
    icon: 'rank/phq',
  },

  LocalRank: {
    title: 'Local Rank',
    url: `${website}/features/rankings/local-rank`,
    icon: 'rank/local',
  },

  AviationRank: {
    title: 'Aviation Rank',
    url: `${website}/features/rankings/aviation-rank`,
    icon: 'rank/aviation',
  },

  AggregateEventImpact: {
    title: 'Aggregate Event Impact',
    shortTitle: 'AEI',
    subheading: `Understand the true event impact by aggregating events`,
    url: `${website}/features/aggregate-event-impact`,
    icon: 'beam/graph',
  },

  LiveTvEvents: {
    title: 'Live TV Events',
    subheading: 'Predict how TV viewership impacts demand',
    url: `${website}/features/live-tv-events`,
    icon: 'products-tools/live-tv',
  },

  PredictedAttendance: {
    title: 'Predicted Attendance',
    subheading: 'Verified estimates for each event to understand impact',
    url: `${website}/features/event-impact-data#predicted-attendance`,
    icon: 'common/predicted-attendance',
  },

  EventImpactData: {
    title: 'Event Impact Data',
    shortTitle: 'Impact Data',
    subheading: 'Attendance, viewership and flight data to integrate into models',
    url: `${website}/features/event-impact-data`,
    icon: 'products-tools/event-impact-data',
  },

  ForecastingFeatures: {
    title: 'Forecasting Features',
    subheading: 'Features we’ve built to make integrating event data into your models easier',
    url: `${website}/features/forecasting-features`,
    icon: 'common/forecasting-features',
  },
  APIs: {
    title: 'APIs',
    subheading: 'Different API access points depending on your needs, from a featurized API to a granular event API',
    url: `${website}/apis`,
    icon: 'products-tools/api',
  },

  EventAPI: {
    title: 'Event API',
    subheading: 'A single source of truth for accurate forecasting and operational platforms',
    url: `${website}/apis/event-api`,
    icon: 'products-tools/api',
  },

  FeaturesAPI: {
    title: 'Features API',
    url: `${website}/apis/features-api`,
    icon: 'products-tools/features-api',
  },

  Beam: {
    title: 'Beam',
    subheading: 'An automated correlation engine to improve forecasting accuracy',
    url: `${website}/beam`,
    icon: 'products-tools/beam',
  },

  Notifications: {
    title: 'Notifications',
    subheading: 'Instant alerts about event changes and updates',
    url: `${website}/notifications`,
    icon: 'products-tools/notifications',
  },

  Pricing: {
    title: 'Pricing',
    url: `${website}/pricing`,
    icon: 'common/pricing',
  },

  Tools: {
    title: 'Tools',
    url: `${website}/tools`,
    icon: 'products-tools/tools',
  },

  ControlCenter: {
    title: 'Control Center',
    subheading: 'Discover, manage, and search data through a central hub',
    url: `${website}/tools/control-center`,
    icon: 'products-tools/cc',
  },

  Search: {
    title: 'Search',
    subheading: 'Search and filter our database to find the most relevant data',
    url: `${website}/tools/control-center/search`,
    icon: 'products-tools/search',
  },

  Loop: {
    title: 'Loop',
    subheading: 'Provide feedback on events and get them updated in our database',
    url: `${website}/tools/loop`,
    icon: 'products-tools/loop',
  },

  DataExporter: {
    title: 'Data Exporter',
    subheading: 'Explore our API and seamlessly download as a CSV or JSON',
    url: `${website}/tools/data-exporter`,
    icon: 'products-tools/api-exporter',
  },

  TableauConnector: {
    title: 'Tableau Connector',
    url: `${website}/tools/tableau-connector`,
    icon: 'products-tools/tableau',
  },
  /* Customer Stories
  -------------------------------------------------- */
  Customers: {
    title: 'Customer Stories',
    url: `${website}/customers`,
  },
  /**
   * Industries
  -------------------------------------------------- */
  Industries: {
    title: 'Industries',
    url: `${website}/industries`,
  },
  /* Intelligence
  -------------------------------------------------- */
  Intelligence: {
    title: 'Intelligent Data',
    url: `${website}/intelligence`,
  },
  OurDataPipeline: {
    title: 'Our Data Pipeline',
    url: `${website}/intelligence`,
    icon: 'pipeline/pipeline',
  },
  DataQuality: {
    title: 'Data Quality',
    url: `${website}/intelligence/data-quality`,
  },
  DataEnrichment: {
    title: 'Data Enrichment',
    url: `${website}/intelligence/data-enrichment`,
  },
  EventCategories: {
    title: 'Event Categories',
    url: `${website}/intelligence/data-enrichment/event-categories`,
  },
  Prediction: {
    title: 'Prediction',
    url: `${website}/intelligence/prediction`,
  },
  Correlation: {
    title: 'Correlation',
    url: `${website}/intelligence/prediction/correlation`,
  },

  /* Partners
  -------------------------------------------------- */

  Partners: {
    title: 'Partnerships',
    url: `${website}/partners`,
    icon: 'common/partners',
  },

  Amadeus: {
    title: 'Amadeus',
    url: `${website}/partners/amadeus`,
    icon: 'common/industries',
  },

  AntuitAi: {
    title: 'Antuit.ai',
    url: `${website}/partners/antuit-ai`,
    icon: 'common/industries',
  },

  Accenture: {
    title: 'Accenture',
    url: `${website}/partners/accenture`,
    icon: 'common/industries',
  },
}
