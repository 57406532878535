import NProgress from 'nprogress'
import * as R from 'ramda'
// import 'nprogress/nprogress.css'

// @link http://ricostacruz.com/nprogress/

export default {
  install(app) {
    NProgress.configure({
      showSpinner: false,
      minimum: 0.2,
      speed: 600,
      // easing: 'ease'
    })

    app.config.globalProperties.$progressBar = {
      show() {
        // Animate the PredictHQ logo
        R.map((el) => {
          el.classList.add('loading')
        }, document.getElementsByClassName('logo'))

        R.map((el) => {
          el.classList.add('loading')
        }, document.getElementsByClassName('full-logo'))

        return NProgress.start()
      },
      hide() {
        // Stop animating the PredictHQ logo
        R.map((el) => {
          el.classList.remove('loading')
        }, document.getElementsByClassName('logo'))

        R.map((el) => {
          el.classList.remove('loading')
        }, document.getElementsByClassName('full-logo'))

        return NProgress.done()
      },
    }
  },
}
