import { merge } from 'lodash'
import Tooltip from 'tooltip.js'

function getTooltipFromBinding(el, binding) {
  let options = {
    title: binding.value,
    template: '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
    delay: {
      show: 100,
      hide: 100,
    },
    popperOptions: {
      modifiers: {
        shift: {
          fn: (data) => {
            if (data.placement === 'top') {
              data.offsets.popper.top = data.offsets.popper.top - 5
            } else if (data.placement === 'bottom') {
              data.offsets.popper.top = data.offsets.popper.top + 5
            } else if (data.placement === 'right') {
              data.offsets.popper.left = data.offsets.popper.left + 5
            } else if (data.placement === 'left') {
              data.offsets.popper.left = data.offsets.popper.left - 5
            }

            return data
          },
        },
      },
    },
  }

  if (binding.modifiers) {
    if (binding.modifiers.left) {
      options.placement = 'left'
    } else if (binding.modifiers.right) {
      options.placement = 'right'
    } else if (binding.modifiers.top) {
      options.placement = 'top'
    } else if (binding.modifiers.bottom) {
      options.placement = 'bottom'
    }
  }

  /**
   * The 'overflow' modifier forces the tooltip to overflow outside its main container
   */
  if (binding.modifiers.overflow) {
    options = merge(options, {
      container: 'body',
      popperOptions: {
        modifiers: {
          preventOverflow: {
            boundariesElement: document.body,
          },
        },
      },
    })
  }

  return new Tooltip(el, options)
}

export const TooltipDirective = {
  mounted(el, binding) {
    if (binding.value) {
      el.tooltip = getTooltipFromBinding(el, binding)
    }
  },
  updated(el, binding) {
    if (el.tooltip && binding.value !== binding.oldValue) {
      el.tooltip.dispose()
      if (binding.value) {
        el.tooltip = getTooltipFromBinding(el, binding)
      }
    }
  },
  unmounted(el) {
    if (el.tooltip) {
      el.tooltip.dispose()
    }
  },
}
