<template>
  <Modal :open="open" @close="$emit('close')" @before-open="$emit('before-open')" :class="bem('')">
    <div :class="[bem('modal'), modalClass]">
      <div :class="bem('header')">
        <div :class="bem('header-content')">
          <Typography v-if="heading || $slots.heading" variant="event" :class="bem('heading')">
            <template v-if="heading">
              {{ heading }}
            </template>
            <template v-else-if="$slots.heading">
              <slot name="heading" />
            </template>
          </Typography>

          <Typography v-if="sub" variant="p-sm" :class="bem('sub')">
            {{ sub }}
          </Typography>
        </div>

        <div :class="bem('header-action')" v-if="$slots.action">
          <slot name="action" />
        </div>

        <div :class="bem('header-close')" @click="$emit('close')">
          <AppIcon icon="nav/close" color="current" inline />
        </div>
      </div>

      <div :class="bem('content')">
        <slot />
      </div>
    </div>
  </Modal>
</template>

<script>
import AppIcon from '@predicthq/vue3.components.icon'
import Modal from '@phq/modal'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'ModalCard',
  components: {
    AppIcon,
    Modal,
    Typography,
  },
  props: {
    /*
     * Modal's open / close state
     */
    open: {
      type: Boolean,
      required: true,
    },
    /*
     * Modal's heading
     */
    heading: {
      type: String,
    },
    /*
     * Modal's sub-heading
     */
    sub: {
      type: String,
    },
    /*
     * Modal's class - for custom width
     */
    modalClass: [String, Object, Array],
  },
}
</script>

<style lang="scss" module src="./modal-card.scss" />
