<template>
  <span :class="bem('')">
    <slot />
    <Caret :class="bem('caret')" :direction="caretDir" />
  </span>
</template>

<script>
import Caret from '@phq/caret'

export default {
  name: 'TableSortLabel',
  components: {
    Caret,
  },
  props: {
    /*
     * If true, the label will have the active styling (should be true for the sorted column).
     */
    active: {
      type: Boolean,
    },
    /*
     * The current sort direction.
     */
    direction: {
      type: String,
      validator: (value) => ['asc', 'desc'].includes(value),
      default: 'asc',
    },
  },
  computed: {
    caretDir() {
      return this.direction === 'asc' ? 'up' : 'down'
    },
  },
}
</script>

<style lang="scss" module src="./table-sort-label.scss" />
