export function getRankDetails(rank) {
  const mapping = [
    {
      min: 0,
      max: 20,
      level: 1,
      name: 'minor',
    },
    {
      min: 21,
      max: 40,
      level: 2,
      name: 'moderate',
    },
    {
      min: 41,
      max: 60,
      level: 3,
      name: 'important',
    },
    {
      min: 61,
      max: 80,
      level: 4,
      name: 'significant',
    },
    {
      min: 81,
      max: 100,
      level: 5,
      name: 'major',
    },
  ]

  const details = mapping.filter((rules) => {
    return rank >= rules.min && rank <= rules.max
  })

  if (details.length > 0) {
    return {
      value: rank,
      level: details[0].level,
      name: details[0].name,
    }
  } else {
    return {
      value: rank,
    }
  }
}
