/*
 * Accepts "place" object, returned from places/search API
 * Return pretty version of that place's address
 */
export function getSecondaryEventAddress(place) {
  const name = place.name
  const region = place.region
  const country = place.countryDetails?.name?.common

  const arr = []
  if (name) arr.push(name)
  if (region && arr.indexOf(region) === -1) arr.push(region)
  if (country && arr.indexOf(country) === -1) arr.push(country)

  return arr.join(', ')
}
