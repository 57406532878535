<template>
  <div :class="bem('', [{ dense, 'no-margin': noMargin }, color])">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Sheet',
  props: {
    color: {
      type: String,
      default: 'white',
      validator: (val) => ['white'].includes(val),
    },
    dense: Boolean,
    noMargin: Boolean,
  },
}
</script>

<style lang="scss" src="./sheet.scss" scoped />
