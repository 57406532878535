<template>
  <tr :class="bem('', { hover, head })">
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'TableRow',
  provide() {
    return { hover: this.hover }
  },
  inject: {
    head: {
      default: false,
    },
  },
  props: {
    hover: {
      /*
       * If true, the table row will shade on hover.
       */
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" module src="./table-row.scss" />
