<template>
  <div :class="bem('')">
    <Typography variant="xs">Event Details </Typography>
    <div :class="bem('content')">
      <div :class="bem('cols')">
        <div :class="bem('col1')">
          <div :class="bem('item')">
            <Typography :class="bem('label')" variant="label">
              Date &amp; Time
            </Typography>
            <div :class="bem('dates-box')">
              <div :class="bem('date')">
                <Typography>{{ startDate }}</Typography>
                <Typography v-if="startTime" variant="p-sm" component="p" :class="bem('sm')">
                  {{ startTime }}
                </Typography>
              </div>
              <Icon icon="arrow" color="current" />
              <div :class="bem('date')">
                <Typography>{{ endDate }}</Typography>
                <Typography
                  v-if="endTime"
                  variant="p-sm"
                  component="p"
                  :class="bem('sm', [{ predicted: isPredictedEndTime }])"
                >
                  {{ endTime }}
                  <AppChip :class="bem('date-tag')" v-if="isPredictedEndTime" variant="pastel-blue">
                    Predicted
                  </AppChip>
                </Typography>
              </div>
            </div>
          </div>
          <div :class="bem('item')" v-if="timeZone.length > 0">
            <Typography :class="bem('label')" variant="label">
              Timezone
            </Typography>
            <Typography>{{ timeZone }}</Typography>
          </div>
          <div :class="bem('item')" v-if="duration">
            <Typography :class="bem('label')" variant="label">
              Duration
            </Typography>
            <Typography>{{ duration }}</Typography>
          </div>
        </div>
        <div :class="bem('col2')">
          <div :class="bem('item')" v-if="showPhqAttendance">
            <Typography :class="bem('label')" variant="label">
              Predicted Attendance
            </Typography>
            <Typography>{{ phqAttendance }}</Typography>
          </div>
        </div>
      </div>

      <div :class="bem('description')" v-if="description.length > 0">
        <Typography :class="bem('label')" variant="label">
          Description
        </Typography>

        <ExpandTransition>
          <Typography v-if="!showFullDescription">
            {{ description }}
            <AppLink
              :class="bem('toggle')"
              variant="secondary"
              v-if="hasLongerDescription"
              @click.stop.prevent="showFullDescription = !showFullDescription"
              >{{ showFullDescription ? 'less' : 'more' }}</AppLink
            >
          </Typography>
        </ExpandTransition>

        <ExpandTransition>
          <Typography v-if="showFullDescription">
            {{ description }}
            <AppLink
              :class="bem('toggle')"
              variant="secondary"
              v-if="hasLongerDescription"
              @click.stop.prevent="showFullDescription = !showFullDescription"
              >{{ showFullDescription ? 'less' : 'more' }}</AppLink
            >
          </Typography>
        </ExpandTransition>
      </div>
      <div :class="bem('time-stamps')">
        <Typography v-if="lastUpdated" variant="p-sm" component="p" :class="bem('sm')">
          Last Updated: {{ lastUpdated }}
        </Typography>
        <Typography v-if="firstSeen" variant="p-sm" component="p" :class="bem('sm')">
          First seen: {{ firstSeen }}
        </Typography>
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import AppChip from '@predicthq/vue3.components.app-chip'
import AppLink from '@predicthq/vue3.components.app-link'
import ExpandTransition from '../expand-transition'
import Icon from '@phq/icon'
import moment from 'moment-timezone'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'EventDetails',
  components: {
    AppChip,
    AppLink,
    ExpandTransition,
    Icon,
    Typography,
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    access: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showFullDescription: false,
    }
  },
  computed: {
    startDate() {
      return this.event.startLocal ? moment(this.event.startLocal).format('ddd, DD MMM YYYY') : null
    },
    startTime() {
      return this.event.startLocal && this.event.tz ? moment(this.event.startLocal).format('h:mm A') : null
    },
    endDate() {
      return this.event.endLocal ? moment(this.event.endLocal).format('ddd, DD MMM YYYY') : null
    },
    endTime() {
      if (!this.event.tz) return null
      if (this.event.predictedEndLocal) return moment(this.event.predictedEndLocal).format('h:mm A')
      if (this.event.endLocal) return moment(this.event.endLocal).format('h:mm A')
      return null
    },
    isPredictedEndTime() {
      return this.event.predictedEnd !== null
    },
    duration() {
      if (R.has('duration', this.event) && this.showDuration) {
        const days = this.event?.duration?.days
        return days === 1 ? '1 day' : days + ' days'
      }
      if (R.has('predictedDuration', this.event) && this.showPredictedDuration) {
        const days = this.event?.predictedDuration?.days
        return days === 1 ? '1 day' : days + ' days'
      }
      return 0
    },
    showDuration() {
      return this.event?.duration?.days > 1 || (this.event?.duration?.days === 1 && this.event?.tz === null)
    },
    showPredictedDuration() {
      return (
        this.event?.predictedDuration?.days > 1 ||
        (this.event?.predictedDuration?.days === 1 && this.event?.tz === null)
      )
    },
    timeZone() {
      if (R.has('tz', this.event) && this.event?.tz !== '' && this.event.tz) {
        return `${moment.tz(this.event?.tz).format('ZZ')} (${this.event?.tz})`
      }
      return ''
    },
    shortDescription() {
      if ((R.has('description', this.event), this.event?.description.length > 400)) {
        return this.event?.description.slice(0, 400) + '...'
      } else {
        return this.event?.description
      }
    },
    hasLongerDescription() {
      return this.event?.description.length > 400
    },
    description() {
      if ((R.has('description', this.event), this.event?.description !== '')) {
        return this.showFullDescription ? this.event?.description : this.shortDescription
      } else {
        return ''
      }
    },
    lastUpdated() {
      if (!R.has('updated', this.event)) {
        return false
      }
      const durationInMonths = moment().diff(moment(this.event?.updated), 'months')
      const durationInWeeks = moment().diff(moment(this.event?.updated), 'weeks')
      const durationInDays = moment().diff(moment(this.event?.updated), 'days')
      const durationInHours = moment().diff(moment(this.event?.updated), 'hours')
      const durationInMins = moment().diff(moment(this.event?.updated), 'minutes')

      const durationInSecs = moment().diff(moment(this.event?.updated), 'seconds')

      if (durationInMonths > 0) {
        return durationInMonths === 1 ? '1 month ago' : `${durationInMonths} months ago`
      }
      if (durationInWeeks > 0) {
        return durationInWeeks === 1 ? '1 week ago' : `${durationInWeeks} weeks ago`
      }
      if (durationInDays > 0) {
        return durationInDays === 1 ? '1 day ago' : `${durationInDays} days ago`
      }
      if (durationInHours > 0) {
        return durationInHours === 1 ? '1 hour ago' : `${durationInHours} hours ago`
      }
      if (durationInMins > 0) {
        return durationInMins === 1 ? '1 min ago' : `${durationInMins} mins ago`
      }
      if (durationInSecs > 0) {
        return durationInSecs === 1 ? '1 sec ago' : `${durationInSecs} secs ago`
      }

      return false
    },
    firstSeen() {
      return moment(this.event?.firstSeen).format('DD MMM YYYY') || ''
    },
    phqAttendance() {
      return this.event?.phqAttendance?.toLocaleString() || 0
    },
    showPhqAttendance() {
      return (
        R.has('phqAttendance', this.event) &&
        this.event.phqAttendance !== null &&
        this.access.includes('phq-attendance')
      )
    },
  },
}
</script>

<style lang="scss" module src="./event-details.scss" />
