<template>
  <div :class="bem('')">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'RankGroup',
}
</script>

<style lang="scss" module src="./rank-group.scss" />
