<template>
  <div :class="[$style.caret, { [$style.up]: direction === 'up', [$style.down]: direction === 'down' }]" />
</template>

<script>
export default {
  name: 'Caret',
  props: {
    direction: {
      type: String,
      validator: (value) => ['up', 'down'].includes(value),
      default: 'down',
    },
  },
}
</script>

<style lang="scss" module>
@import '~@phq/scss/globals';

$caretSize: 4px;

.caret {
  pointer-events: none;
  width: 0;
  height: 0;
  border: solid $caretSize;
  border-color: currentColor transparent transparent;
}

.down {
  transform: translateY(calc($caretSize / 2));
}

.up {
  transform: rotate(-180deg) translateY(calc($caretSize / 2));
}
</style>
