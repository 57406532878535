<template>
  <div :class="bem('')">
    <div :class="bem('h')">
      <slot />
    </div>
    <div :class="bem('sub')">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddonText',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" module src="./addon-text.scss" />
