import * as R from 'ramda'
import PublicApiEventsEvent from './event'
import PublicApiEventsBroadcastEvent from './broadcast-event'

function PublicApiEventsSearchResult(data) {
  const result = {
    get results() {
      if (R.has('results', data)) {
        return R.map((e) => {
          if (R.has('broadcast_id', e)) {
            return new PublicApiEventsBroadcastEvent(e)
          } else {
            return new PublicApiEventsEvent(e)
          }
        }, data.results)
      }

      return []
    },
    get count() {
      return R.has('count', data) ? data.count : 0
    },
  }

  return result
}

export default PublicApiEventsSearchResult
