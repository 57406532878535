export default function useFormErrors() {
  function firstErrorMatching(errors, match) {
    for (const field in errors) {
      if (field.includes(match) && errors[field]) return errors[field]
    }

    return null
  }

  function firstError(errors) {
    return Object.values(errors)[0] ?? null
  }

  function hasError(errors) {
    return errors?.length > 0
  }

  return {
    firstErrorMatching,
    firstError,
    hasError,
  }
}
