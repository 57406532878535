<template>
  <div :class="bem('')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldGroup',
}
</script>

<style lang="scss" module src="./field-group.scss" />
