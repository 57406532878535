<template>
  <div :class="bem('')" :style="style">
    <slot />
  </div>
</template>

<script>
import breakpoints from '@phq/breakpoints'

export default {
  name: 'Row',
  props: {
    align: {
      type: String,
      default: 'center',
    },
    gap: {
      type: [Number, String, Object],
      default: 10,
    },
    /** Columns @ different breakpoints */
    sm: String,
    md: String,
    lg: String,
  },
  computed: {
    columns() {
      let columns = this.sm
      if (breakpoints.t && this.md) columns = this.md
      if (breakpoints.l && this.lg) columns = this.lg
      return {
        gridTemplateColumns: columns,
      }
    },
    formattedGap() {
      const gridGap = (this.spacing + '').replaceAll('px', '').replaceAll(/\d+/g, '$&px')
      return { gridGap }
    },
    spacing() {
      const gap = this.gap
      if (typeof gap === 'string' || typeof gap === 'number') return gap

      if (gap.sm) return gap.sm
      if (breakpoints.t && gap.md) return gap.md
      if (breakpoints.l && gap.lg) return gap.lg

      return 10
    },
    style() {
      return {
        alignItems: this.align,
        ...this.columns,
        ...this.formattedGap,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  display: grid;
}
</style>
