import dom from '@/app/dom'

export default {
  namespaced: true,
  state: {
    item: {
      id: null,
      name: null,
    },
  },
  mutations: {
    item(state, payload) {
      if (payload.id) {
        state.item.id = payload.id
      } else {
        state.item.id = null
      }

      if (payload.name) {
        state.item.name = payload.name
      } else {
        state.item.name = null
      }

      if (state.item.name !== null) {
        dom.setPageTitle(state.item.name)
      } else {
        dom.setPageTitle('')
      }
    },
  },
}
