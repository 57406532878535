/*
 * Accepts "event" object, returned from 'events/search' API
 * Return a single deepest common "id" inside event's "placeHierarchies" arrays
 */
export function getDeepestCommonId(event) {
  if (event.placeHierarchies.length === 0) return null

  // Find intersecting ids
  // [1, 2, 3], [1, 2, 4] => [1, 2]
  // [4, 2, 3], [1, 2, 4] => []
  let { ids } = event.placeHierarchies.reduce((a, b) => {
    return {
      ids: a.ids.map((id, index) => {
        return id === b.ids[index] ? id : null
      }),
    }
  })

  // Filter out nulls
  ids = ids.filter((id) => id)

  return ids[ids.length - 1]
}
