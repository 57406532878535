<template>
  <thead>
    <slot />
  </thead>
</template>

<script>
export default {
  name: 'TableHead',
  provide() {
    return { head: true }
  },
}
</script>
