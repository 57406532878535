// This is a singleton as we only want to set up the breakpoint
// listeners once and share them across the whole application.

import { reactive, readonly } from 'vue'

// Based on breakpoints in:
//
// https://bit.dev/predicthq/predicthq-ui/assets/scss/~code#settings/_breakpoints.scss
//
// Add other breakpoints as needed

// TODO: Share values with file above
const breakpointWidths = {
  m: 375,
  t: 768,
  l: 1024,
  d: 1300,
}

const breakpoints = reactive({
  m: false,
  t: false,
  l: false,
  d: false,
})

Object.entries(breakpointWidths).forEach(([breakpoint, width]) => {
  const mql = window.matchMedia(`screen and (min-width: ${width}px)`)
  breakpoints[breakpoint] = mql.matches
  mql.addEventListener('change', (e) => {
    breakpoints[breakpoint] = e.matches
  })
})

export default readonly(breakpoints)
