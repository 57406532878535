import { getTz } from './getTz'

export function getQueryParams(query) {
  // exporter-backend accepts the query as flat JSON
  const searchQuery = Object.fromEntries(new URLSearchParams(query))

  // CC doesn't add active.tz and start.tz to the query
  if (searchQuery['active.gte'] !== undefined || searchQuery['active.lte'] !== undefined) {
    searchQuery['active.tz'] = getTz()
  }
  if (searchQuery['start.gte'] !== undefined || searchQuery['start.lte'] !== undefined) {
    searchQuery['start.tz'] = getTz()
  }

  return searchQuery
}

// Builds query string and removes empty values
export function getQueryString(params) {
  return new URLSearchParams(
    Object.keys(params)
      .map((key) => (params[key] ? `${key}=${params[key]}` : ''))
      .join('&'),
  ).toString()
}
