<template>
  <div class="system-messages" v-show="messages">
    <system-messages-message
      v-for="message in messages"
      :key="message.id"
      :type="message.type"
      :text="message.text"
      :icon="message.icon"
      :actions="message.actions"
      :undo="message.undo"
    ></system-messages-message>
  </div>
</template>

<script>
import SystemMessagesMessage from './system-messages/message'

export default {
  name: 'system-messages',
  data() {
    return {
      messages: [],
      messageId: 0,
    }
  },
  created() {
    this.emitter.on('message', ({ text, options }) => {
      this.add(text, options)
    })
  },
  methods: {
    add(text, options) {
      this.messages.push({
        id: this.messageId++,
        type: options.type || 'normal',
        text: text,
        icon: options.icon || '',
        actions: options.actions || [],
        undo: options.undo || null,
      })
    },
  },
  components: {
    SystemMessagesMessage,
  },
}
</script>
