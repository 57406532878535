import * as R from 'ramda'

function PublicApiEventsEventEntity(data) {
  const entity = {
    type: R.has('type', data) ? data.type : null,
    name: R.has('name', data) ? data.name : null,
    formattedAddress: R.has('formatted_address', data) ? data.formatted_address : null, // TODO only for venue types
    singleLineAddress: R.has('formatted_address', data) ? data.formatted_address.split('\n').join(', ') : null,
  }

  return entity
}

export default PublicApiEventsEventEntity
