// Replicates local/session storage API and stores data in memory
// in case browser Storage is not available (e.g. Safari private mode)
const memoryStorage = {
  getItem(prop) {
    return this[prop] ?? null
  },
  setItem(prop, value) {
    this[prop] = value
  },
  removeItem(prop) {
    delete this[prop]
  },
}

/**
 * Utility class to simplify the API and allow for other types
 * (objects, arrays) to be stored locally
 */
class PhqStorage {
  constructor() {
    this.use('local')
  }
  /**
   * Set the type of storage to use. Defaults to `local` and
   * fallbacks to `memory` if not available
   *
   * @param type
   * @returns this
   */
  use(type) {
    // Try-catch is required because of Safari/Firefox private
    // browsing mode. Even though `localStorage` is available,
    // it throws an error when trying to access it.
    try {
      this.storage = window[`${type}Storage`] ?? memoryStorage
    } catch (e) {
      this.storage = memoryStorage
    }

    return this
  }
  /**
   * Get parsed record from local storage
   *
   * @param key
   * @returns
   */
  get(key, defaultValue) {
    const item = this.storage.getItem(key)

    if (item !== null) {
      if (/^'-?\d{1,}?\.?\d{1,}'$/.test(item)) return Number(item)
      if (['{', '['].includes(item[0])) return JSON.parse(item)

      return item
    }

    if (defaultValue) return defaultValue

    return null
  }

  /**
   * Save record to local storage
   *
   * @param key
   * @param value
   */
  set(key, value) {
    if (typeof value === 'object') value = JSON.stringify(value)

    this.storage.setItem(key, value)
  }

  /**
   * Update record to local storage
   */
  update(key, value) {
    const item = this.storage.getItem(key)

    if (typeof value === 'object' && ['{', '['].includes(item[0])) {
      const obj = JSON.parse(item)
      for (const k in value) {
        obj[k] = value[k]
      }

      this.storage.setItem(key, JSON.stringify(obj))
    } else this.storage.setItem(key, value)
  }

  /**
   * Destroy record on local storage
   * @param key
   */
  remove(key) {
    this.storage.removeItem(key)
  }

  /**
   * Check if there's a record with the provided key.
   *
   * @param key
   * @returns {boolean}
   */
  has(key) {
    return this.storage.hasOwnProperty(key)
  }
}

const storage = new PhqStorage()

export default storage
