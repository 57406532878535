import { getCountry } from '@/app/countries'
import { getDurationAsDays, getDurationFromRange } from '@/app/events/helpers/dates'
import { getRankDetails } from '@/app/events/helpers/rank'
import moment from 'moment-timezone'
import PublicApiEventsEventEntity from './event/entity'
import PublicApiEventsEventPlaceHierarchy from './event/place-hierarchy'

function PublicApiEventsEvent(data) {
  return {
    id: data?.id ?? null,
    title: data?.title ?? '',
    description: data?.description ?? '',
    category: data.category,
    state: data?.state ?? null,
    deletedReason: data?.deleted_reason ?? null,
    cancelled: data?.cancelled ?? null,
    postponed: data.postponed ?? null,
    updated: data?.updated ?? null,
    firstSeen: data.first_seen ?? null,
    get labels() {
      let labels = []
      if ('phq_labels' in data) labels = data.phq_labels.map((x) => x.label)
      else if ('labels' in data) labels = data.labels
      return labels.sort()
    },
    start: data?.start ?? null,
    end: data?.end ?? null,
    predictedEnd: data?.predicted_end ?? null,
    tz: data?.timezone ?? null,
    // date *_local fields are assumed to be in the event timezone, do not have timezone info
    // if the *_local field isn't there, use the corresponding date field and timezone to generate value
    get startLocal() {
      if (data?.start_local) return data.start_local
      if (this.start && this.tz) return moment.utc(this.start).tz(this.tz).format('YYYY-MM-DDTHH:mm:ss')
      if (this.start) return moment.utc(this.start).format('YYYY-MM-DDTHH:mm:ss')
      return null
    },
    get endLocal() {
      if (data?.end_local) return data.end_local
      if (this.end && this.tz) return moment.utc(this.end).tz(this.tz).format('YYYY-MM-DDTHH:mm:ss')
      if (this.end) return moment.utc(this.end).format('YYYY-MM-DDTHH:mm:ss')
      return null
    },
    get predictedEndLocal() {
      if (data?.predicted_end_local) return data.predicted_end_local
      if (this.predictedEnd && this.tz) return moment.utc(this.predictedEnd).tz(this.tz).format('YYYY-MM-DDTHH:mm:ss')
      if (this.predictedEnd) return moment.utc(this.predictedEnd).format('YYYY-MM-DDTHH:mm:ss')
      return null
    },
    get predictedDuration() {
      let seconds = null
      if (this.predictedEnd) seconds = getDurationFromRange(this.start, this.predictedEnd)

      const days = seconds !== null ? getDurationAsDays(seconds) : null
      return {
        seconds,
        days,
      }
    },
    get duration() {
      const seconds = data?.duration ?? null
      const days = seconds !== null ? getDurationAsDays(seconds) : null

      return {
        seconds,
        days,
      }
    },
    lat: data?.location?.[1] ?? 0.0,
    lng: data?.location?.[0] ?? 0.0,
    get location() {
      return {
        lat: this.lat,
        lng: this.lng,
        coords: [this.lng, this.lat],
      }
    },
    country: data?.country ?? null,
    get countryDetails() {
      return getCountry({ alpha2: this.country })
    },
    get placeHierarchies() {
      if ('place_hierarchies' in data)
        return data.place_hierarchies.map((hierarchy) => new PublicApiEventsEventPlaceHierarchy(hierarchy))
      return []
    },
    get entities() {
      if ('entities' in data) return data.entities.map((entity) => new PublicApiEventsEventEntity(entity))
      return []
    },
    address: data?.geo?.address ?? null,
    // formattedAddress prioritises using the formatted_address from geo.address (only republished events have this field)
    // otherwise uses venue address
    // TODO: remove the fallback logic in future when all events have geo.address fields
    get formattedAddress() {
      if (this.address?.formatted_address) return this.address.formatted_address

      if (this.entities.length) {
        const venue = this.entities.find(
          (entity) =>
            ['venue', 'airport'].includes(entity.type) && entity.formattedAddress && entity.formattedAddress.length,
        )
        if (venue) return `${venue.name}\n${venue.formattedAddress}`
      }

      return ''
    },
    get singleLineAddress() {
      return this.formattedAddress.split('\n').join(', ').trim()
    },
    get singleLineLocation() {
      // Get the best available data to describe the location in a single line
      if (this.singleLineAddress.length) return this.singleLineAddress
      if (this.countryDetails) return this.countryDetails.name.common
      if (this.country) return this.country

      return ''
    },
    get geometry() {
      if ('geo' in data) return data.geo?.geometry
      return {
        type: 'Point',
        coordinates: [this.lng, this.lat],
      }
    },
    scope: data?.scope ?? '',
    rank: 'rank' in data ? getRankDetails(data.rank) : getRankDetails(null),
    localRank: 'local_rank' in data ? getRankDetails(data.local_rank) : getRankDetails(null),
    phqAttendance: data?.phq_attendance ?? null,
    predictedSpend: data?.predicted_event_spend ?? null,
    predictedSpendIndustries: data?.predicted_event_spend_industries ?? null,
    relevance: data?.relevance ?? null, // Only applicable for search results
  }
}

export default PublicApiEventsEvent
