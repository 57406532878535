import client from './util/client'
import serviceClient from './util/service-api-client'

export default {
  search(params, retry = true) {
    return client.request({ uri: '/v1/places/', params, retry })
  },
  access(params, retry = true) {
    return serviceClient.request({
      uri: '/geoscope-api/scopes',
      params,
      retry,
    })
  },
}
