import moment from 'moment-timezone'

const TIME_FORMAT = 'h:mm A'
const DATE_FORMAT = 'ddd, D MMM Y'
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
const COMPARE_DATE_FORMAT = 'YYYY-MM-DD'
const COMPARE_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const OFFSET_FORMAT = 'ZZ'
const BROADCAST_DATETIME_FORMAT = 'ddd, D MMM Y h:mm A'

export function getEventDate(event, type = false) {
  if (type === 'broadcast') {
    /* Broadcast events doesn't have end date but has a startLocal 
    so returning that for display */
    return moment(event.startLocal).format(BROADCAST_DATETIME_FORMAT)
  }
  let dateString = formatEventDateForDisplay(event.start, event.end, event.tz)
  const days = event.duration.days

  if (days > 1 || (days === 1 && (event.tz === null || event.tz.length === 0))) {
    // dateString += ' (' + lang.format('event.duration.days', { days }) + ')'
    dateString += ` (${days === 1 ? '1 day' : days + ' days'})`
  }

  return dateString
}

export function getEventPredictedEnd(event) {
  if (event.predictedEnd) {
    return formatPredictedEndDateForDisplay(event.start, event.predictedEnd, event.tz)
  }
  return null
}

export function getEventPredictedEndFormat(event) {
  if (event?.predictedEnd) {
    const date = moment.tz(event?.predictedEnd, event?.tz)
    return {
      date: date.format('ddd, D MMM Y'),
      time: date.format('h:mm A'),
    }
  }
  return null
}

// TODO use moment to work out duration (date diff)
export function getDurationAsDays(seconds) {
  let days = 1

  if (seconds >= 86400) {
    days = Math.ceil(seconds / 86400)

    if (seconds % 86400 === 0) {
      // This event is an exact multiple of 24h so add an extra day
      days++
    }
  }

  return days
}

export function getDurationFromRange(start, end) {
  return moment(end).diff(moment(start), 'seconds')
}

export function eventHasTime(date, tz) {
  /**
   * When the time is set to 12:00 AM in the event timezone, we assume this
   * means we don't know the correct time for an event so we don't display the
   * the time component.
   */
  let hasTime = true

  if (moment.utc(date).tz(tz).format('HH:mm') === '00:00') {
    hasTime = false
  }

  return hasTime
}

export function getQuickViewDate(start, end, eventTz = null, userTz = null) {
  const hasTime = eventTz !== null && eventTz.length > 0 ? eventHasTime(start, eventTz) : false

  // Work out which tz to use
  const tz = eventTz !== null && eventTz.length > 0 && userTz !== null && userTz.length > 0 ? userTz : eventTz

  let dateString = formatEventDateForDisplay(start, end, tz, hasTime)

  if (userTz !== null && userTz.length > 0) {
    dateString += ` (${userTz})`
  } else if (eventTz !== null && eventTz.length > 0) {
    dateString += ` (${eventTz})`
  }

  return dateString
}

// Assumes start/end are UTC
export function formatEventDateForDisplay(start, end, tz = null, hasTime = null) {
  let dateString = ''

  const isTimeZoneAgnostic = tz === null || tz.length === 0

  if (typeof hasTime !== 'boolean') {
    hasTime = isTimeZoneAgnostic === false ? eventHasTime(start, tz) : false
  }

  if (isTimeZoneAgnostic) {
    // Treat as UTC so the .tz(tz) usage below still works
    tz = 'UTC'
  }

  if (
    end &&
    moment.utc(start).tz(tz).format(COMPARE_DATE_FORMAT) !== moment.utc(end).tz(tz).format(COMPARE_DATE_FORMAT)
  ) {
    // Event starts and ends on different days (include offset)
    if (!isTimeZoneAgnostic) {
      const startDateFormat = hasTime ? DATETIME_FORMAT : DATE_FORMAT
      const endDateFormat = `${hasTime ? DATETIME_FORMAT : DATE_FORMAT} ${OFFSET_FORMAT}`

      dateString = `${moment.utc(start).tz(tz).format(startDateFormat)} - ${moment
        .utc(end)
        .tz(tz)
        .format(endDateFormat)}`
    } else {
      const startDateFormat = DATE_FORMAT
      const endDateFormat = DATE_FORMAT

      // Slightly different here as the end time might be 23:59:59 so still the same day
      if (moment.utc(start).format(startDateFormat) === moment.utc(end).format(startDateFormat)) {
        dateString = moment.utc(start).format(startDateFormat)
      } else {
        dateString = `${moment.utc(start).format(startDateFormat)} - ${moment.utc(end).format(endDateFormat)}`
      }
    }
  } else if (
    moment.utc(start).tz(tz).format(COMPARE_DATETIME_FORMAT) === moment.utc(end).tz(tz).format(COMPARE_DATETIME_FORMAT)
  ) {
    // Event starts and ends the same day and time (include offset)
    if (!isTimeZoneAgnostic) {
      const startDateFormat = `${hasTime ? DATETIME_FORMAT : DATE_FORMAT} ${OFFSET_FORMAT}`

      dateString = moment.utc(start).tz(tz).format(startDateFormat)
    } else {
      const startDateFormat = DATE_FORMAT

      dateString = moment.utc(start).format(startDateFormat)
    }
  } else if (
    moment.utc(start).tz(tz).format(COMPARE_DATE_FORMAT) === moment.utc(end).tz(tz).format(COMPARE_DATE_FORMAT)
  ) {
    // Event starts and ends the same day but different time (include offset)
    if (!isTimeZoneAgnostic) {
      const startDateFormat = hasTime ? DATETIME_FORMAT : DATE_FORMAT
      const endDateFormat = `${TIME_FORMAT} ${OFFSET_FORMAT}`

      dateString = `${moment.utc(start).tz(tz).format(startDateFormat)} - ${moment
        .utc(end)
        .tz(tz)
        .format(endDateFormat)}`
    } else {
      const startDateFormat = DATE_FORMAT

      dateString = moment.utc(start).format(startDateFormat)
    }
  } else {
    // Event is ongoing
    if (!isTimeZoneAgnostic) {
      const startDateFormat = `${hasTime ? DATETIME_FORMAT : DATE_FORMAT} ${OFFSET_FORMAT}`

      dateString = `${moment.utc(start).tz(tz).format(startDateFormat)} - Ongoing`
    } else {
      const startDateFormat = `${DATE_FORMAT} ${OFFSET_FORMAT}`

      dateString = `${moment.utc(start).format(startDateFormat)} - Ongoing`
    }
  }

  return dateString
}

export function formatPredictedEndDateForDisplay(start, predictedEnd, tz = null) {
  return moment.utc(predictedEnd).tz(tz).format(DATETIME_FORMAT)
}

export function formatDateRange(from, to) {
  let text = ''

  if (from !== to) {
    if (moment(from, 'Y-MM-DD').format('YYYY') !== moment(to, 'Y-MM-DD').format('YYYY')) {
      // Crosses years so include years in format
      text = moment(from, 'Y-MM-DD').format('D MMM YYYY') + ' to ' + moment(to, 'Y-MM-DD').format('D MMM YYYY')
    } else if (moment(from, 'Y-MM-DD').format('MM') !== moment(to, 'Y-MM-DD').format('MM')) {
      // Crosses months so include months in format
      text = moment(from, 'Y-MM-DD').format('D MMM') + ' to ' + moment(to, 'Y-MM-DD').format('D MMM YYYY')
    } else {
      // Within same month and year so just display month/year once
      text = moment(from, 'Y-MM-DD').format('D') + ' to ' + moment(to, 'Y-MM-DD').format('D MMM YYYY')
    }
  } else {
    text = moment(from, 'Y-MM-DD').format('D MMM YYYY')
  }

  return text
}
