<template>
  <div id="content">
    <router-view></router-view>
  </div>
</template>

<script>
import config from '@/app/config'

export default {
  computed: {
    config() {
      return config
    },
  },
  created() {
    if (this.$route.path === '/') {
      window.location = config.PHQ_WEBSITE_URL
    }
  },
}
</script>