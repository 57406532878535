<template>
  <section
    :class="[bem('', [align, { 'with-bg': includeLinearBg || includeWhiteBg, 'full-width': fullWidth }]), 'sides']"
  >
    <div
      :class="bem('bg', { linear: includeLinearBg, white: includeWhiteBg })"
      v-if="includeLinearBg || includeWhiteBg"
    />
    <div class="wrap" :class="bem('wrap', { wide, 'full-width': fullWidth })">
      <div :class="bem('content')">
        <AppTypography variant="2xl-light" :class="bem('title')" v-if="title">{{ title }}</AppTypography>
        <AppTypography variant="sm" :class="bem('subheading')" v-if="subheading">{{ subheading }}</AppTypography>
        <AppTypography variant="p-sm" v-if="$slots.body" :class="bem('body')">
          <slot name="body" />
        </AppTypography>
      </div>
      <div :class="bem('links')" v-if="$slots.links">
        <slot name="links" />
      </div>
    </div>
  </section>
</template>
<script>
import AppTypography from '@predicthq/vue3.components.typography'

export default {
  name: 'AppCta',
  components: {
    AppTypography,
  },
  props: {
    align: {
      type: String,
      default: 'center',
      validator: (val) => ['left', 'center', 'right'].includes(val),
    },
    title: {
      type: String,
    },
    subheading: {
      type: String,
    },
    wide: {
      type: Boolean,
    },
    fullWidth: {
      type: Boolean,
    },
    includeLinearBg: Boolean,
    includeWhiteBg: Boolean,
  },
}
</script>

<style lang="scss" scoped src="./app-cta.scss" />
