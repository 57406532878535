import worldCountries from 'world-countries'

export function getCountry(options) {
  const { alpha2 = '', alpha3 = '' } = options

  const countryDetailsArray = getAllCountries().filter((country) => {
    return country.alpha2 === alpha2 || country.alpha3 === alpha3
  })

  if (countryDetailsArray.length > 0) {
    const countryDetails = countryDetailsArray[0]

    return countryDetails
  }
}

export function getAllCountries() {
  return worldCountries.map((country) => {
    return {
      alpha2: country.cca2,
      alpha3: country.cca3,
      name: {
        common: country.name.common,
        official: country.name.official,
      },
      languages: country.languages,
      demonym: country.demonym,
      region: country.region,
      subRegion: country.subregion,
      capitals: country.capital,
    }
  })
}
