import service from '@/app/api/public/saved-locations'
import { PublicApiSavedLocation } from '@/app/phq-public/saved-locations'
import PublicApiEventsSearchResult from '@/app/phq-public/events/search-result'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    location: null,
  },
  mutations: {
    setLoadingStatus(state, isLoading) {
      state.isLoading = isLoading
    },
    setLocation(state, location) {
      state.location = location
    },
  },
  actions: {
    async fetchLocation({ commit }, id) {
      try {
        commit('setLoadingStatus', true)
        const location = new PublicApiSavedLocation(await service.getLocation(id))
        commit('setLocation', location)
        return location
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      } finally {
        commit('setLoadingStatus', false)
      }
    },
    async fetchLocationEvents(_, { id, params }) {
      return new PublicApiEventsSearchResult(await service.getLocationEvents(id, params))
    },
  },
}
