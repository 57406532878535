import * as R from 'ramda'
import PublicApiPlacesPlace from './place'

function PublicApiPlacesSearchResult(data) {
  const result = {
    get results() {
      if (R.has('results', data)) {
        const resultsToSort = R.map((e) => {
          return new PublicApiPlacesPlace(e)
        }, data.results)
        return resultsToSort
      }

      return []
    },
    count: R.has('count', data) ? data.count : 0,
  }

  return result
}

export default PublicApiPlacesSearchResult
