const scheme = {
  white: 'var(--color-white)',
  blue: 'var(--bg-blue-pastel)',
  pink: 'var(--bg-pink-pastel)',
  purple: 'var(--bg-purple-pastel)',
  beam1: 'var(--bg-purple-pastel)',
  beam2: '#F5EDFA',
  beam3: '#F9EDF7',
  beam4: '#FCEDF5',
  beam5: 'var(--bg-pink-pastel)',
}

// Array of trackers, so can tell if none intersecting
const trackers = []

const changeBg = ({ color, callback }) => {
  if (callback) callback()

  document.body.style.backgroundColor = scheme[color]
  // root.style.setProperty('--bg-color', scheme[color])
}

export const BgChange = {
  beforeMount: function (el, binding) {
    const options = {
      root: null,
      rootMargin: '-49% 0px -50% 0px', // small line in the middle
      threshold: 0, // Any amount of target in the line
    }

    // Keep track of this particular element
    const tracker = { intersecting: false, el, value: binding.value }
    trackers.push(tracker)

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          tracker.intersecting = true
          changeBg(tracker.value)
        } else {
          tracker.intersecting = false

          // If none intersecting go back to default
          if (!trackers.some((t) => t.intersecting)) {
            changeBg({ color: 'white' })
          }
        }
      })
    }
    const observer = new IntersectionObserver(callback, options)

    observer.observe(el)
  },
  updated: function (el, binding) {
    const index = trackers.findIndex((t) => t.el === el)

    if (index >= 0 && trackers[index].value.color !== binding.value.color) {
      trackers[index].value = binding.value

      if (trackers[index].intersecting) changeBg(binding.value)
    }
  },
}
