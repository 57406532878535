<template>
  <span :class="bem('', { separator })">
    <AppIcon :icon="`event-cat/${category}`" :class="bem('icon')" />
    <AppTypography component="span" variant="label" class="c-dark weight-500">{{ categoryName }}</AppTypography>
  </span>
</template>

<script setup>
import { computed } from 'vue'
import AppIcon from '@predicthq/vue3.components.icon'
import AppTypography from '@predicthq/vue3.components.typography'
import categories from '@/data/categories.json'

defineOptions({
  name: 'CategoryLabel',
})

const props = defineProps({
  category: {
    type: String,
    required: true,
  },
  separator: Boolean,
})
const categoryName = computed(() => categories.find((c) => c.tag === props.category).name)
</script>

<style lang="scss" scoped>
@import '~@phq/scss/globals';

.category-label {
  align-items: center;
  display: inline-flex;
  gap: 10px;

  &--separator {
    border-right: 1px solid $c-line-10;
    margin-right: 18px;
    padding-right: 16px;
  }

  &__icon {
    height: $icon-size;
    width: $icon-size;
  }
}
</style>
