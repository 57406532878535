<template>
  <div :class="bem('', [direction])" id="event-map">
    <div :class="bem('map-details')">
      <div>
        <Typography variant="xs">Location</Typography>
        <div :class="bem('address')">
          <Typography v-if="location">{{ location.address }}</Typography>
          <Typography variant="p-sm" component="p" :class="bem('sm')">
            <template v-if="isPolygonEvent">Center: </template>{{ coords.lat }},&nbsp;{{ coords.lng }}
          </Typography>
        </div>
        <div :class="bem('chip-group')" v-if="location.labels && location.labels.length > 0">
          <ChipGroup>
            <AppChip v-for="(label, idx) in location.labels" :key="idx" variant="pastel-purple">
              {{ label }}
            </AppChip>
          </ChipGroup>
        </div>
        <div :class="bem('list')" v-if="radius">
          <div>
            <Typography :class="bem('label')" variant="label">
              Radius
            </Typography>
            <Typography>{{ radius.text }}</Typography>
          </div>
        </div>
      </div>
      <slot />
    </div>
    <div :class="bem('map-container')" id="map-container">
      <div id="map" ref="map">
        <transition name="fade">
          <div :class="bem('overlay-helper')" v-if="showHelper">
            <Typography variant="lg">{{ interactionHelper }}</Typography>
          </div>
        </transition>
        <div :class="bem('full-screen')" @click="toggleFullscreen">
          <AppIcon color="current" :class="bem('full-screen-icon')" icon="nav/full-screen" />
        </div>
        <div>
          <Zoom :class="bem('zoom')" @in="zoomIn" @out="zoomOut" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from '@/utils/getUserDevice'
import AppChip from '@predicthq/vue3.components.app-chip'
import AppIcon from '@predicthq/vue3.components.icon'
import ChipGroup from '@predicthq/vue3.components.chip-group'
import config from '@/app/config'
import breakpoints from '@phq/breakpoints'
import mapboxgl from 'mapbox-gl'
import MapMixin from './events-mixin'
import Typography from '@predicthq/vue3.components.typography'
import Zoom from '@phq/zoom'

export default {
  name: 'EventMap',
  components: {
    AppChip,
    AppIcon,
    ChipGroup,
    Typography,
    Zoom,
  },
  mixins: [MapMixin],
  props: {
    location: Object,
    events: {
      type: Array,
      default: () => [],
    },
    markers: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: 'column',
      validator: (val) => ['row', 'column'].includes(val),
    },
    showEventsPopup: Boolean,
    isPolygonEvent: Boolean,
  },
  computed: {
    isTouchDevice,
    coords() {
      if (this.location) return { lat: this.location.lat, lng: this.location.lng }

      if (this.events?.length) return { lat: this.events[0].lat, lng: this.events[0].lng }

      return { lat: 0, lng: 0 }
    },
    radius() {
      if (!this.location?.properties?.radius) return

      const { radius, radius_unit: unit } = this.location.properties

      return {
        value: radius,
        unit,
        text: `${radius} ${unit}`,
      }
    },
  },
  data() {
    return {
      breakpoints,
      config,
      interactionHelper: '',
      bounds: new mapboxgl.LngLatBounds(),
      showHelper: false,
      mapLoaded: false,
      fullScreen: false,
    }
  },
  methods: {
    prepareMap() {
      const vm = this

      // First, make sure the browser supports web-gl
      if (vm.supportsWebGl()) {
        // Prepare the map
        vm.map = new mapboxgl.Map({
          container: 'map',
          style:
            'https://api.maptiler.com/maps/46af3ef8-b3b2-4b8c-ac68-f7f4e1f7dfd0/style.json?key=q1fv5VeWSQfrA96VuMhh',
          minzoom: 1.3,
          center: [-74.0073, 40.7124], // Manhattan
          zoom: 2,
          scrollZoom: true,
          dragPan: !vm.isTouchDevice,
          attributionControl: false,
        })

        vm.map.on('load', () => {
          vm.addEventsSource()
          vm.addWithinSource()
          vm.addHeatmapEventsSource()
          vm.addLocationSource()
          vm.addHeatmapEventsLayer()
          vm.addEventsLayer()
          vm.addLocationLayer()
          vm.addWithinLayers()

          // Create a popup, but don't add it to the map yet.
          const tooltipPopup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          })

          // Use the same approach as above to indicate that the symbols are clickable
          // by changing the cursor style to 'pointer'
          vm.map.on('mousemove', (e) => {
            const features = vm.map.queryRenderedFeatures(e.point, {
              layers: ['events-click-target', 'polygon-click-target', 'locations-click-target'],
            })

            vm.map.getCanvas().style.cursor = features.length ? 'pointer' : ''

            // if the features have no info, return nothing
            if (!features.length) {
              tooltipPopup.remove()
              return
            }

            const feature = features[0]
            const { type } = feature.geometry

            // show tooltip
            if (type === 'Point') tooltipPopup.setLngLat(feature.geometry.coordinates)

            if (['LineString', 'Polygon', 'MultiPolygon'].includes(type)) tooltipPopup.setLngLat(e.lngLat)

            tooltipPopup
              .setHTML(
                `<div class="map-tooltip"><div class="tooltip-icon"><span id="pop-up-icon"></span><span class="tooltip-text">${feature.properties.title}</span></div></div>`,
              )
              .addTo(vm.map)

            tooltipPopup._content.classList.add('no-pointer-events')
          })

          /**
           * map interaction handler
           */
          vm.map.on('wheel', (event) => {
            const { ctrlKey, metaKey, altKey } = event.originalEvent
            const key = window.navigator.platform.includes('Mac') ? '⌘' : 'ctrl'
            vm.interactionHelper = `Use ${key} + scroll to zoom the map`

            if (ctrlKey || metaKey || altKey) {
              vm.showHelper = false
              return
            }
            vm.toggleOverlay()
            event.preventDefault()
          })

          vm.map.on('touchstart', (event) => {
            const { touches, shiftKey } = event.originalEvent
            vm.interactionHelper = 'Use two fingers to move the map'

            if (touches.length > 1 || shiftKey) {
              vm.map.dragPan.enable()
              vm.showHelper = false
              return
            }
            vm.map.dragPan.disable()
            vm.toggleOverlay()
            event.preventDefault()
          })

          if (this.showEventsPopup) {
            vm.map.on('click', (e) => {
              const features = vm.map.queryRenderedFeatures(e.point, {
                layers: ['events-click-target', 'polygon-click-target'],
              })

              if (features.length > 0 && features[0].properties.eventId) {
                const eventId = features[0].properties.eventId
                const event = this.events.find((event) => event.id === eventId)
                const coords =
                  features[0].layer.id === 'polygon-click-target' ? e.lngLat : { lng: event.lng, lat: event.lat }
                vm.openPopupForEvent(event, coords)
                vm.map.flyTo({ center: coords, offset: [0, 100] })
              }
            })
          }

          vm.mapLoaded = true

          if (vm.location) vm.loadLocationMarker()
          if (vm.events?.length) vm.loadEventMarkers()
          if (vm.markers.length) vm.loadCustomMarkers()

          this.centralize()
        })
      } else {
        this.$messages.show("Your browser doesn't appear to support WebGL which our map depends on.", {
          type: 'warning',
        })
      }
    },
    loadCustomMarkers() {
      for (const marker of this.markers) {
        const container = document.createElement('div')
        container.className = this.bem('custom-marker')
        container.append(marker.el)
        new mapboxgl.Marker(container).setLngLat(marker.coordinates).addTo(this.map)
      }
    },
    loadLocationMarker() {
      const { geometry, properties, coords } = this.location
      this.map.getSource('location').setData({
        type: 'Feature',
        geometry,
        properties,
      })
      this.bounds.extend(coords)
      if (this.radius) this.loadWithin()
    },
    loadEventMarkers() {
      if (this.mapLoaded && this.events.length) {
        const excludeCategories = ['observances', 'public-holidays', 'school-holidays', 'politics', 'daylight-savings']
        const events = this.events.filter(
          // Filter out non polygon holidays and observances
          (e) => !(excludeCategories.includes(e.category) && e.geometry.type === 'Point'),
        )
        const features = this.getGeoJsonFeatures(events)
        const data = {
          type: 'FeatureCollection',
          features,
        }

        this.map.getSource('events').setData(data)
        this.map.getSource('heatmap-events').setData(data)
        this.bounds.extend(this.getBoundsForGeoJsonFeatures(features))
      }
    },
    loadWithin() {
      this.map.getSource('within').setData(
        this.getGeoJsonCircleForWithin({
          lat: this.coords.lat,
          long: this.coords.lng,
          radius: this.radius.value,
          unit: this.radius.unit,
        }),
      )
    },
    centralize() {
      this.map.fitBounds(this.bounds, {
        maxZoom: 11,
        minZoom: 2,
        padding: 10,
        center: [this.coords.lng, this.coords.lat],
      })
    },
    toggleOverlay() {
      if (this.showHelper) return
      this.showHelper = true
      setTimeout(() => {
        this.showHelper = false
      }, 2000)
    },
  },
  watch: {
    events: {
      async handler(val, prevVal) {
        await this.loadEventMarkers()
        // Only centralize the first time records are added to events
        if (prevVal.length === 0) this.centralize()
      },
      deep: true,
    },
  },
  mounted() {
    this.prepareMap()

    /*
     * Added resize observer to account for ViewershipTable expanding the height
     * Triggers reszie on the map, when this.$refs.map resized
     */
    this.resizeObserver = new ResizeObserver(() => {
      if (this.map) this.map.resize()
    })

    this.resizeObserver.observe(this.$refs.map)
  },
  beforeUnmount() {
    this.resizeObserver.unobserve(this.$refs.map)
  },
}
</script>

<style lang="scss" module src="./map.scss" />
