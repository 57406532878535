<template>
  <AppSurvey endpoint="2023-01-09/j2757r" :fields-map="surveyFieldsMap">
    <AppTypography>
      Thank you for your response to our Predicted Event Spend. Let us know how we can improve.
    </AppTypography>
  </AppSurvey>
  <AppSheet :class="bem('')">
    <AppTypography variant="xs" class="v-space-mb-2">
      Predicted Event Spend
    </AppTypography>
    <AppStat
      :value="formatSpend(predictedSpend)"
      align="left"
      color="pink"
      label="Total Predicted Event Spend (USD)"
      tooltip="This figure is calculated from our core PredictHQ data, enriched by local economic indicators and partner data."
      preventInline
    />
    <AppTable :class="bem('table')">
      <TableHead>
        <TableRow>
          <TableCell>
            Spend Categories
          </TableCell>
          <TableCell class="text-right">
            Spend (USD)
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow v-for="(value, category) in predictedSpendIndustries" :key="category">
          <TableCell>{{ capitalize(labels[category] || category) }}</TableCell>
          <TableCell class="text-right weight-600 c-brand-pink">{{ formatSpend(value) }}</TableCell>
        </TableRow>
      </TableBody>
    </AppTable>
  </AppSheet>
</template>

<script>
import { AppStat } from '@phq/app-stats'
import { Table as AppTable, TableBody, TableHead, TableRow, TableCell } from '@phq/table'
import AppSurvey from '@phq/app-survey'
import AppTypography from '@predicthq/vue3.components.typography'
import AppSheet from '@phq/sheet'

import { capitalize } from 'lodash'

export default {
  name: 'EventPredictedSpend',
  props: {
    predictedSpend: {
      type: Number,
      required: true,
    },
    predictedSpendIndustries: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppStat,
    AppSurvey,
    AppSheet,
    AppTable,
    AppTypography,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  },
  setup() {
    const surveyFieldsMap = {
      answer: 'info_useful',
      email: 'email',
      details: 'more_details',
      sendUpdates: 'send_updates',
    }

    const labels = {
      hospitality: 'Restaurants',
    }

    function formatSpend(value) {
      return value < 500 ? 'Less than $500' : `$${value.toLocaleString()}`
    }

    return {
      capitalize,
      formatSpend,
      labels,
      surveyFieldsMap,
    }
  },
}
</script>

<style lang="scss" scoped>
.event-predicted-spend {
  &__table {
    tr:last-child td {
      border-bottom: none;
    }
    th:last-child,
    td:last-child {
      padding-right: 0;
    }
    th:first-child,
    td:first-child {
      padding-left: 0;
    }
  }
}
</style>
