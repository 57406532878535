<template>
  <div :class="bem('')">
    <Typography variant="lg" :class="bem('title')">Sign up for free trial</Typography>

    <Typography class="text-p" variant="p-sm">
      Increase your event visibility and reduce your FoMO with PredictHQ. Because no one likes missing out.
    </Typography>
    <AppButton
      :to="config.SIGNUP_URL + '?utm_source=phq_public_pages&utm_medium=advert-signup&utm_campaign=organic'"
      :class="bem('button')"
      >Get started</AppButton
    >
    <Typography variant="p-sm" component="p">
      Already have an account?
      <AppLink
        variant="secondary"
        :to="config.CONTROL_CENTER_URL + '?utm_source=phq_public_pages&utm_medium=advert-signup&utm_campaign=organic'"
        :class="bem('link')"
        >Login</AppLink
      >
    </Typography>
  </div>
</template>

<script>
import AppButton from '@predicthq/vue3.components.app-button'
import AppLink from '@predicthq/vue3.components.app-link'
import config from '@/app/config'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'advert-signup',
  components: {
    Typography,
    AppButton,
    AppLink,
  },
  computed: {
    config() {
      return config
    },
  },
}
</script>

<style lang="scss" module src="./advert-signup.scss" />
