const pause = (duration) => new Promise((resolve) => setTimeout(resolve, duration))

export default function fetchRetry(url, fetchConfig, delay = 100, remainingRetries = 3) {
  return fetch(url, fetchConfig)
    .then(async (response) => {
      // Only retry GET requests when it's a 5xx error, or a 401 error
      if (remainingRetries > 0 && fetchConfig.method === 'GET' && response.status >= 500 && response.status <= 599) {
        console.error(`Request failed, about to retry... (remaining retries: ${remainingRetries})`, response)

        delay = delay * 2.5
        await pause(delay)

        return fetchRetry(url, fetchConfig, delay, remainingRetries - 1)
      }

      return response
    })
    .catch(async (error) => {
      if (remainingRetries > 0 && fetchConfig.method === 'GET') {
        console.error(`Request failed, about to retry... (remaining retries: ${remainingRetries})`, error)

        delay = delay * 2.5
        await pause(delay)

        return fetchRetry(url, fetchConfig, delay, remainingRetries - 1)
      } else {
        throw error
      }
    })
}
