import places from '@/app/api/phq-public/places'
import PublicApiPlacesSearchResult from '@/app/phq-public/places/search-result'
import states from '@/data/states.json'
import config from '@/app/config'
import phqApiClient from '@/app/api/phq-public/util/client'

const state = {}

export default {
  namespaced: true,
  state,
  actions: {
    search(_, params) {
      phqApiClient.setBearerToken(config.PHQ_PUBLIC_API_PUBLIC_EVENTS_TOKEN)
      return new Promise((resolve, reject) => {
        places
          .search(params)
          .then((result) => {
            resolve(new PublicApiPlacesSearchResult(result))
          })
          .catch((error) => reject(error))
      })
    },
    states(_, params) {
      /** Filter results by US States */
      const results = states.filter((e) => {
        const q = params.q.toLowerCase()
        const states = e.name.toLowerCase()
        return states.includes(q)
      })
      return new PublicApiPlacesSearchResult({ results })
    },
    access(_, params) {
      return new Promise((resolve, reject) => {
        places
          .access(params)
          .then((result) => {
            resolve(result)
          })
          .catch((error) => reject(error))
      })
    },
  },
}
