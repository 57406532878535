<template>
  <component :is="component" :class="bem('', { head, hover, sticky })">
    <span :class="bem('content')">
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'TableCell',
  inject: {
    head: {
      default: false,
    },
    hover: {
      default: false,
    },
    stickyHeader: {
      default: false,
    },
  },
  computed: {
    component() {
      return this.head ? 'th' : 'td'
    },
    sticky() {
      return this.head && this.stickyHeader
    },
  },
}
</script>

<style lang="scss" module src="./table-cell.scss" />
