import 'whatwg-fetch' // polyfill for older browsers
import queryString from 'query-string'
import config from '@/app/config'
import * as R from 'ramda'
import ApiError from '@/utils/apiError'
import fetchRetry from '@/utils/fetchRetry'

const client = {
  bearerToken: null,
  setBearerToken(bearerToken) {
    this.bearerToken = bearerToken
  },
  request({
    uri,
    params = {},
    method = 'GET',
    headers = {},
    json = null,
    data = null,
    body = null,
    bearerToken = true,
    credentials = null,
  }) {
    let url = config.API_ENDPOINT + uri

    const serviceName = R.reduce(
      (acc, value) => {
        if (acc.length === 0 && value.trim().length > 0) {
          acc = value
        }

        return acc
      },
      '',
      uri.split('/'),
    )

    if (params && !R.isEmpty(params)) url += '?' + queryString.stringify(params)

    const conf = {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json',
        'X-User-Agent': `${config.USER_AGENT}/${config.APP_VERSION}`,
      },
    }

    if (credentials) conf.credentials = credentials

    if (bearerToken && this.bearerToken !== null) {
      conf.headers.Authorization = `Bearer ${this.bearerToken}`
    }
    if (json !== null) {
      conf.headers['Content-type'] = 'application/json'
      conf.body = JSON.stringify(json)
    }
    if (body !== null && method === 'post') {
      conf.headers['Content-type'] = 'application/x-www-form-urlencoded'
      conf.body = body
    }

    if (data !== null) {
      conf.headers['Content-type'] = 'application/json'
      conf.body = JSON.stringify(data)
    }

    // Override headers with specified headers
    if (Object.keys(headers).length > 0) {
      conf.headers = {
        ...conf.headers,
        ...headers,
      }
    }

    return new Promise((resolve, reject) => {
      fetchRetry(url, conf)
        .then((response) => {
          if (response.ok) {
            // Don't try read as json for 204 (no content)
            if (response.status !== 204) {
              const contentType = response.headers.get('Content-Type')

              if (R.contains('application/json', contentType)) {
                return response.json()
              } else if (R.contains('application/pdf', contentType)) {
                return response.blob()
              } else {
                return response.text()
              }
            } else {
              return response.text()
            }
          } else {
            // Handle error
            const handleError = async (response) => {
              let json = null

              try {
                json = await response.json()
              } catch (error) {
                // Error was not returned as json
                json = {}
              }

              if (R.has('error', json)) {
                throw new ApiError(json.error, {
                  response: json,
                  service: serviceName,
                  url,
                  status: response.status,
                })
              } else {
                throw new ApiError('Missing "error" in response.', {
                  response: json,
                  service: serviceName,
                  url,
                  status: response.status,
                })
              }
            }

            return handleError(response)
          }
        })
        .then((result) => {
          if (result && R.has('error', result)) {
            reject(result)
          }

          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default client
