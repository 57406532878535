import { createStore } from 'vuex'
import publicEvents from './modules/public-events'
import places from './modules/places'
import route from './modules/route'
import savedLocations from './modules/saved-locations'
import adSpace from './modules/ad-space'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  modules: {
    publicEvents,
    places,
    route,
    savedLocations,
    adSpace,
  },
  strict: debug,
})

export default store
