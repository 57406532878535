<template>
  <Metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} - PredictHQ` : `PredictHQ` }}</template>
  </Metainfo>
  <AppHeader />
  <div>
    <template v-if="error === null">
      <router-view></router-view>
      <!--
        When upgrading to Vue 3, `PortalVue` should get replaced
        with native `Teleport` solution. `<portal>` tags are
        included on Modal component from bit.dev
        -->
      <portal-target name="modal" multiple></portal-target>
    </template>
    <template v-else>
      <error-layout :error="error"></error-layout>
    </template>
  </div>
  <AppFooter />
</template>

<script>
import dom from '@/app/dom'
import { NotFoundError } from '@/app/error'
import ErrorLayout from '@/components/views/error'
import config from '@/app/config.js'
import AppHeader from '@phq/header'
import AppFooter from '@phq/app-footer'
import breakpoints from '@phq/breakpoints'

export default {
  components: {
    AppFooter,
    AppHeader,
    ErrorLayout,
  },
  metaInfo() {
    return {
      og: {
        type: 'website',
        image: require('@/assets/img/predicthq-ogimage.png'),
      },
    }
  },
  data() {
    return {
      classes: [],
      error: null,
      breakpoints,
    }
  },
  watch: {
    $route: 'routeChanged',
    classes: {
      handler(newValue) {
        dom.setBodyClasses(newValue.join(' '))
      },
      deep: true,
    },
  },
  created() {
    this.routeChanged()

    if (config.HEAP_ENABLED) {
      this.loadHeapAnalyticsScript()
    }
  },
  errorCaptured(err) {
    this.error = err

    console.error(err)

    if (err instanceof NotFoundError) {
      this.classes = ['supplemental', 'error404']
    } else {
      this.classes = ['supplemental', 'error500']
    }

    // Don't propagate
    return false
  },
  computed: {
    config() {
      return config
    },
  },
  methods: {
    loadHeapAnalyticsScript() {
      const scriptTag = document.createElement('script')
      const heapScript = document.createTextNode(`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
      heap.load(${config.HEAP_APP_ID});`)
      scriptTag.appendChild(heapScript)
      document.getElementsByTagName('head')[0].appendChild(scriptTag)
    },
    routeChanged() {
      // Clear any existing error
      this.error = null

      let classes = []

      if (this.$route.name === '404') {
        classes = ['supplemental', 'error404']
      } else {
        classes = ['supplemental', 'bg-signup']
      }

      this.classes = classes
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/app.scss';

body {
  background-color: $bg-half-grey-hover;
}

.wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
}
</style>
