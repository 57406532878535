<template>
  <div v-if="event" class="map-infobox">
    <div class="event-holder">
      <div class="text">
        <h3>
          <RouterLink :to="`/events/${event.id}`">{{ event.title }}</RouterLink>
        </h3>
        <p>
          <span class="location">{{ event.singleLineLocation }}</span>
          <span class="date">
            {{ formattedDate }}<span v-if="formattedPredictedEnd"> • Predicted end {{ formattedPredictedEnd }}</span>
          </span>
        </p>
      </div>
      <div class="actions">
        <RouterLink :to="`/events/${event.id}`" class="btn">View full details</RouterLink>
        <div class="category-icon">
          <CategoryIcon :icon="event.category" />
        </div>
        <div>
          <ul class="tags">
            <li v-for="label in event.labels" :key="label">{{ label }}</li>
          </ul>
        </div>
      </div>
    </div>
    <footer style="">
      <ul v-if="event.state !== 'deleted'" class="event-stats">
        <li>
          <AppRank
            v-if="event.rank.value !== null"
            v-tooltip="tooltips.rank"
            :progress="event.rank.value"
            type="rank"
          />
        </li>
        <li>
          <AppRank
            v-if="event.localRank.value !== null"
            v-tooltip="tooltips.localRank"
            :progress="event.localRank.value"
            type="local"
          />
        </li>
      </ul>
    </footer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { getEventDate, getEventPredictedEnd } from '@/app/events/helpers/dates'
import AppRank from '@predicthq/vue3.components.app-rank'
import CategoryIcon from '@phq/category-icon'
import tooltips from '@/data/tooltips.json'

defineOptions({
  name: 'MapPopup',
})

const props = defineProps({
  event: {
    type: Object,
    required: false,
  },
})

const formattedDate = computed(() => {
  return getEventDate(props.event)
})
const formattedPredictedEnd = computed(() => {
  return getEventPredictedEnd(props.event)
})
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';

// Putting it here for now so it doesn't clash with other dashboard map things
.map-infobox {
  max-width: 520px !important;
  min-width: 400px !important;
  padding: 0;
  color: $c-text;

  .event-holder {
    padding: 20px 20px 0 20px;

    .actions {
      align-items: center;
      display: grid;
      justify-content: space-between;
      grid-gap: 10px;
      grid-template:
        'btn cat' auto
        'tags tags' auto
        / auto 1fr;

      .btn {
        grid-area: btn;
      }

      > div:nth-child(2) {
        grid-area: cat;

        > span {
          margin-right: 0 !important;
        }
      }

      > div:nth-child(3) {
        grid-area: tags;
      }
    }
  }

  .event-stats {
    display: flex;
    grid-gap: 24px;
    list-style: none;
  }

  footer {
    align-items: center;
    background: #fcfcfc;
    border-top: solid 1px #f0f1f2;
    display: flex;
    padding: 10px 20px;

    .event-stats {
      li:last-child {
        margin-right: 0;
      }

      .rank-container {
        span:nth-child(2) {
          position: relative;
          top: -7px;
        }
      }
    }
  }
}

@media (max-width: #{760px - 1px}) {
  .map-infobox {
    width: 304px;

    .event-holder {
      //padding: 20px 20px 0 20px;

      .actions {
        padding-bottom: 15px;

        a {
          width: calc(100% - 30px);
          margin-bottom: 20px;
        }

        > div:nth-child(2) {
          float: right;
        }

        > div:nth-child(3) {
          float: left;
        }
      }
    }

    footer {
      height: initial;
    }
  }
}
</style>
