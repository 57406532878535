<template>
  <div :class="[bem('', { 'has-image': image }), { 'wrap-795': !block }]">
    <div :class="bem('container', color)">
      <span :class="bem('wrap')">
        <Icon v-if="icon" :class="bem('icon')" v-bind="{ icon }" inline />
        <span :class="bem('title')">
          <slot v-if="$slots.title" name="title"></slot>
          <template v-else>{{ title }}</template>
        </span>
        <span v-if="description" :class="bem('description')" class="text-p-sm">
          {{ description }}
        </span>
        <span :class="bem('cta', isButton ? 'button' : 'link')">
          <AppLink v-if="!isButton" :to="linkUrl">
            <template v-slot:endIcon>
              <Icon icon="nav/arrow-right" color="current" inline />
            </template>
            {{ linkText }}
          </AppLink>
          <AppButton v-else :to="linkUrl" inline>{{ linkText }}</AppButton>
        </span>
        <img v-if="image && (breakpoints && breakpoints.t)" alt="" :class="bem('image')" :src="image.file.url" />
      </span>
    </div>
  </div>
</template>
<script>
import AppButton from '@predicthq/vue3.components.app-button'
import AppLink from '@predicthq/vue3.components.app-link'
import breakpoints from '@phq/breakpoints'
import Icon from '@predicthq/vue3.components.icon'

export default {
  name: 'InlineCta',
  components: {
    AppButton,
    AppLink,
    Icon,
  },
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    color: String,
    description: String,
    icon: [String, Object],
    image: Object,
    file: Array,
    title: String,
    linkText: String,
    linkUrl: String,
    linkAppearance: {
      type: String,
      default: 'link',
      validator: (val) => ['link', 'button'].includes(val),
    },
  },
  data() {
    return {
      breakpoints,
    }
  },
  computed: {
    isButton() {
      return this.linkAppearance === 'button'
    },
  },
}
</script>

<style lang="scss" scoped src="./inline-cta.scss" />
