<template>
  <div :class="bem('')">
    <div v-if="!!$slots.header" :class="bem('header')">
      <slot name="header" />
    </div>
    <ul :class="bem('list')" v-if="results.length > 0">
      <template v-for="(event, idx) in results" :key="`event${event.id}`">
        <EventCard is="li" :subscribedAddons="['phq', 'local', 'phq-attendance']" :event="event" variant="events" />
        <li v-if="showAdSpace(idx)" :class="bem('ad-item')">
          <slot name="adSpace" />
        </li>
      </template>
    </ul>
    <div class="sides v-space-mb-4 v-space-mt-4" v-else>
      <Typography variant="2xl" v-if="title">
        {{ title }}
      </Typography>
      <Typography variant="p" class="c-brand-pink">No events found</Typography>
    </div>
  </div>
</template>
<script>
import EventCard from '@phq/event-card/event-card'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'EventList',
  props: {
    title: {
      type: String,
    },
    results: {
      type: Array,
      required: true,
      default: () => [],
    },
    isLoading: Boolean,
  },
  components: {
    EventCard,
    Typography,
  },
  setup(props, { slots }) {
    // Show ad space based on the list index
    function showAdSpace(idx) {
      if (!slots.adSpace) return false

      if (idx === 2) return true
      // If less than 3 events, show ad space on the last item
      return props.results.length < 3 && props.results.length - 1 === idx
    }

    return {
      showAdSpace,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@phq/scss/globals';
.event-list {
  &__header {
    margin: 32px 0;
  }

  &__list {
    margin: 0;
  }

  &__ad-item {
    list-style: none;
  }
}
</style>
