<template>
  <div :class="bem('')" v-if="venue">
    <div :class="bem('venue-header')">
      <Typography variant="xs">Venue</Typography>
      <AppLink variant="secondary" v-if="false">
        <template v-slot:startIcon>
          <Icon icon="arrow-right" color="current" />
        </template>
        Search this venue
      </AppLink>
    </div>

    <div :class="bem('name')">
      <Typography>{{ venue.name }}</Typography>
      <Typography variant="p-sm" component="p" :class="bem('sm')">
        {{ venue.address }}
      </Typography>
    </div>
    <div :class="bem('stats')" v-if="hasStats">
      <div :class="bem('item')" v-for="(value, label, i) in stats" :key="label + i">
        <Typography :class="bem('label')" variant="label">{{ label }}</Typography>
        <Typography>{{ value }}</Typography>
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import Icon from '@phq/icon'
import Typography from '@predicthq/vue3.components.typography'
import AppLink from '@predicthq/vue3.components.app-link'

export default {
  name: 'EventVenue',
  data() {
    return {
      showFullDescription: false,
    }
  },
  props: {
    /* transformed event object which is used by details page */
    event: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    stats: {
      /* Key Value pair for stats, where Key = label */
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  computed: {
    venue() {
      // We only show venue field when entities type is venue or Airport
      if (R.has('entities', this.event) && !R.isEmpty(this.event.entities)) {
        const validVenues = this.event.entities.filter((e) => {
          return ['venue', 'airport'].includes(e.type.toLowerCase())
        })
        if (validVenues.length > 0) {
          const location = validVenues[0]
          return {
            label: location.type,
            name: location.name,
            address: location.singleLineAddress,
          }
        } else {
          return false
        }
      }
      return false
    },
    hasStats() {
      // TODO: Update when stats are available via API
      return !R.isEmpty(this.stats)
    },
  },
  components: {
    Typography,
    AppLink,
    Icon,
  },
}
</script>

<style lang="scss" module src="./event-venue.scss" />
