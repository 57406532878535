<template>
  <span :class="bem('', modifiers)">
    <component :is="component" />
  </span>
</template>
<script>
import lookup from './icon.lookup.js'

export const iconKeys = Object.keys(lookup)

const colors = ['current']

export default {
  name: 'Icon',
  props: {
    /*
     * Icon name - Mapped to icon.lookup.js
     */
    icon: {
      type: String,
      required: true,
      validator: (value) => {
        return iconKeys.includes(value)
      },
    },
    /*
     * Icon color - applied to the whole icon
     */
    color: {
      type: String,
      validator: (value) => {
        return colors.includes(value)
      },
    },
    /*
     * Icon primary - replaces primary icon color
     * Usually the more dominant color - like dark purple
     */
    colorPrimary: {
      type: String,
      validator: (value) => {
        return colors.includes(value)
      },
    },
    /*
     * Icon secondary - replaces secondary icon color
     * Like pink
     */
    colorSecondary: {
      type: String,
      validator: (value) => {
        return colors.includes(value)
      },
    },
  },
  computed: {
    component() {
      return lookup[this.icon]
    },
    modifiers() {
      const classes = []
      if (this.color) {
        classes.push(this.color)
      }
      if (this.colorPrimary) {
        classes.push(`primary-${this.colorPrimary}`)
      }
      if (this.colorSecondary) {
        classes.push(`secondary-${this.colorSecondary}`)
      }

      return classes
    },
  },
}
</script>

<style lang="scss" module src="./icon.scss" />
