function ApiError(message, { response = null, service = null, url = null, status = null } = {}) {
  this.name = 'ApiError'
  this.message = message
  this.stack = new Error().stack

  this.response = response
  this.service = service
  this.url = url
  this.status = status
}
ApiError.prototype = Object.create(Error.prototype)
ApiError.prototype.constructor = ApiError

export default ApiError
