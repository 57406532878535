import config from '@/app/config'
import events from '@/app/api/phq-public/events'
import phqApiClient from '@/app/api/phq-public/util/client'
import PublicApiEventsSearchResult from '@/app/phq-public/events/search-result'

export default {
  namespaced: true,
  state: {
    searchResult: null,
  },
  actions: {
    get({ commit }, params) {
      phqApiClient.setBearerToken(config.PHQ_PUBLIC_API_PUBLIC_EVENTS_TOKEN)

      return new Promise((resolve, reject) => {
        events
          .search(params)
          .then((result) => {
            const searchResult = new PublicApiEventsSearchResult(result)

            commit('setSearchResult', searchResult)

            resolve(searchResult)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  mutations: {
    setSearchResult(state, searchResult) {
      state.searchResult = searchResult
    },
  },
}
