import client from './util/client'

export default { getLocation, getLocationEvents }

async function getLocation(id) {
  return client.request({ uri: `/saved-locations/${id}` })
}

async function getLocationEvents(id, params) {
  return client.request({ uri: `/saved-locations/${id}/insights/events`, params })
}
