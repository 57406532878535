<template>
  <section class="sides v-space-mb-5 v-space-mt-5" :class="bem('', { staggered })">
    <div class="wrap">
      <Typography v-if="label" :class="[bem('label'), 'text-label']" variant="label">{{ label }}</Typography>
      <ul :class="[bem('cards-list', `${featuredCustomers.length}`), 'row']">
        <li v-for="(item, idx) in featuredCustomers" :key="idx" :class="[bem('card'), col]">
          <AppCustomerCard
            v-bind="item"
            :isLarge="featuredCustomers.length === 4"
            :hasHover="typeof item.linkUrl !== 'undefined' && typeof item.linkTitle !== 'undefined'"
          />
        </li>
      </ul>

      <div v-if="linkText && linkUrl" class="text-center v-space-mt-5">
        <AppButton target="_blank" :url="linkUrl">{{ linkText }}</AppButton>
      </div>
    </div>
  </section>
</template>

<script>
import { customers } from '@/data/customers'
import AppButton from '@predicthq/vue3.components.app-button'
import AppCustomerCard from '@phq/app-customer-card'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'FeaturedCustomers',
  components: {
    AppButton,
    AppCustomerCard,
    Typography,
  },
  computed: {
    col() {
      const { length } = this.featuredCustomers
      let t

      if (length === 4) t = 'col-t-6 col-l-3'
      else if (length > 6) t = 'col-t-4 col-l-2.4'
      else t = 'col-t-4 col-l-2'

      return `col-m-6 ${t}`
    },
  },
  props: {
    label: {
      type: String,
      default: 'Customers using PredictHQ',
    },
    featuredCustomers: {
      type: Array,
      default: () => {
        return [
          customers.Accor.customerCard,
          customers.Dominos.customerCard,
          customers.SpotHero.customerCard,
          customers.WalGreens.customerCard,
          customers.AmazonAlexa.customerCard,
          customers.Uber.customerCard,
          customers.Zartico.customerCard,
          customers.OTAInsight.customerCard,
          customers.Legion.customerCard,
        ]
      },
    },
    staggered: {
      type: Boolean,
      default: false,
    },
    /* If linkUrl and linkText is supplied, populates an AppButton underneath the customer cards */
    linkUrl: {
      type: String,
    },
    linkText: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped src="./featured-customers.scss" />
