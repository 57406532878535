<template>
  <div :class="bem('')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TableContainer',
}
</script>

<style lang="scss" module src="./table-container.scss" />
