/**
Example of how to use this plugin with all options:

this.$messages.show('foo', {
  icon: 'event', // 'star', 'dismiss', 'event', 'alert', 'project', 'task', 'signals'
  type: 'warning', // 'normal', 'warning'
  undo: () => {
    console.log('Undo')
  },
  actions: [
    {
      text: 'Open',
      action: () {
        console.log('do action: open')
      }
    },
    {
      text: 'Close',
      action: () {
        console.log('do action: close')
      }
    }
  ]
})
 */

export default {
  install(app) {
    app.config.globalProperties.$messages = {
      show(text, options = {}) {
        // The system-message component listens for these events
        app.config.globalProperties.emitter.emit('message', {
          text,
          options,
        })
      },
    }
  },
}
