import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import * as R from 'ramda'

// Layouts
import PublicLayout from '../components/views/public'
import ErrorLayout from '../components/views/error'

// Views
import PublicEventsView from '../components/views/public/events'
import PublicEventsDetailView from '../components/views/public/events/detail'
import PublicLocationsView from '../components/views/public/locations'
import PublicLocationsDetailView from '../components/views/public/locations/detail'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      component: PublicLayout,
      children: [
        {
          path: 'events',
          component: PublicEventsView,
          redirect: '/',
          children: [
            {
              path: ':id',
              component: PublicEventsDetailView,
            },
          ],
        },
        {
          path: 'locations',
          component: PublicLocationsView,
          redirect: '/',
          children: [
            {
              path: ':id',
              component: PublicLocationsDetailView,
            },
          ],
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: ErrorLayout,
    },
  ],
})

// Handle page title changes
router.afterEach((to) => {
  if (R.has('meta', to) && R.has('title', to.meta)) {
    store.commit('route/item', {
      name: to.meta.title,
    })
  } else {
    store.commit('route/item', {
      name: '',
    })
  }
})

export default router
