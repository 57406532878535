<template>
  <div :class="bem('')" v-bg-change="{ color: 'pink' }">
    <AppBreadcrumb v-bind="{ breadcrumbs }" />
    <template v-if="location">
      <div class="sides v-space-mt-5 v-space-mb-5">
        <Row lg="1.2fr 1fr" align="start" gap="24" class="wrap">
          <div :class="bem('header')">
            <Typography :variant="breakpoints.t ? '3xl' : '2xl'" component="h1">
              Major events {{ `for ${location.title} ${now.year}` }}
            </Typography>
            <Typography :class="bem('text')">
              There will be <strong>{{ totalEvents.toLocaleString() }} impactful events</strong> for
              {{ location.title }} in the next 90 days alone. Discover what events are impacting demand for your local
              business in {{ location.title }} so your forecast models and operation teams can make faster and more
              accurate decisions, at scale. Try our Events API to track important events.
            </Typography>
          </div>
        </Row>
      </div>
      <div class="wrap">
        <Sheet dense noMargin>
          <ActionPanel
            :title="{
              text: 'Global companies from travel, retail, mobility, and more use PredictHQ',
              variant: 'event',
            }"
            color="pastel-blue"
            alignActions="start"
          >
            <template #description>
              <Typography variant="p-sm" :class="bem('action-panel-text')">
                Stop endlessly searching. Instantly find events impacting your business for {{ location.title }} or
                anywhere in the world.
              </Typography>
              <div :class="bem('action-panel-badges')">
                <AppLink
                  :to="`${config.PHQ_WEBSITE_URL}/intelligence/data-enrichment/event-categories`"
                  variant="secondary"
                >
                  <AppBadge content="19" color="secondary" inline left>Event categories</AppBadge>
                </AppLink>
                <AppLink :to="`${config.PHQ_WEBSITE_URL}/use-cases/event-visibility`" variant="secondary" color="pink">
                  <AppBadge content="27" inline left>million+ events</AppBadge>
                </AppLink>
              </div>
            </template>
            <template #actions>
              <div :class="bem('action-panel-buttons')">
                <AppButton
                  @click="$refs.events.scrollIntoView({ behavior: 'smooth' })"
                  variant="secondary"
                  :class="bem('action-panel-button')"
                >
                  View top events
                </AppButton>
                <AppButton
                  v-tooltip="
                    'Add up to 3 locations to discover events that cause a shift in demand for your business for FREE 😎'
                  "
                  :to="config.SIGNUP_URL"
                  :class="bem('action-panel-button')"
                >
                  Try for free
                </AppButton>
              </div>
            </template>
          </ActionPanel>
        </Sheet>
        <Sheet>
          <Row sm="1fr auto">
            <Typography variant="xs">Insights in the next 90 days</Typography>
            <div class="text-right">
              <AppLink variant="secondary" @click="showShareModal = true">
                <template v-slot:startIcon>
                  <Icon icon="common/share" />
                </template>
                Share
              </AppLink>
            </div>
          </Row>
          <AppStats>
            <AppStat v-bind="item" v-for="(item, idx) in insights" :key="idx" color="dark" />
          </AppStats>
        </Sheet>
        <AppMap
          v-if="coords.lat && coords.lng && !isFetchingEvents"
          :class="bem('map')"
          :direction="breakpoints.t ? 'row' : 'column'"
          :location="formattedLocation"
          :events="mapEvents"
          :markers="lockedEventsMarkers"
          showEventsPopup
        >
          <div :class="bem('map-cta')">
            <AppLink :to="`${config.PHQ_WEBSITE_URL}/major-events`">
              <template v-slot:endIcon>
                <Icon icon="nav/arrow-right" color="current" inline />
              </template>
              View events in other locations
            </AppLink>
            <Typography :class="bem('map-cta-text')">
              <a :href="config.SIGNUP_URL" target="_blank" class="link">Sign up</a> to view events in locations across
              the world
            </Typography>
          </div>
        </AppMap>
        <AlertPanel variant="error" class="v-space-mb-3">
          <p>
            This page only shows up to 10 events,
            <a :href="config.SIGNUP_URL" target="_blank" class="link">sign up</a> to view more
          </p>
        </AlertPanel>
      </div>
      <FeaturedCustomers />
      <div :class="bem('events')" ref="events">
        <div class="sides v-space-mb-5 v-space-mt-5">
          <div class="wrap">
            <div :class="bem('events-header')">
              <Typography variant="2xl">
                Top events that will impact businesses' demand for {{ location.title }} from
                {{ `${now.month} ${now.year}` }}
              </Typography>
              <Typography :class="bem('text')">
                Below are some of the biggest events to occur from
                <strong>{{ `${now.month} ${now.year}` }}</strong
                >. With our powerful Events API and tools, you can train your forecasting models to significantly
                improve their accuracy or simply gain more visibility on what’s going on around your key business
                locations. <a :href="config.SIGNUP_URL" target="_blank" class="link">Get started today</a>.
              </Typography>
            </div>
          </div>
        </div>
        <div v-if="activeTab" class="sides">
          <AppTabs v-if="!isFetchingEvents" class="v-space-mt-5 wrap" v-model="activeTabKey" variant="nav" full-width>
            <AppTab v-for="(tab, key) in tabs" :key="key" :title="tab.name" :value="key">
              <template #endIcon v-if="tab.tooltip">
                <Icon color="current" icon="nav/notes" inline :size="24" v-tooltip.overflow="tab.tooltip" />
              </template>
            </AppTab>
          </AppTabs>
          <InlineHeader class="wrap v-space-mt-3 v-space-mb-3">
            <Typography variant="lg">{{ activeTab.title }}</Typography>
            <template #action>
              <AppLink
                :class="bem('ranking-modal-link')"
                variant="secondary"
                to="https://www.predicthq.com/features/rankings"
              >
                <template #endIcon>
                  <Icon color="current" icon="common/support" inline :size="20" />
                </template>
                What are these rankings?
              </AppLink>
            </template>
          </InlineHeader>
        </div>
        <div v-if="events.count > 0" class="wrap">
          <EventList v-bind="events" :title="eventsTitle" :isLoading="isLoading">
            <template #adSpace>
              <InlineCta v-bind="adSpace" color="blue" block />
            </template>
          </EventList>

          <div class="sides text-center v-space-mt-5 v-space-mb-8">
            <AppButton variant="primary" :to="viewAllUrl">
              <template v-if="totalEvents > 10">
                View all
                <AppBadge :content="totalEvents.toLocaleString()" invert />
                events
              </template>
              <template v-else>
                View events in other locations
              </template>
            </AppButton>
          </div>
        </div>
        <Sheet noMargin>
          <div class="wrap v-space-mb-8 v-space-mt-3">
            <InlineCta linkText="Sign up" :linkUrl="config.SIGNUP_URL" icon="brand/logo" color="blue">
              <template #title>
                Sign up with PredictHQ to
                <a :href="viewAllUrl">view all events</a>
                and
                <a :href="`${controlCenterUrl}/notifications/configurations/create?${location.urlParams.notifications}`"
                  >create notifications</a
                >
              </template>
            </InlineCta>
          </div>
          <div class="wrap v-space-mt-3 v-space-mb-8">
            <Typography variant="label" class="text-label v-space-mb-3">
              Use&nbsp;cases for our event&nbsp;data
            </Typography>
            <CaseTiles
              align="center"
              :tiles="useCases"
              columns="3"
              persistImages
              :linkUrl="`${config.PHQ_WEBSITE_URL}/use-cases`"
              linkText="View more use cases"
            />
          </div>
        </Sheet>
      </div>
    </template>
    <LoadingEvent v-else-if="isLoading" :class="[bem('skeleton'), 'wrap']" />

    <AlertPanel v-else class="wrap v-space-mt-5" variant="error" :class="bem('alert')" title="Location not found">
      This location is missing or otherwise unavailable.
    </AlertPanel>
    <ShareModal
      v-if="showShareModal"
      :title="location.title"
      :open="showShareModal"
      variant="location"
      @close-modal="showShareModal = false"
    />
  </div>
</template>

<script>
import { createApp } from 'vue'

// Modules (Sections)
import config from '@/app/config'
import LoadingEvent from '@phq/loading-event'

// Common
import { AppStats, AppStat } from '@phq/app-stats'
import { Row } from '@phq/grid'
import { getDeepestCommonId } from '@/utils/getDeepestCommonId'
import { getSecondaryEventAddress } from '@/utils/getSecondaryEventAddress'
import { mapState } from 'vuex'
import ActionPanel from '@predicthq/vue3.components.action-panel'
import AlertPanel from '@predicthq/vue3.components.alert-panel'
import AppBadge from '@predicthq/vue3.components.app-badge'
import AppBreadcrumb from '@phq/app-breadcrumb'
import AppButton from '@predicthq/vue3.components.app-button'
import AppLink from '@predicthq/vue3.components.app-link'
import AppMap from '@phq/map'
import AppTab from '@predicthq/vue3.components.app-tab'
import AppTabs from '@predicthq/vue3.components.app-tabs'
import breakpoints from '@phq/breakpoints'
import CaseTiles from '@phq/case-tiles'
import EventList from '@phq/event-list'
import FeaturedCustomers from '@phq/featured-customers'
import Icon from '@predicthq/vue3.components.icon'
import InlineCta from '@phq/inline-cta'
import InlineHeader from '@phq/inline-header'
import moment from 'moment-timezone'
import ShareModal from '@phq/share-modal'
import Sheet from '@phq/sheet'
import Typography from '@predicthq/vue3.components.typography'

const insightsData = {
  phqAttendance: {
    label: 'Predicted Attendance',
    tooltip: 'The predicted number of people attending events within a location',
  },
  predictedEventSpend: {
    label: 'Predicted Event Spend (USD)',
    prefix: '$',
    tooltip:
      'This figure is calculated from our core PredictHQ data, enriched by local economic indicators and partner data',
  },
  attendedEvents: {
    label: 'Attended Events',
    tooltip: 'The sum total of Attended Events (e.g., sports, festivals, concerts, conferences, expos, and community)',
  },
  nonAttendedEvents: {
    label: 'Non-Attended Events',
    tooltip:
      'The sum total of Non-Attended Events (e.g., public holidays, school holidays, observances, academic, and politics)',
  },
  unscheduledEvents: {
    label: 'Unscheduled Events',
    tooltip:
      'The sum total of Unscheduled Events (e.g., severe weather, disasters, terror, health warnings, and airport delays)',
  },
}

const eventsDefaultValue = {
  results: [],
  count: 0,
}

export default {
  name: 'LocationDetails',
  components: {
    ActionPanel,
    AlertPanel,
    AppBadge,
    AppBreadcrumb,
    AppButton,
    AppLink,
    AppStat,
    AppStats,
    AppMap,
    AppTab,
    AppTabs,
    CaseTiles,
    EventList,
    FeaturedCustomers,
    Icon,
    InlineCta,
    InlineHeader,
    LoadingEvent,
    Row,
    ShareModal,
    Sheet,
    Typography,
  },
  metaInfo() {
    const locationName = this.location?.title

    const title = locationName ? `Major Events for ${locationName}` : ''
    const description = `Find out what events are happening for ${
      locationName ?? 'a location'
    }. Including attended events like sports, conferences, expos, concerts, festivals, performing arts, and community events. Also, non-attended events like holidays and unscheduled events including severe weather.  This allows your forecast models can be updated and operation teams can make faster and more accurate decisions, at scale.`

    return {
      title,
      description,
      og: {
        title,
        description,
      },
    }
  },
  data() {
    return {
      activeTabKey: 'attended',
      breakpoints,
      config,
      eventsByType: {
        attended: { results: [], count: 0 },
        non_attended: { results: [], count: 0 },
        unscheduled: { results: [], count: 0 },
      },
      lockedEvents: [],
      location: null,
      isFetchingEvents: false,
      isLoading: false,
      showShareModal: false,
      units: {
        km: 'kilometer',
        mi: 'mile',
      },
    }
  },
  computed: {
    ...mapState('adSpace', ['adSpace']),
    activeTab() {
      return this.tabs[this.activeTabKey]
    },
    breadcrumbs() {
      return [
        { title: 'Major Events', url: `${config.PHQ_WEBSITE_URL}/major-events`, icon: 'rank/phq' },
        { title: this.location?.title },
      ]
    },
    events() {
      if (Object.keys(this.eventsByType).length > 0) {
        return this.eventsByType[this.activeTabKey] ?? eventsDefaultValue
      }

      return eventsDefaultValue
    },
    controlCenterUrl() {
      return config.CONTROL_CENTER_URL
    },
    locationId() {
      return this.$route.params.id
    },
    now() {
      const date = new Date()
      return {
        year: date.getFullYear(),
        month: date.toLocaleString('en-US', { month: 'long' }),
      }
    },
    geometry() {
      if (this.location.geometry.type) return this.location.geometry

      return {
        type: 'Point',
        coordinates: [this.coords.lng, this.coords.lat],
      }
    },
    insights() {
      const summaryInsights = this.location?.summaryInsights || {}
      return Object.entries(insightsData).map(([key, insight]) => ({
        ...insight,
        value: summaryInsights[key],
      }))
    },
    coords() {
      const { lat, lng } = this.location
      return { lat, lng }
    },
    radius() {
      if (!this.location?.properties?.radius) return null
      const { radius: value, radius_unit: unit } = this.location.properties

      return { value, unit }
    },
    formattedLocation() {
      const { title, formattedAddress, properties, labels } = this.location
      const { lat, lng } = this.coords
      const geometry = this.geometry

      return {
        address: formattedAddress,
        properties: {
          ...properties,
          title,
        },
        labels,
        lat,
        lng,
        coords: [lng, lat],
        geometry,
      }
    },
    createdAt() {
      return moment(this.location.createdAt).format('DD MMM Y')
    },
    totalEvents() {
      const summaryInsights = this.location.summaryInsights || {}

      let count = 0
      const insightsToAdd = ['attendedEvents', 'nonAttendedEvents', 'unscheduledEvents']
      insightsToAdd.forEach((insight) => {
        count += summaryInsights[insight] ?? 0
      })

      return count
    },
    eventsTitle() {
      if (this.radius) return `Events within a ${this.radius.value} ${this.units[this.radius.unit]} radius`
      if (this.formattedLocation.address) return `Events in ${this.formattedLocation.address}`

      return 'Events'
    },
    lockedEventsMarkers() {
      const iconProps = {
        icon: 'common/lock',
        inline: true,
        color: 'current',
        size: 12,
      }

      return this.lockedEvents.map((e) => {
        const container = document.createElement('span')
        const marker = createApp(Icon, iconProps).mount(container)
        /** Create custom map marker element to append in MapboxGL */
        return { el: marker.$el, coordinates: { lat: e.lat, lng: e.lng } }
      })
    },
    mapEvents() {
      return [...this.eventsByType.attended.results, ...this.lockedEvents]
    },
    useCases() {
      return [
        {
          title: 'Demand Forecasting',
          color: 'pink',
          desc: 'Improve your forecasting models for more accurate demand predictions.',
          cta: {
            title: 'Learn more',
            url: `${config.PHQ_WEBSITE_URL}/use-cases/demand-forecasting`,
          },
          image: require('@/assets/img/use-cases/illustration-demand-forecasting.png'),
          icon: 'use-cases/demand-forecasting',
        },
        {
          title: 'Visualizations & Insights',
          color: 'purple',
          desc: 'Visualize event data through BI tools like Tableau or Observable to better understand impact.',
          cta: {
            title: 'Learn more',
            url: `${config.PHQ_WEBSITE_URL}/use-cases/visualizations-insights`,
          },
          image: require('@/assets/img/use-cases/visualization-insights-hero.png'),
          icon: 'use-cases/predictive-analytics',
        },
        {
          title: 'Event Visibility',
          color: 'pink',
          desc:
            'Get value from demand intelligence by being able to upload locations, search and identify relevant events efficiently.',
          cta: {
            title: 'Learn more',
            url: `${config.PHQ_WEBSITE_URL}/use-cases/event-visibility`,
          },
          image: require('@/assets/img/use-cases/li-location-details.png'),
          icon: 'use-cases/data-centralization',
        },
      ]
    },
    tabs() {
      const {
        attended: { count: attendedEventsCount },
        non_attended: { count: nonAttendedEventsCount },
        unscheduled: { count: unscheduledEventsCount },
      } = this.eventsByType

      const tabs = {
        attended: {
          name: 'Attended events',
          title: `Top ${attendedEventsCount >= 10 ? '10 ' : ''}Attended Events in ${
            this.location.title
          } in the next 90 days`,
          tooltip: 'Includes sports, conferences, expos, festivals, concerts, performing arts and community events.',
        },
        non_attended: {
          name: 'Non-attended events',
          title: `Top ${nonAttendedEventsCount >= 10 ? '10 ' : ''}Non-Attended Events in ${
            this.location.title
          } in the next 90 days`,
          tooltip: 'Major events such as public holidays, school holidays, observances and more.',
        },
        unscheduled: {
          name: 'Unscheduled events',
          title: `Top ${unscheduledEventsCount >= 10 ? '10 ' : ''}Unscheduled Events in ${
            this.location.title
          } in the next 90 days`,
          tooltip: 'Breaking events such as severe weather, disasters, health warnings and more.',
        },
      }

      // hide if there are no events of that type
      if (Object.keys(this.eventsByType).length > 0) {
        for (const key in tabs) {
          if (this.eventsByType[key].count === 0) delete tabs[key]
        }
      }

      return tabs
    },
    viewAllUrl() {
      return `${config.SIGNUP_URL}/?continue=${encodeURIComponent(
        `/search/events?${this.location.urlParams.searchEvents}`,
      )}`
    },
  },
  methods: {
    async fetchEventsByType(type) {
      const events = await this.$store.dispatch('savedLocations/fetchLocationEvents', {
        id: this.locationId,
        params: {
          sort: 'rank',
          limit: 15,
          date_range_type: 'next_90d',
          type,
        },
      })

      return events?.results && events?.count > 0 ? { ...events } : eventsDefaultValue
    },
    async fetchEvents() {
      try {
        this.isFetchingEvents = true

        const [attendedEvents, nonAttendedEvents, unscheduledEvents] = await Promise.all([
          this.fetchEventsByType('attended'),
          this.fetchEventsByType('non_attended'),
          this.fetchEventsByType('unscheduled'),
        ])

        this.eventsByType = {
          attended: attendedEvents,
          non_attended: nonAttendedEvents,
          unscheduled: unscheduledEvents,
        }

        this.activeTabKey = Object.keys(this.eventsByType).find((key) => this.eventsByType[key].count > 0) ?? 'attended'

        this.lockedEvents = attendedEvents.results.splice(10, 5).map((e) => {
          const { geometry, lat, lng, category } = e
          return { geometry, category, lat, lng, title: 'Sign up to view this event' }
        })

        Object.keys(this.eventsByType).forEach((key) => {
          this.eventsByType[key].results = this.eventsByType[key].results.splice(0, 10)
          const eventsWithNoAddress = this.eventsByType[key].results.filter((e) => !e.singleLineAddress)

          // If events has no address, build the address from the places API
          if (eventsWithNoAddress.length) {
            this.getAddresses(eventsWithNoAddress).then((eventsWithAddress) => {
              this.eventsByType[key].results.forEach((result) => {
                const item = eventsWithAddress.find(({ id }) => id === result.id)
                if (item) result = item
              })
            })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isFetchingEvents = false
      }
    },
    async getAddresses(events) {
      const places = events
        .map((event) => {
          return {
            placeId: getDeepestCommonId(event), // Pick deepest id
            eventId: event.id, // Keep event id for future reference
          }
        })
        .filter(({ placeId }) => placeId)
      if (places.length) {
        const placeIds = [...new Set(places.map((p) => p.placeId))]
        const { results } = await this.$store.dispatch('places/search', {
          id: placeIds.join(','),
          limit: placeIds.length,
        })

        events.forEach((event) => {
          if (!event.placeHierarchies.length) return
          // Find event's place, that was searched for
          const place = places.find((p) => p.eventId === event.id)

          // Find place's full result, returned from the API
          const result = results.find((r) => r.placeId === place.placeId)

          if (result) {
            // Add secondary address line to the event
            event.secondarySingleLineAddress = getSecondaryEventAddress(result)
          }
        })
      }

      return events
    },
    async prepare() {
      this.$progressBar.show()
      this.isLoading = true

      try {
        this.fetchEvents()
        this.location = await this.$store.dispatch('savedLocations/fetchLocation', this.locationId)
        this.$store.dispatch('adSpace/fetchContent')
      } catch (err) {
        console.error(err)
        this.$messages.show('Location not found', {
          type: 'warning',
        })
      }

      this.$progressBar.hide()
      this.isLoading = false
    },
  },
  mounted() {
    this.prepare()
  },
}
</script>

<style src="./detail.scss" lang="scss" scoped />
