import * as R from 'ramda'

import categories from '@/data/categories.json'

// todo - split into multiple files and move
const CATEGORY_TYPE_ATTENDED = 'attended'
const CATEGORY_TYPE_NONATTENDED = 'non-attended'
const CATEGORY_TYPE_SCHEDULED = 'scheduled'
const CATEGORY_TYPE_UNSCHEDULED = 'unscheduled'

export default {
  ranks: [
    {
      id: 'minor',
      value: 1,
      min: 0,
      max: 20,
    },
    {
      id: 'moderate',
      value: 2,
      min: 21,
      max: 40,
    },
    {
      id: 'important',
      value: 3,
      min: 41,
      max: 60,
    },
    {
      id: 'significant',
      value: 4,
      min: 61,
      max: 80,
    },
    {
      id: 'major',
      value: 5,
      min: 81,
      max: 100,
    },
  ],
  categories: categories
    .filter((c) => !c.beta)
    .map((c) => ({
      id: c.tag,
      type: c.type,
    })),
  getAttendedCategories() {
    return R.filter((category) => {
      return category.type === CATEGORY_TYPE_ATTENDED
    }, this.categories)
  },
  getNonAttendedCategories() {
    return R.filter((category) => {
      return category.type === CATEGORY_TYPE_NONATTENDED
    }, this.categories)
  },
  getScheduledCategories() {
    return R.filter((category) => {
      return category.type === CATEGORY_TYPE_SCHEDULED
    }, this.categories)
  },
  getUnscheduledCategories() {
    return R.filter((category) => {
      return category.type === CATEGORY_TYPE_UNSCHEDULED
    }, this.categories)
  },
  getCategory(id) {
    const category = this.categories.find((category) => category.id === id)

    if (category === undefined) {
      throw new Error('Unknown category')
    }

    return category
  },
  getRank(val) {
    const rank = this.ranks.find((rank) => rank.value === val)

    if (rank === undefined) {
      throw new Error('Unknown rank level')
    }

    return rank
  },
}
