import * as R from 'ramda'

const config = {
  APP_VERSION: 'dev',
  APP_URL: '',
  USER_AGENT: 'PHQ-Public-Events',
  API_ENDPOINT: '',
  PHQ_PUBLIC_API_ENDPOINT: '',
  PHQ_PUBLIC_API_PUBLIC_EVENTS_TOKEN: '',
  SIGNUP_URL: '',
  CONTROL_CENTER_URL: '',
  PHQ_API_DOCS_URL: '',
  PHQ_WEBSITE_URL: '',
  SENTRY_DSN: '',
  SENTRY_ENABLED: false,
  GTM_TRACKING_ID: '',
  GTM_TRACKING_ENABLED: false,
  HEAP_ENABLED: false,
  HEAP_APP_ID: '',
  PHQ_INSTAGRAM_URL: 'https://www.instagram.com/predicthqteam',
  PHQ_TWITTER_URL: 'https://twitter.com/predicthq',
  PHQ_LINKEDIN_URL: 'https://www.linkedin.com/company/predicthq',
  PHQ_GITHUB_URL: 'https://github.com/predicthq',
  CONTENTFUL_ENVIRONMENT: '',
  CONTENTFUL_SPACE_ID: '',
  CONTENTFUL_ACCESS_TOKEN: '',
  CONTENTFUL_HOST: '',

  load(config) {
    const newConfig = R.merge(this, config)

    // Update existing config
    const self = this

    R.keys(newConfig).map((key) => {
      if (R.has(key, self) && typeof self[key] !== 'function') {
        self[key] = newConfig[key]
      }
    })
  },
}

// Handle webpack (dev) .env config
if (typeof process.env !== 'undefined') {
  const env = {}

  R.mapObjIndexed((value, key) => {
    if (key.startsWith('VUE_APP_')) {
      if (['true', 'false'].includes(value)) {
        // Convert string "true"/"false" to boolean
        env[key.slice('VUE_APP_'.length)] = value === 'true'
      } else {
        env[key.slice('VUE_APP_'.length)] = value
      }
    }
  }, process.env)

  config.load(env)
}

// Handle runtime env.js config
if (typeof window !== 'undefined' && typeof window._runtimeEnv !== 'undefined') {
  // Convert string 1 and 0 to true/false
  const runtimeEnv = {}

  R.map((key) => {
    if (['0', '1'].includes(window._runtimeEnv[key])) {
      runtimeEnv[key] = window._runtimeEnv[key] === '1'
    } else {
      runtimeEnv[key] = window._runtimeEnv[key]
    }
  }, Object.keys(window._runtimeEnv))

  config.load(runtimeEnv)
}

export default config
