import * as R from 'ramda'
import mapboxgl from 'mapbox-gl'
import { createApp } from 'vue'
import router from '@/router'
import LangPlugin from '@/plugins/lang'
import MapPopup from './popup.vue'
import { TooltipDirective } from '@/utils/directives'

// In relation to Kms
const UNIT_RATIOS = {
  m: 0.001,
  km: 1,
  ft: 0.0003048,
  mi: 1.60934,
}

export default {
  created() {
    // Needs to be non-reactive, so set here
    // and not in "data"
    this.map = null
  },
  methods: {
    supportsWebGl() {
      try {
        const canvas = document.createElement('canvas')

        // Get WebGLRenderingContext from canvas element.
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')

        // Report the result.
        return gl !== null
      } catch (error) {
        return false
      }
    },
    addEventsSource() {
      this.map.addSource('events', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })
    },
    addLocationSource() {
      this.map.addSource('location', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })
    },
    addHeatmapEventsSource() {
      this.map.addSource('heatmap-events', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })
    },
    addWithinSource() {
      this.map.addSource('within', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })
    },
    addLocationLayer() {
      this.map.addLayer({
        id: 'marker',
        type: 'circle',
        source: 'location',
        paint: {
          'circle-radius': 3,
          'circle-stroke-opacity': 1,
          'circle-stroke-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
            default: '#508bcb',
          },
          'circle-stroke-width': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, 4],
              [2, 4],
              [3, 8],
              [4, 8],
              [5, 10],
            ],
            default: 6,
          },
          'circle-color': '#ffffff',
        },
      })

      this.map.addLayer({
        id: 'locations-click-target',
        type: 'circle',
        source: 'location',
        paint: {
          'circle-opacity': 0,
          'circle-radius': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, 6],
              [2, 6],
              [3, 10],
              [4, 10],
              [5, 14],
            ],
          },
        },
      })
    },
    addHeatmapEventsLayer() {
      this.map.addLayer({
        id: 'events-heatmap',
        type: 'heatmap',
        source: 'heatmap-events',
        maxZoom: 9,
        paint: {
          'heatmap-weight': {
            property: 'rankLevel',
            type: 'exponential',
            stops: [
              [1, 0.1],
              [2, 0.25],
              [3, 0.5],
              [4, 0.75],
              [5, 1],
            ],
          },
          'heatmap-intensity': {
            stops: [
              [0, 1],
              [9, 3],
            ],
          },
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(255, 248, 230, 0)',
            0.1,
            'rgb(255, 248, 230)',
            0.2,
            'rgb(255, 242, 206)',
            0.3,
            'rgb(255, 235, 181)',
            0.4,
            'rgb(255, 229, 157)',
            0.5,
            'rgb(255, 222, 132)',
            0.6,
            'rgb(255, 213, 96)',
            0.7,
            'rgb(255, 203, 59)',
            0.8,
            'rgb(255, 190, 10)',
            0.9,
            'rgb(254, 176, 22)',
            1,
            'rgb(252, 158, 37)',
          ],
          'heatmap-radius': {
            stops: [
              [0, 8],
              [9, 30],
            ],
          },
          'heatmap-opacity': {
            default: 1,
            stops: [
              [3, 1],
              [5, 0],
            ],
          },
        },
      })
    },
    addEventsLayer() {
      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'maine',
        type: 'fill',
        source: 'events', // reference the data source
        layout: {},
        minZoom: 4,
        paint: {
          'fill-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'fill-opacity': {
            stops: [
              [3, 0],
              [4, 0.1],
            ],
          },
        },
        filter: ['==', '$type', 'Polygon'],
      })
      this.map.addLayer({
        id: 'linestring',
        type: 'line',
        source: 'events',
        layout: {},
        minZoom: 4,
        paint: {
          'line-color': {
            default: '#616161',
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'line-width': 2,
        },
        filter: ['==', '$type', 'LineString'],
      })
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: 'outline',
        type: 'line',
        source: 'events',
        layout: {},
        minZoom: 4,
        paint: {
          'line-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'line-width': 2,
          'line-opacity': {
            stops: [
              [3, 0],
              [4, 1],
            ],
          },
        },
        filter: ['==', '$type', 'Polygon'],
      })

      this.map.addLayer({
        id: 'polygon-click-target',
        type: 'fill',
        source: 'events', // reference the data source
        layout: {},
        minZoom: 4,
        paint: {
          'fill-color': '#00d09e',
          'fill-opacity': 0,
        },
        filter: ['any', ['==', '$type', 'Polygon'], ['==', '$type', 'LineString']],
      })

      this.map.addLayer({
        id: 'events',
        type: 'circle',
        source: 'events',
        minZoom: 4,
        paint: {
          'circle-radius': 3,
          // Transition from heatmap to circle layer by zoom level
          'circle-opacity': {
            stops: [
              [4, 0],
              [5, 1],
            ],
          },
          'circle-stroke-opacity': {
            stops: [
              [4, 0],
              [5, 1],
            ],
          },
          'circle-stroke-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'circle-stroke-width': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, 2],
              [2, 2],
              [3, 6],
              [4, 6],
              [5, 10],
            ],
          },
          'circle-color': '#ffffff',
        },
        filter: ['==', '$type', 'Point'],
      })

      this.map.addLayer({
        id: 'events-click-target',
        type: 'circle',
        source: 'events',
        minZoom: 7,
        paint: {
          // 'circle-color': '#00d09e',
          'circle-opacity': 0,
          'circle-radius': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, 6],
              [2, 6],
              [3, 10],
              [4, 10],
              [5, 14],
            ],
          },
        },
        filter: ['==', '$type', 'Point'],
      })
    },
    addSingleEventsLayer() {
      this.map.addLayer({
        id: 'events',
        type: 'circle',
        source: 'events',
        paint: {
          'circle-radius': 3,
          'circle-stroke-opacity': 1,
          'circle-stroke-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'circle-stroke-width': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, 4],
              [2, 4],
              [3, 8],
              [4, 8],
              [5, 10],
            ],
          },
          'circle-color': '#ffffff',
        },
        filter: ['==', '$type', 'Point'],
      })

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'maine',
        type: 'fill',
        source: 'events', // reference the data source
        layout: {},
        paint: {
          'fill-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'fill-opacity': 0.1,
        },
        filter: ['==', '$type', 'Polygon'],
      })
      this.map.addLayer({
        id: 'linestring',
        type: 'line',
        source: 'events',
        layout: {},
        paint: {
          'line-color': {
            default: '#616161',
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'line-width': 2,
        },
        filter: ['==', '$type', 'LineString'],
      })
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: 'outline',
        type: 'line',
        source: 'events',
        layout: {},
        paint: {
          'line-color': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, '#ffae00'],
              [2, '#ff8a19'],
              [3, '#ff6831'],
              [4, '#ff454a'],
              [5, '#ff2364'],
            ],
          },
          'line-width': 2,
          'line-opacity': 1,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      this.map.addLayer({
        id: 'events-click-target',
        type: 'circle',
        source: 'events',
        paint: {
          'circle-opacity': 0,
          'circle-radius': {
            property: 'rankLevel',
            type: 'categorical',
            stops: [
              [1, 6],
              [2, 6],
              [3, 10],
              [4, 10],
              [5, 14],
            ],
          },
        },
      })
    },
    addWithinLayers() {
      this.map.addLayer({
        id: 'within',
        type: 'fill',
        source: 'within',
        paint: {
          'fill-color': 'rgb(80,139,203)',
          'fill-opacity': 0.25,
        },
        filter: ['==', '$type', 'Polygon'],
      })
      this.map.addLayer({
        id: 'within-outline',
        type: 'line',
        source: 'within',
        paint: {
          'line-color': 'rgb(80,139,203)',
          'line-width': 1,
          'line-dasharray': [2, 1],
        },
        filter: ['==', '$type', 'Polygon'],
      })
      this.map.addLayer({
        id: 'within-circle',
        type: 'circle',
        source: 'within',
        paint: {
          'circle-radius': 3,
          'circle-stroke-opacity': 1,
          'circle-stroke-width': 6,
          'circle-stroke-color': 'rgb(80,139,203)',
          'circle-color': '#ffffff',
        },
        filter: ['==', '$type', 'Point'],
      })
    },
    getBoundsForGeoJsonFeatures(features) {
      const coords = []

      const getCoords = (obj) => {
        for (const x of obj) {
          if (typeof x === 'number') return obj
          return getCoords(x)
        }
      }

      features.map((feature) => {
        coords.push(getCoords(feature.geometry.coordinates))
      })

      const bounds = coords.reduce(function (bounds, coord) {
        return bounds.extend(coord)
      }, new mapboxgl.LngLatBounds(coords[0], coords[0]))

      return bounds
    },
    getGeoJsonFeatureForEvent(event) {
      return {
        type: 'Feature',
        properties: {
          eventId: event.id,
          title: event.title,
          rank: event.rank,
          rankLevel: event.rank?.level,
          category: event.category,
        },
        geometry: event.geometry,
      }
    },
    getGeoJsonFeatures(events, type = null) {
      const features = []
      let filteredEvents = events

      if (type) {
        filteredEvents = events.filter((x) => {
          const filter = type !== 'Location' ? type : 'Polygon'

          return x.geometry.type === filter
        })
      }
      R.map((event) => {
        const props = this.getGeoJsonFeatureForEvent(event)

        if (type === 'Location') {
          props.geometry.coordinates = event.location.coords
          props.geometry.type = 'Point'
        }
        features.push(props)
      }, filteredEvents)

      return features
    },
    getGeoJsonCircleForWithin({ lat, long, radius, unit, points = 64 }) {
      // Radius is in KMs so converting other units to KMs
      const actualRadius = UNIT_RATIOS[unit] * radius

      const coords = {
        latitude: lat,
        longitude: long,
      }

      const ret = []
      const distanceX = actualRadius / (111.32 * Math.cos((coords.latitude * Math.PI) / 180))
      const distanceY = actualRadius / 110.574

      let theta, x, y

      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI)
        x = distanceX * Math.cos(theta)
        y = distanceY * Math.sin(theta)

        ret.push([coords.longitude + x, coords.latitude + y])
      }
      ret.push(ret[0])

      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [long, lat],
            },
          },
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [ret],
            },
          },
        ],
      }
    },
    zoomIn() {
      this.map.zoomIn()
    },
    zoomOut() {
      this.map.zoomOut()
    },
    toggleFullscreen() {
      if (this.fullScreen) {
        this.exitFullscreen()
        return
      }
      this.requestFullscreen()
    },

    requestFullscreen() {
      // const container = this.map.getContainer()
      const container = document.querySelector('#event-map')
      const rfs =
        container.requestFullscreen ||
        container.webkitRequestFullScreen ||
        container.mozRequestFullScreen ||
        container.msRequestFullscreen

      rfs.call(container)
      this.fullScreen = true
    },
    exitFullscreen() {
      document.exitFullscreen()
      this.fullScreen = false
    },
    openPopupForEvent(event, coords = null) {
      if (this.mapPopup) {
        this.mapPopup.remove()
      }
      const latlng = coords || event.location.coords

      this.mapPopup = new mapboxgl.Popup()
        .setLngLat(latlng)
        .setHTML('<div id="vue-map-popup-content"></div>')
        .addTo(this.map)

      const app = createApp(MapPopup, { event })
      app.use(router)
      app.use(LangPlugin)
      app.directive('tooltip', TooltipDirective)

      app.mount('#vue-map-popup-content')
    },
    openPopupForEventId(eventId) {
      const matchingEvents = R.filter((event) => event.id === eventId, this.events)

      if (matchingEvents.length > 0) {
        this.openPopupForEvent(matchingEvents[0])
        this.map.panTo(new mapboxgl.LngLat(matchingEvents[0].lng, matchingEvents[0].lat))
      }
    },
  },
}
