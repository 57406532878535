<template>
  <div :class="$style.zoom">
    <a :class="$style['zoom-button']" @click="$emit('in')"><Icon color="current" icon="common/expand-sm" inline /></a>
    <div :class="$style['zoom-separator']"></div>
    <a :class="$style['zoom-button']" @click="$emit('out')"
      ><Icon color="current" icon="common/contract-sm" inline
    /></a>
  </div>
</template>
<script>
import Icon from '@predicthq/vue3.components.icon'
export default {
  name: 'Zoom',
  components: {
    Icon,
  },
}
</script>
<style lang="scss" module src="./zoom.scss" />
