<template>
  <transition :name="transition">
    <dialog :class="[modalClass, bem('', { open })]" v-show="open">
      <system-messages v-if="open" />
      <slot />
    </dialog>
  </transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import SystemMessages from '@/components/shared/system-messages'

// TODO: Stacking context issue https://github.com/GoogleChrome/dialog-polyfill#limitations
// TODO: Open/close transition
export default {
  name: 'Modal',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    modalClass: {
      type: [String, Object, Array],
      default: '',
    },
    transition: [String],
  },
  components: {
    SystemMessages,
  },
  methods: {
    show() {
      this.$emit('before-open')
      this.$el.showModal()
      disableBodyScroll(this.$el)
    },
    close() {
      // Need to check if the element is open, otherwise
      // this causes an exception on browsers that use the polyfill.
      if (this.$el.open) {
        this.$el.close()
        this.onClose()
      }
    },
    onClose() {
      enableBodyScroll(this.$el)
      // TODO: Restore focus on close
      // https://gist.github.com/samthor/babe9fad4a65625b301ba482dad284d1
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.show()
      } else {
        this.close()
      }
    },
  },
  async mounted() {
    if (!this.$el.showModal) {
      const dialogPolyfill = (await import('dialog-polyfill')).default
      dialogPolyfill.registerDialog(this.$el)
    }

    if (this.open) {
      this.show()
    }

    // Native dialogs can be closed by escape presses, so listen for this
    this.$el.addEventListener('close', () => {
      this.$emit('close')
      this.onClose()
    })

    // Also listen for clicks on the backdrop to close
    this.$el.addEventListener('click', (e) => {
      // There's currently no way to determine whether the backdrop has
      // been clicked, so check if the dialog is the event target - clicks
      // on children inside the dialog won't trigger this.
      if (e.target === this.$el) {
        this.$emit('close')
        this.onClose()
      }
    })
  },
  beforeUnmount() {
    this.onClose()
  },
}
</script>

<style lang="scss" module>
@import '~@phq/scss/globals';

@mixin backdropBg() {
  background-color: rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(5px);
}

.modal {
  // This is a low-level component to handle modal behaviour;
  // it's up to the child component to provide styling for the content
  background-color: transparent;
  border: 0;
  height: auto;
  left: unset;
  right: unset;
  top: unset;
  bottom: unset;
  display: block;
  max-width: none;
  max-height: none;

  // &--open {
  //   display: block;
  // }

  &::backdrop {
    @include backdropBg();
  }

  // Polyfill
  + :global(.backdrop) {
    @include backdropBg();
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
