function PublicApiEventsEventPlaceHierarchy(ids) {
  const hierarchy = {
    get ids() {
      return ids
    },
    get lowest() {
      return ids.slice(-1)
    },
  }

  return hierarchy
}

export default PublicApiEventsEventPlaceHierarchy
