<template>
  <div :class="bem('')">
    <section :class="bem('header')">
      <header>
        <h1></h1>
      </header>
      <div>
        <section>
          <h3></h3>
          <p></p>
        </section>
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <footer></footer>
    </section>
    <div :class="bem('body')">
      <div :class="bem('left')">
        <section :class="bem('lines')">
          <h3></h3>
          <p></p>
        </section>
        <section :class="bem('lines')">
          <h3></h3>
          <p></p>
        </section>
        <section :class="bem('square')">
          <h3></h3>
          <p></p>
        </section>
      </div>
      <div :class="bem('right')">
        <section :class="bem('square')">
          <h3></h3>
          <p></p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingEvent',
}
</script>

<style lang="scss" scoped src="./loading-event.scss" />
