<template>
  <transition name="fade">
    <div class="sys-msg" :class="[[typeClass], [iconClass]]" v-if="show" @mouseover="mouseover" @mouseout="mouseout">
      <a class="btn strong undo" v-if="undo" @click="onUndo">Undo</a>
      <a class="btn action" v-for="(action, key) in actions" :key="key" @click="onAction(action)">{{ action.text }}</a>
      <p>{{ text }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'system-messages-message',
  data() {
    return {
      show: true,
      timer: null,
    }
  },
  props: {
    type: {
      type: String,
      required: false,
      validator(value) {
        var valid = ['normal', 'warning']
        return valid.indexOf(value) >= 0
      },
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      validator(value) {
        var valid = ['star', 'dismiss', 'event', 'alert', 'project', 'task', 'signals']
        return value.length === 0 || valid.indexOf(value) >= 0
      },
    },
    actions: {
      type: Array,
      required: false,
    },
    undo: {
      type: Function,
      required: false,
    },
  },
  created() {
    this.open()
  },
  computed: {
    typeClass() {
      return this.type === 'warning' ? 'type-warning' : ''
    },
    iconClass() {
      var icon = this.icon

      if (this.type === 'warning') {
        // Override the icon when showing a warning
        icon = 'alert'
      }

      return icon.length > 0 ? 'icon-' + icon : ''
    },
  },
  methods: {
    mouseover() {
      if (this.timer !== null) {
        // Allow the message to remain longer
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    mouseout() {
      this.open(700)
    },
    open(timeout = 5000) {
      var vm = this

      vm.show = true
      vm.timer = setTimeout(() => {
        vm.close()
      }, timeout)
    },
    close() {
      this.show = false

      if (this.timer !== null) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    onUndo() {
      this.undo()
      this.close()
    },
    onAction(action) {
      action.action()
      this.close()
    },
  },
}
</script>
