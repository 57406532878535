<template>
  <component v-if="isValidEvent" :is="is" :class="getCardClasses">
    <BaseLink :class="bem('preview', getPreviewVariant)" :to="`/events/${event.id}`">
      <div v-if="isBroadcastEvent" :class="bem('aside')">
        <AppTypography :class="bem('aside-h')" variant="xs">
          <span v-if="broadcastPlaceName">{{ broadcastPlaceName }}, </span>{{ broadcastPlace.region }}
        </AppTypography>
        <AppTypography :class="bem('aside-p')" variant="p-xs">
          {{ broadcastPlaceType }}
        </AppTypography>
      </div>
      <div :class="bem('content')">
        <div :class="bem('header')">
          <div :class="bem('info')">
            <div :class="bem('title-wrapper')">
              <AppTypography :class="bem('title')" variant="lg">
                {{ event.title }}
              </AppTypography>
              <AppChip v-if="tag" v-tooltip="tag.tooltip" :class="bem('title-tag')" :variant="tag.variant">
                {{ tag.label }}
              </AppChip>
            </div>

            <AppTypography v-if="address" variant="p-sm">
              {{ address }}
              <AppChip v-if="isPolygonEvent" variant="pastel-blue">
                Polygon
              </AppChip>
            </AppTypography>
            <AppTypography :class="bem('text-light')" component="p" variant="p-sm">
              <span>
                {{ formattedDate }}
                <span v-if="isBroadcastEvent">
                  {{ broadcastTimeZone }}
                </span>
                <template v-if="formattedPredictedEnd">
                  • {{ formattedPredictedEnd.date }}
                  <span class="c-brand-purple">{{ formattedPredictedEnd.time }} (Predicted end time)</span>
                </template>
              </span>
            </AppTypography>
          </div>

          <div :class="bem('addons-group')">
            <div v-if="showPhqViewershipOnEvent" :class="bem('addon', 'viewership')">
              <AppIcon :class="bem('addon', 'viewership-icon')" icon="event-cat/tv" />
              <AddonText :class="$style['viewership-text']" label="PHQ TV Viewership">
                {{ phqViewership }}
              </AddonText>
            </div>

            <template v-if="!isBroadcastEvent">
              <div v-if="totalPredictedSpend" :class="bem('addon', 'spend')">
                <AddonText label="Predicted Event Spend (USD)">{{ totalPredictedSpend }}</AddonText>
              </div>
              <div v-if="showPhqAttendanceOnEvent" :class="bem('addon', 'attendance')">
                <AddonText label="Predicted Attendance">
                  {{ phqAttendance }}
                </AddonText>
              </div>

              <div :class="bem('ranks')">
                <AppRank
                  v-if="showPhqRank"
                  v-tooltip.left="tooltips.rank"
                  :class="getPhqRankClasses"
                  :locked="!hasAccessToPhqRank"
                  :progress="phqRank"
                  :radius="24"
                  type="rank"
                />
                <AppRank
                  v-if="showLocalRank"
                  v-tooltip.left="tooltips.localRank"
                  :class="getLocalRankClasses"
                  :locked="!hasAccessToLocalRank"
                  :progress="localRank"
                  :radius="24"
                  type="local"
                  @click.stop.prevent="upgrade('local-rank', !hasAccessToLocalRank)"
                />
              </div>
            </template>
          </div>
        </div>
        <div :class="bem('footer')">
          <div :class="bem('chip-group')">
            <AppIcon :class="bem('icon')" :icon="`event-cat/${category}`" />
            <AppChip v-for="label in labels" :key="label" :class="bem('label')" type="tag">
              {{ label }}
            </AppChip>
          </div>
          <div v-if="!isBroadcastEvent" :class="bem('actions')">
            <AppLink :class="bem('actions', 'toggle')" variant="secondary" @click.stop.prevent="toggleExpanded">
              <template v-slot:startIcon>
                <AppIcon :icon="getToggleIcon" color="current" inline />
              </template>
              {{ expanded ? 'Hide Details' : 'More Details' }}
            </AppLink>
          </div>
        </div>
      </div>
    </BaseLink>
    <!-- Expanded content details -->
    <ExpandTransition>
      <div v-if="expanded">
        <div v-if="expanded" :class="bem('content', 'detail')">
          <div :class="bem('col1')">
            <div :class="bem('item', 'dates-container')">
              <AppTypography :class="bem('item-label')" variant="label">
                Date &amp; Time
              </AppTypography>
              <div :class="bem('dates-box')">
                <div :class="bem('date')">
                  <AppTypography>{{ startDate }}</AppTypography>
                  <AppTypography v-if="startTime" :class="bem('text-light')" component="p" variant="p-sm">
                    {{ startTime }}
                  </AppTypography>
                </div>
                <AppIcon color="current" icon="bullet/arrow" />
                <div :class="bem('date')">
                  <AppTypography>{{ endDate }}</AppTypography>
                  <AppTypography
                    v-if="endTime"
                    :class="bem('time', [{ predicted: isPredictedEndTime }])"
                    component="p"
                    variant="p-sm"
                  >
                    {{ endTime }}
                    <template v-if="isPredictedEndTime">
                      (Predicted end time)
                    </template>
                  </AppTypography>
                </div>
              </div>
            </div>
            <div v-if="timeZone.length > 0" :class="bem('item')">
              <AppTypography :class="bem('item-label')" variant="label">
                Timezone
              </AppTypography>
              <AppTypography>{{ timeZone }}</AppTypography>
            </div>
          </div>
          <div :class="bem('col2')">
            <div v-if="duration" :class="bem('item')">
              <AppTypography :class="bem('item-label')" variant="label">
                Duration
              </AppTypography>
              <AppTypography>{{ duration }}</AppTypography>
            </div>
            <div v-if="venue" :class="bem('item')">
              <AppTypography :class="bem('item-label')" variant="label">
                {{ venue.label }}
              </AppTypography>
              <AppTypography>{{ venue.name }}</AppTypography>
              <AppTypography :class="bem('text-light')" component="p" variant="p-sm">
                {{ venue.address }}
              </AppTypography>
            </div>
          </div>
          <div v-if="description.length > 0" :class="bem('description')">
            <AppTypography :class="bem('item-label')" variant="label">
              Description
            </AppTypography>

            <ExpandTransition>
              <AppTypography v-if="!showFullDescription">
                {{ description }}
                <AppLink
                  v-if="hasLongerDescription"
                  :class="bem('toggle')"
                  variant="secondary"
                  @click.stop.prevent="showFullDescription = !showFullDescription"
                  >{{ showFullDescription ? 'less' : 'more' }}</AppLink
                >
              </AppTypography>
            </ExpandTransition>

            <ExpandTransition>
              <AppTypography v-if="showFullDescription">
                {{ description }}
                <AppLink
                  v-if="hasLongerDescription"
                  :class="bem('toggle')"
                  variant="secondary"
                  @click.stop.prevent="showFullDescription = !showFullDescription"
                  >{{ showFullDescription ? 'less' : 'more' }}</AppLink
                >
              </AppTypography>
            </ExpandTransition>
          </div>
          <div :class="bem('item-actions')">
            <AppLink v-if="hasAccessToLoop" :class="bem('feedback')" variant="secondary" @click.stop.prevent="openLoop">
              <template v-slot:startIcon>
                <AppIcon color="current" icon="common/chat" inline />
              </template>
              Event Feedback
            </AppLink>
          </div>
        </div>
      </div>
    </ExpandTransition>
  </component>
</template>
<script>
import { computed } from 'vue'
import { getEventDate, getQuickViewDate, getEventPredictedEndFormat } from './utils/dates'
import { upperFirst } from 'lodash'
import * as R from 'ramda'
import AddonText from '@phq/addon-text'
import AppChip from '@predicthq/vue3.components.app-chip'
import AppIcon from '@predicthq/vue3.components.icon/icon.vue'
import AppLink from '@predicthq/vue3.components.app-link'
import AppRank from '@predicthq/vue3.components.app-rank'
import AppTypography from '@predicthq/vue3.components.typography'
import BaseLink from '@predicthq/vue3.components.base-link'
import BaseSkeleton from '@predicthq/vue3.components.base-skeleton'
import eventTags from '@/data/event-tags.json'
import ExpandTransition from '../expand-transition'
import moment from 'moment-timezone'
import numeral from 'numeral'
import tooltips from '@/data/tooltips.json'
import useExpand from '@use/expand'

export default {
  name: 'EventCard',
  components: {
    AddonText,
    AppChip,
    AppIcon,
    AppLink,
    AppRank,
    AppTypography,
    BaseLink,
    BaseSkeleton,
    ExpandTransition,
  },
  props: {
    variant: {
      type: String,
      default: 'events',
      validator: (value) => {
        return ['events', 'notifications', 'broadcast'].indexOf(value) !== -1
      },
    },
    event: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    subscribedAddons: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    is: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      // expanded: false,
      targetExpanded: false,
      targetPhqRank: false,
      targetLocalRank: false,
      targetMoreDetails: false,
      showFullDescription: false,
      tooltips,
    }
  },
  setup(props) {
    const { expanded, toggleExpanded } = useExpand()

    const totalPredictedSpend = computed(() => {
      const totalSpend = props.event.predictedSpend

      if (typeof totalSpend !== 'number') return null

      if (totalSpend < 500) return 'Less than $500'

      return `$${totalSpend.toLocaleString()}`
    })

    return {
      expanded,
      toggleExpanded,
      totalPredictedSpend,
    }
  },
  computed: {
    getPreviewVariant() {
      return ['broadcasts', 'broadcast', 'notifications'].includes(this.variant) ? 'split' : ''
    },
    getCardClasses() {
      return this.expanded ? this.bem('', 'expanded') : this.bem('')
    },
    address() {
      const { singleLineAddress: a, secondarySingleLineAddress: b } = this.event
      return a || b
    },
    isBroadcastEvent() {
      return this.variant === 'broadcast'
    },
    isPolygonEvent() {
      return ['LineString', 'Polygon', 'MultiPolygon'].includes(this.event?.geometry?.type)
    },
    getToggleIcon() {
      return `common/${this.expanded ? 'contract-sm' : 'expand-sm'}`
    },
    isValidEvent() {
      return !R.isEmpty(this.event)
    },
    getClasses() {
      if (this.expanded) {
        return [this.$style['event-card'], this.$style.expanded, this.$style[this.variant]]
      }
      return [this.$style['event-card'], this.$style[this.variant]]
    },
    getPhqRankClasses() {
      return !this.hasAccessToPhqRank ? this.bem('rank', 'target') : this.bem('rank')
    },
    getLocalRankClasses() {
      return !this.hasAccessToLocalRank ? this.bem('rank', 'target') : this.bem('rank')
    },
    eventData() {
      return ['events', 'bradcast', 'notifications'].includes(this.variant) ? this.event : {}
    },
    isLoading() {
      return R.isEmpty(this.event)
    },
    isPredictedEndTime() {
      return R.has('predictedEnd', this.event) && this.event.predictedEnd !== null
    },
    category() {
      return this.event?.category
    },
    labels() {
      return this.event?.labels || []
    },
    formattedDate() {
      return getEventDate(this.event, this.variant)
    },
    formattedPredictedEnd() {
      return getEventPredictedEndFormat(this.event)
    },
    quickViewEventDate() {
      return getQuickViewDate(this.event?.start, this.event?.end, this.event?.tz)
    },
    hasAccessToPhqRank() {
      return this.subscribedAddons.includes('phq')
    },
    hasAccessToLocalRank() {
      return this.subscribedAddons.includes('local')
    },
    hasAccessToPhqAttendance() {
      return this.subscribedAddons.includes('phq-attendance')
    },
    hasAccessToPhqViewership() {
      return this.subscribedAddons.includes('broadcast')
    },
    showPhqRank() {
      return this.event.rank.value !== null || !this.hasAccessToPhqRank
    },
    showLocalRank() {
      return this.event.localRank.value !== null || !this.hasAccessToLocalRank
    },
    phqRank() {
      return this.event.rank?.value
    },
    localRank() {
      return this.event.localRank?.value
    },
    showPhqAttendanceOnEvent() {
      return R.has('phqAttendance', this.event) && this.event.phqAttendance !== null && this.hasAccessToPhqAttendance
    },
    showPhqViewershipOnEvent() {
      return (
        R.has('phqViewership', this.event) &&
        this.event.phqViewership !== null &&
        this.hasAccessToPhqViewership &&
        this.variant === 'broadcast'
      )
    },
    phqAttendance() {
      return this.formatNumber(this.event.phqAttendance)
    },
    phqViewership() {
      return this.formatNumber(this.event.phqViewership)
    },
    isInterestingDuration() {
      return this.event.duration.days > 1 || (this.event.duration.days === 1 && this.event.tz === null)
    },
    isInterestingPredictedDuration() {
      return (
        this.event.predictedDuration.days > 1 || (this.event.predictedDuration.days === 1 && this.event.tz === null)
      )
    },
    tag() {
      const tag = this.event.deletedReason || this.event.state
      if (tag in eventTags) return eventTags[tag]
      return null
    },
    deletedReason() {
      const label = this.event?.deletedReason
      if (!label) return
      if (label === 'cancelled') return 'Canceled'
      return upperFirst(label)
    },
    broadcastPlace() {
      return R.has('broadcastPlaces', this.event) && this.event.broadcastPlaces.length > 0
        ? this.event.broadcastPlaces[0]
        : {}
    },
    broadcastPlaceName() {
      return R.has('broadcastPlaces', this.event) && this.event.broadcastPlaces.length > 0
        ? this.event.broadcastPlaces[0].name
        : ''
    },
    broadcastPlaceType() {
      return R.has('broadcastPlaces', this.event) && this.event.broadcastPlaces.length > 0
        ? this.event.broadcastPlaces[0].type
        : ''
    },
    broadcastTimeZone() {
      if (
        R.has('broadcastTimezone', this.event) &&
        this.event?.broadcastTimezone !== '' &&
        this.event.broadcastTimezone
      ) {
        return moment.tz(this.event?.broadcastTimezone).format('ZZ')
      }
      return ''
    },

    // Expanded section

    startDate() {
      return this.event.startLocal ? moment(this.event.startLocal).format('ddd, DD MMM YYYY') : null
    },
    startTime() {
      return this.event.startLocal && this.event?.tz ? moment(this.event.startLocal).format('h:mm A') : null
    },
    endDate() {
      return this.event.endLocal ? moment(this.event.endLocal).format('ddd, DD MMM YYYY') : null
    },
    endTime() {
      if (!this.event.tz) return null
      if (this.event.predictedEndLocal) return moment(this.event.predictedEndLocal).format('h:mm A')
      if (this.event.endLocal) return moment(this.event.endLocal).format('h:mm A')
      return null
    },
    hasAccessToLoop() {
      return this.subscribedAddons.includes('loop')
    },
    loopUrl() {
      // TODO: Replace the loop url to be loaded from config
      return `https://loop.predicthq.com/feedback/submit?event_id=${this.event.id}`
    },
    venue() {
      // We only show venue field when entities type is venue or Airport
      if (R.has('entities', this.event) && !R.isEmpty(this.event.entities)) {
        const validVenues = this.event.entities.filter((e) => {
          return ['venue', 'airport'].includes(e.type.toLowerCase())
        })
        if (validVenues.length > 0) {
          const location = validVenues[0]
          return {
            label: location.type,
            name: location.name,
            address: location.singleLineAddress,
          }
        } else {
          return false
        }
      }
      return false
    },
    duration() {
      if (R.has('duration', this.event) && this.showDuration) {
        const days = this.event?.duration?.days
        return days === 1 ? '1 day' : days + ' days'
      }
      if (R.has('predictedDuration', this.event) && this.showPredictedDuration) {
        const days = this.event?.predictedDuration?.days
        return days === 1 ? '1 day' : days + ' days'
      }
      return 0
    },
    showDuration() {
      return this.event?.duration?.days > 1 || (this.event?.duration?.days === 1 && this.event?.tz === null)
    },
    showPredictedDuration() {
      return (
        this.event?.predictedDuration?.days > 1 ||
        (this.event?.predictedDuration?.days === 1 && this.event?.tz === null)
      )
    },
    timeZone() {
      if (R.has('tz', this.event) && this.event?.tz !== '' && this.event.tz) {
        return `${moment.tz(this.event?.tz).format('ZZ')} (${this.event?.tz})`
      }
      return ''
    },
    shortDescription() {
      if ((R.has('description', this.event), this.event?.description.length > 400)) {
        return this.event?.description.slice(0, 400) + '...'
      } else {
        return this.event?.description
      }
    },
    hasLongerDescription() {
      return this.event?.description.length > 400
    },
    description() {
      if ((R.has('description', this.event), this.event?.description !== '')) {
        return this.showFullDescription ? this.event?.description : this.shortDescription
      } else {
        return ''
      }
    },
  },
  methods: {
    formatNumber(value) {
      if (!value && value !== 0) return ''
      return numeral(value).format('0,0')
    },
    upgrade(value, trigger) {
      if (trigger) {
        this.$emit('upgrade', {
          product: 'events',
          item: 'addons',
          value: value,
        })
      }
    },
    openLoop() {
      window.open(this.loopUrl, '_blank')
    },
  },
}
</script>

<style lang="scss" module src="./event-card.scss" />
