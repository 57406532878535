<template>
  <component :is="component" :class="bem('', [align, color, { 'no-inline': preventInline }])">
    <Loader v-if="loading" :color="loaderColor" :class="bem('loader')" />
    <Typography v-else variant="2xl-light" component="span" :class="bem('value')">
      <template v-if="prefix">{{ prefix }}</template
      >{{ formattedValue }}
    </Typography>
    <div :class="bem('label')">
      <Typography variant="p-sm">{{ label }}</Typography>
      <Icon
        v-if="tooltip"
        inline
        icon="nav/notes"
        color="current"
        :size="18"
        v-tooltip="tooltip"
        :class="bem('tooltip-icon')"
      />
    </div>
  </component>
</template>
<script>
import Icon from '@predicthq/vue3.components.icon'
import Loader from '@predicthq/vue3.components.loader'
import Typography from '@predicthq/vue3.components.typography'

export default {
  name: 'AppStat',
  components: {
    Icon,
    Loader,
    Typography,
  },
  props: {
    align: {
      type: String,
      default: 'center',
      validator: (v) => ['left', 'center', 'right'].includes(v),
    },
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'purple',
      validator: (v) => ['purple', 'pink', 'dark'].includes(v),
    },
    prefix: String,
    loading: Boolean,
    value: [Number, String],
    tooltip: String,
    // Prevents UI from changing to inline on smaller screens
    preventInline: Boolean,
  },
  computed: {
    formattedValue() {
      if (!['number', 'string'].includes(typeof this.value)) return '-'
      return this.value.toLocaleString()
    },
    component() {
      // If it has a key it's part of a list
      return typeof this.$.vnode.key === 'undefined' ? 'div' : 'li'
    },
    loaderColor() {
      return {
        pink: 'primary',
        purple: 'secondary',
      }[this.color]
    },
  },
}
</script>

<style lang="scss" src="./app-stat.scss" scoped />
