import config from '@/app/config'

export const customers = {
  Accor: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-accorhotels.svg'),
      text: 'Demand Forecasting: Incorporating events into models for smarter forecasting.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  Accenture: {
    customerCard: {
      logoImage: {
        alt: 'Accenture',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-accenture.png'),
          '2x': require('@/assets/img/customers/logos/logo-accenture@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-accenture-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-accenture-sm@2x.png'),
        },
      },
      text: 'PredictHQ is an Accenture Data Services Partner.',
      linkTitle: 'Customer stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  AlaskaAirlines: {
    appStory: {
      quote: `Every moment matters in demand forecasting. Imagine finding out a couple of hours too late that say Coachella is scheduled for a different weekend in April than years past. That delay cost millions of dollars in a missed opportunity to update pricing and potentially more supply to reflect demand. With Notifications, we won't have to worry about this ever again for any event that impacts our business.`,
      companyLogo: {
        '1x': require('@/assets/img/customers/logos/logo-alaska.png'),
        '2x': require('@/assets/img/customers/logos/logo-alaska@2x.png'),
      },
      companyLogoAlt: 'Alaska Airlines',
    },
    customerCard: {
      logoImage: {
        alt: 'Alaska Airlines',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-alaska.png'),
          '2x': require('@/assets/img/customers/logos/logo-alaska@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-alaska-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-alaska-sm@2x.png'),
        },
      },
      text: 'Yield optimization: Tracking incoming demand ahead of the booking curve.',
      linkTitle: 'Customer stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  BlueYonder: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-blueyonder.svg'),
      text: 'Demand Forecasting: Incorporating events into models for smarter forecasting.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  'Booking.com': {
    customerCard: {
      logoImage: {
        alt: 'Booking.com',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-bookingcom.png'),
          '2x': require('@/assets/img/customers/logos/logo-bookingcom@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-bookingcom-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-bookingcom-sm@2x.png'),
        },
      },
      text: 'Dynamic pricing: Setting prices and packaging based on smarter forecasts.',
      linkTitle: 'Customer stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  Dominos: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-dominos.svg'),
      text: 'Labor optimization: Ensuring the right number of drivers and store staff to meet demand.',
      linkTitle: 'Customer stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  FirstData: {
    customerCard: {
      logoImage: {
        alt: 'First Data',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-firstdata.png'),
          '2x': require('@/assets/img/customers/logos/logo-firstdata@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-firstdata-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-firstdata-sm@2x.png'),
        },
      },
      text: 'Merchant Services: Adding relevant demand causal factor insights to customer communications.',
      linkTitle: 'Customer stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  Uber: {
    customerCard: {
      logoImage: {
        alt: 'Uber',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-uber.png'),
          '2x': require('@/assets/img/customers/logos/logo-uber@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-uber-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-uber-sm@2x.png'),
        },
      },
      text: 'Demand forecasting: Getting drivers in the right place ahead of time to improve pick-up times.',
      linkTitle: 'Customer stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  CNBC: {
    customerCard: {
      logoImage: {
        alt: 'CNBC',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-cnbc.png'),
          '2x': require('@/assets/img/customers/logos/logo-cnbc@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-cnbc-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-cnbc-sm@2x.png'),
        },
      },
      text: 'PredictHQ provides insight to cost of event cancellations related to COVID-19.',
      linkTitle: 'Read more',
      linkUrl: 'https://www.cnbc.com/2020/03/18/the-economic-impact-of-coronavirus-event-cancellations.html',
    },
  },
  Recode: {
    customerCard: {
      logoImage: {
        alt: 'Recode',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-recode.png'),
          '2x': require('@/assets/img/customers/logos/logo-recode@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-recode-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-recode-sm@2x.png'),
        },
      },
      text:
        'Cancellations of major tech events from COVID-19 soars beyond $1 billion. PredictHQ provides insight into the economic impact.',
      linkTitle: 'Read more',
      linkUrl: 'https://www.vox.com/recode/2020/3/3/21162802/tech-conferences-cancellation-coronavirus',
    },
  },
  VentureBeat: {
    customerCard: {
      logoImage: {
        alt: 'Venture Beat',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-venturebeat.png'),
          '2x': require('@/assets/img/customers/logos/logo-venturebeat@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-venturebeat-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-venturebeat-sm@2x.png'),
        },
      },
      text: 'PredictHQ raises $22 million to help Uber and others predict demand with big data.',
      linkTitle: 'Read more',
      linkUrl:
        'https://venturebeat.com/2020/02/12/predicthq-raises-22-million-to-help-uber-and-others-predict-demand-with-big-data/',
    },
  },
  Reuters: {
    customerCard: {
      logoImage: {
        alt: 'Reuters',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-reuters.png'),
          '2x': require('@/assets/img/customers/logos/logo-reuters@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-reuters-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-reuters-sm@2x.png'),
        },
      },
      text:
        'PredictHQ launches Aviation Rank which alerts airlines to major events and helps carriers rebook customers to maximize revenue.',
      linkTitle: 'Read more',
      linkUrl:
        'https://www.reuters.com/article/us-tech-airlines/tech-startups-tout-ai-to-airlines-seeking-revenue-boost-idUSKCN1VR02L',
    },
  },
  NZH: {
    customerCard: {
      logoImage: {
        alt: 'NZH',
        normal: {
          '1x': require('@/assets/img/customers/logos/logo-nzh.png'),
          '2x': require('@/assets/img/customers/logos/logo-nzh@2x.png'),
        },
        small: {
          '1x': require('@/assets/img/customers/logos/logo-nzh-sm.png'),
          '2x': require('@/assets/img/customers/logos/logo-nzh-sm@2x.png'),
        },
      },
      text: 'PredictHQ raises $34 million from Silicon Valley.',
      linkTitle: 'Read more',
      linkUrl: 'https://www.nzherald.co.nz/business/news/article.cfm?c_id=3&objectid=12307974',
    },
  },
  HqRevenue: {
    appStory: {
      quote:
        'Adding PredictHQ into data sources has improved speed of global expansion and helped achieve an average increase of 10% revenue per available room (RevPAR) for customers.',
      companyLogo: {
        '1x': require('@/assets/img/customers/logos/logo-hqplus.png'),
        '2x': require('@/assets/img/customers/logos/logo-hqplus@2x.png'),
      },
      companyLogoAlt: 'HQ revenue',
      linkTitle: 'Read HQ revenue Story',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers/hqrevenue`,
    },
  },
  Legion: {
    appStory: {
      companyLogo: require('@/assets/img/customers/logos/legion-logo.svg'),
      companyLogoWidth: `158px`,
      companyLogoAlt: 'Legion',
      linkTitle: 'Read Legion’s Story',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers/legion`,
      quote:
        "Don't underestimate how much effort it takes to work with event data... Being able to rely on a company whose sole purpose is to remove the ambiguity of event data has been game-changing for us.",
    },
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/legion-logo.svg'),
      text:
        'Labor optimization: Enriching the Legion Technologies platform, used by retailers to reduce labor inefficiencies.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  AmazonAlexa: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-amazon-alexa.svg'),
      logoImageAlt: 'Amazon Alexa',
      logoImageWidth: `140px`,
      text: `Amazon Alexa's "Events Near Me" feature uses PredictHQ data to inform users about local events.`,
      linkTitle: 'Blog',
      linkUrl: `${config.PHQ_WEBSITE_URL}/blog/predicthq-amazon-alexa`,
    },
  },
  WalGreens: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-walgreens.svg'),
      text:
        'Demand forecasting: Incorporating events into their Antuit-built models to better understand demand across 9600 stores.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  GoPuff: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-gopuff.svg'),
      text:
        'Demand forecasting: Smarter inventory and driver-partner management by knowing of impactful events in advance.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  Zartico: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-zartico.svg'),
      text: 'Event visibility: Available in Zartico’s platform enabling deeper insight and smarter decisions.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  OTAInsight: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-OTAInsight.svg'),
      text: 'Pricing: A key source of intelligence for the OTA Insights platform, enabling smarter pricing.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
  SpotHero: {
    customerCard: {
      logoImage: require('@/assets/img/customers/logos/logo-spothero.svg'),
      text:
        'PredictHQ has supplemented incomplete data sets, including unticketed events and cancelled events, to power pricing strategies and operational decisions.',
      linkTitle: 'Customer Stories',
      linkUrl: `${config.PHQ_WEBSITE_URL}/customers`,
    },
  },
}
