import service from '@/app/api/public/contentful'

export default {
  namespaced: true,
  state: {
    adSpace: null,
  },
  mutations: {
    setAdSpace(state, adSpace) {
      state.adSpace = adSpace
    },
  },
  actions: {
    async fetchContent({ commit, dispatch }) {
      const entry = await service.getEntry('5gVDd80yqbkwAKp77RCtI0')
      const content = entry.data.fields

      if (content) {
        content.image.file = await dispatch('fetchAsset', content.image.sys.id)
      }

      commit('setAdSpace', content)
      return content
    },

    async fetchAsset(_, id) {
      const asset = await service.getAsset(id)
      return asset?.data?.fields?.file
    },
  },
}
