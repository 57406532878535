<template>
  <ul :class="[bem(''), `text-${align}`]">
    <slot />
  </ul>
</template>
<script>
export default {
  name: 'AppStats',
  props: {
    align: {
      type: String,
      default: 'center',
      validator: (val) => ['left', 'center', 'right'].includes(val),
    },
  },
}
</script>

<style lang="scss" src="./app-stats.scss" scoped />
