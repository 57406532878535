import { createApp } from 'vue'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { defineRule, configure } from 'vee-validate'
import { Scroll, BgChange, TooltipDirective } from './utils/directives'
import * as Sentry from '@sentry/vue'
import AllRules from '@vee-validate/rules'
import App from './app'
import bemMixin from '@predicthq/vue3.utils.mixin-bem'
import config from '@/app/config'
import LangPlugin from './plugins/lang'
import mitt from 'mitt'
import PortalVue from 'portal-vue'
import ProgressBarPlugin from './plugins/progress-bar'
import router from './router'
import store from './store'
import SystemMessagesPlugin from './plugins/system-messages'
import uuidMixin from '@predicthq/vue3.utils.mixin-uuid'

// Gloabl main scss file
// Is used to style base things like tags - body, html, root and etc.
// Also includes reset styles
import '@phq/scss/main.scss'
import '@predicthq/vue3.assets.fonts'

// Set all vee-validate basic rules
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule])
})
// Config vee-validate
configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false,
  generateMessage: ({ field, rule }) => {
    return {
      email: `${field} must be a valid email address`,
      required: `${field} is required`,
    }[rule.name]
  },
})

const app = createApp(App)

const metaManager = createMetaManager()

const emitter = mitt() // Event bus, since Vue 3 doesn't have one

app.config.globalProperties.emitter = emitter

if (config.SENTRY_ENABLED) {
  Sentry.init({
    app,
    dsn: config.SENTRY_DSN,
    release: config.APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  })
}

app.use(PortalVue)
app.use(metaManager)
app.use(metaPlugin)
app.use(router)
app.use(store)

app.use(LangPlugin)
app.use(ProgressBarPlugin)
app.use(SystemMessagesPlugin)

app.mixin(uuidMixin)
app.mixin(bemMixin)

app.directive('tooltip', TooltipDirective)
app.directive('scroll', Scroll)
app.directive('bg-change', BgChange)

app.mount('#app')
