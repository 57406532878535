<template>
  <div :class="[bem(''), 'sides']">
    <nav :class="[bem('wrap'), 'wrap']">
      <ol :class="bem('list')">
        <li :class="bem('item')" v-for="(breadcrumb, idx) in breadcrumbs" :key="idx">
          <Icon v-if="idx === 0 && breadcrumb.icon" :class="bem('icon')" :icon="breadcrumb.icon" />
          <a v-if="breadcrumb.url" :href="breadcrumb.url" :class="bem('link')">
            {{ breadcrumb.title }}
          </a>
          <template v-else>{{ breadcrumb.title }}</template>
        </li>
      </ol>
    </nav>
  </div>
</template>
<script>
import Icon from '@predicthq/vue3.components.icon'

export default {
  name: 'AppBreadcrumb',
  components: {
    Icon,
  },
  props: {
    breadcrumbs: Array,
  },
}
</script>
<style lang="scss" scoped src="./app-breadcrumb.scss" />
