<template>
  <nav>
    <ul :class="bem('menu')">
      <li v-for="(item, idx) in menu" :key="idx" :class="bem('nav')">
        <Typography :class="bem('label')" variant="label">{{ item.title }}</Typography>
        <ul :class="bem('list')">
          <li :class="bem('item')" v-for="(page, idx) in item.menu" :key="idx">
            <a v-if="page" :class="bem('link')" :href="page.url">
              {{ page.title }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import Typography from '@predicthq/vue3.components.typography'
import { computed } from 'vue'
import { Pages } from '@/data/pages'

const {
  Industries,
  Customers,
  OurDataPipeline,
  DataQuality,
  DataEnrichment,
  Prediction,
  Rankings,
  EventCategories,
  Correlation,
  APIs,
  Beam,
  Notifications,
  Pricing,
  AggregateEventImpact,
  ControlCenter,
  Search,
  Tools,
  OurTeam,
  OurStory,
  Careers,
  Press,
  Blog,
  Partners,
  ContactUs,
  Documentation,
  Support,
  DevelopersQuickstart,
  DataScienceQuickstart,
  SystemStatus,
  LiveTvEvents,
  /** Temp disabled: This should be dynamically pulled from Contentful */
  // DemandForecasting,
  // Visualizations,
  // EventVisibility,
  // DynamicPricing,
  // WorkforceOptimization,
  // InventoryManagement,
} = Pages

export default {
  name: 'FooterNav',
  components: {
    Typography,
  },
  setup() {
    const menu = computed(() => {
      return [
        {
          title: 'Use Cases',
          menu: [
            /** Temp disabled: This should be dynamically pulled from Contentful */
            // DemandForecasting,
            // Visualizations,
            // EventVisibility,
            // DynamicPricing,
            // WorkforceOptimization,
            // InventoryManagement,
            Industries,
            Customers,
          ],
        },
        {
          title: 'Intelligence',
          menu: [OurDataPipeline, DataQuality, DataEnrichment, EventCategories, Prediction, Correlation],
        },
        {
          title: 'Products',
          menu: [APIs, Beam, Notifications, Pricing, ControlCenter, Search, Tools],
        },
        {
          title: 'Features',
          menu: [LiveTvEvents, AggregateEventImpact, Rankings],
        },
        {
          title: 'About',
          menu: [OurTeam, OurStory, Careers, Press, Partners, Blog, ContactUs],
        },
        {
          title: 'Docs',
          menu: [Documentation, DevelopersQuickstart, DataScienceQuickstart, Support, SystemStatus],
        },
      ]
    })

    return {
      menu,
    }
  },
}
</script>

<style lang="scss" scoped src="./footer-nav.scss" />
