<template>
  <table :class="bem('', { stickyHeader })">
    <slot />
  </table>
</template>

<script>
export default {
  name: 'Table',
  provide() {
    return { stickyHeader: this.stickyHeader }
  },
  inject: {
    head: {
      default: false,
    },
  },
  props: {
    stickyHeader: {
      /*
       * If true, the table will have fixed column headers
       */
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" module src="./table.scss" />
