<template>
  <footer :class="[bem(''), 'sides']">
    <div class="wrap">
      <div :class="bem('cta')" v-if="hasFooterCta">
        <div :class="bem('cta-inner')" class="row">
          <div :class="bem('cta-content')" class="col-m-12 col-t-8 col-l-6">
            <h2 :class="bem('cta-title')">Ready to get started?</h2>
            <h3 class="text-sub-heading">Get in touch or create an account.</h3>
          </div>
          <div :class="bem('cta-actions')" class="col-m-12 col-t-4 col-l-5">
            <AppButton :class="bem('cta-btn')" url="https://signup.predicthq.com/" :inline="false">Sign Up</AppButton>
            <AppButton :class="bem('cta-btn')" url="/contact/sales" modifier="secondary" :inline="false">
              Talk to an Expert
            </AppButton>
          </div>
        </div>
      </div>
    </div>
    <div :class="[bem('inner'), 'wrap']">
      <FooterNav :class="bem('nav')" />

      <a :href="config.PHQ_WEBSITE_URL" :class="bem('logo')">
        <img src="/images/logo.svg" />
      </a>
      <ul :class="bem('list')">
        <li :class="bem('list-item')">
          <p>&copy;&nbsp;{{ getCurrentYear }}&nbsp;PredictHQ Ltd</p>
        </li>
        <li :class="bem('list-item')">
          <a :href="`${config.PHQ_WEBSITE_URL}/legal/terms`" :class="bem('quick-link')">Terms of Service</a>
        </li>
        <li :class="bem('list-item')">
          <a :href="`${config.PHQ_WEBSITE_URL}/legal/privacy`" :class="bem('quick-link')">Privacy</a>
        </li>
      </ul>
      <ul :class="bem('social-links')">
        <li :class="bem('social-link')">
          <a :href="config.PHQ_INSTAGRAM_URL" target="_blank">
            <Icon icon="social/instagram" />
          </a>
        </li>
        <li :class="bem('social-link')">
          <a :href="config.PHQ_TWITTER_URL" target="_blank">
            <Icon icon="social/twitter" />
          </a>
        </li>
        <li :class="bem('social-link')">
          <a :href="config.PHQ_LINKEDIN_URL" target="_blank">
            <Icon icon="social/linkedin" />
          </a>
        </li>
        <li :class="bem('social-link')">
          <a :href="config.PHQ_GITHUB_URL" target="_blank">
            <Icon icon="social/github" />
          </a>
        </li>
        <li :class="bem('social-link')">
          <a :href="`${config.PHQ_WEBSITE_URL}/contact`">
            <Icon icon="social/contact" />
          </a>
        </li>
      </ul>
    </div>
    <!-- end .inner -->
  </footer>
</template>
<script>
import AppButton from '@predicthq/vue3.components.app-button'
import config from '@/app/config'
import FooterNav from './footer-nav'
import Icon from '@predicthq/vue3.components.icon'

export default {
  name: 'AppFooter',
  components: {
    FooterNav,
    AppButton,
    Icon,
  },
  props: ['hasFooterCta'],
  computed: {
    getCurrentYear() {
      return new Date().getFullYear()
    },
  },
  data() {
    return {
      config,
    }
  },
}
</script>
<style lang="scss" scoped src="./app-footer.scss" />
