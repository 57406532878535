<template>
  <a :href="cta.url" :class="bem('', color)">
    <span :class="bem('bg')"></span>
    <span :class="bem('hero', { persist: persistImage })">
      <img :class="bem('hero-image', type === 'image' ? 'fill' : '')" :src="image" immediate />
    </span>
    <div :class="bem('content-wrap')">
      <span :class="bem('content')">
        <h2 :class="bem('title')" class="text-h3">
          <Icon v-if="icon" :class="bem('icon')" :icon="icon" />
          {{ title }}
        </h2>
        <span :class="bem('article')" class="text-p-sm">{{ desc }}</span>
        <AppLink :class="bem('button')">{{ cta.title }}</AppLink>
      </span>
    </div>
  </a>
</template>
<script>
import AppLink from '@predicthq/vue3.components.app-link'
import Icon from '@predicthq/vue3.components.icon'

export default {
  name: 'CaseTile',
  components: {
    AppLink,
    Icon,
  },
  props: {
    blob: Object,
    color: String,
    cta: Object,
    image: [String, Object],
    title: String,
    icon: String,
    desc: String,
    type: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ['illustration', 'image'].indexOf(value) !== -1
      },
      default: 'illustration',
    },
    persistImage: {
      // If enabled, image displays on tablet and under
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped src="./case-tile.scss" />
