import * as R from 'ramda'
import IntlMessageFormat from 'intl-messageformat'
import memoizeFormatConstructor from 'intl-format-cache'

const getMessage = memoizeFormatConstructor(IntlMessageFormat)

export default {
  strings: [],
  setStrings(strings) {
    this.strings = strings
  },
  format(string, data = {}) {
    const msg = getMessage(R.has(string, this.strings) ? this.strings[string] : string, 'en')

    return msg.format(data)
  },
}
