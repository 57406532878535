<template>
  <div :class="bem('')">
    <ShareModal
      v-if="shareEventId && showSocialShareModal"
      :open="showSocialShareModal"
      :title="shareEventTitle"
      @close-modal="showSocialShareModal = false"
    />
    <div :class="bem('title-bar')">
      <div :class="bem('title-wrapper')">
        <AppTypography :class="bem('title')" component="h1" variant="2xl">
          {{ event.title }}
        </AppTypography>
        <AppChip v-if="tag" v-tooltip="tag.tooltip" :class="bem('title-tag')" :variant="tag.variant">
          {{ tag.label }}
        </AppChip>
      </div>
      <div v-if="sharable" :class="bem('title-actions')">
        <AppLink variant="secondary" @click="showSocialShareModal = true">
          <template #startIcon>
            <AppIcon icon="common/share" />
          </template>
          Share
        </AppLink>
      </div>
    </div>

    <AppTypography v-if="!loading" :class="bem('sub-title')" variant="p">
      {{ address }}
      <AppChip v-if="isPolygonEvent" variant="pastel-blue">
        Polygon
      </AppChip>
    </AppTypography>

    <ContentLoader
      v-else
      :animate="true"
      :style="{ width: 188, height: 24 }"
      class="wrap"
      height="24"
      primaryColor="#f0f1f2"
      secondaryColor="#f0f1f2"
      width="188"
    >
      <rect x="0" y="8" rx="0" ry="0" width="188" height="16" />
    </ContentLoader>

    <div :class="bem('addons-container')">
      <div :class="bem('addons-left')">
        <CategoryLabel v-bind="{ category }" :class="bem('category-icon')" />

        <div :class="bem('tags')">
          <AppChip v-for="label in labels" :key="label" :class="bem('tag')" type="tag">
            {{ label }}
          </AppChip>
        </div>
      </div>

      <div :class="bem('addons-right')">
        <AddonText v-if="showPhqAttendanceOnEvent" :class="bem('viewership')" label="Predicted Attendance">
          {{ phqAttendance }}
        </AddonText>

        <RankGroup :class="bem('rank-group')">
          <AppRank
            v-if="showPhqRank"
            v-tooltip.overflow="tooltips.rank"
            :locked="!hasAccessToPhqRank"
            :progress="phqRank"
            type="rank"
            @click="upgrade('phq-rank')"
          />
          <AppRank
            v-if="showLocalRank"
            v-tooltip.overflow="tooltips.localRank"
            :locked="!hasAccessToLocalRank"
            :progress="localRank"
            type="local"
            @click="upgrade('local-rank')"
          />
        </RankGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ContentLoader } from 'vue-content-loader'
import { computed, ref } from 'vue'
import * as R from 'ramda'
import AddonText from '@phq/addon-text'
import AppChip from '@predicthq/vue3.components.app-chip'
import AppIcon from '@predicthq/vue3.components.icon'
import AppLink from '@predicthq/vue3.components.app-link'
import AppRank from '@predicthq/vue3.components.app-rank'
import AppTypography from '@predicthq/vue3.components.typography'
import CategoryLabel from '@phq/category-label'
import eventTags from '@/data/event-tags.json'
import RankGroup from '@phq/rank-group'
import ShareModal from '@phq/share-modal'
import tooltips from '@/data/tooltips.json'

defineOptions({
  name: 'EventHeader',
})

const emit = defineEmits(['upgrade'])

const props = defineProps({
  event: {
    type: Object,
    required: true,
    default() {
      return {}
    },
  },
  isPolygonEvent: {
    type: Boolean,
    default: false,
  },
  access: {
    type: Array,
    required: false,
    default() {
      return []
    },
  },
  sharable: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const loading = ref(false)
const showSocialShareModal = ref(false)

const shareEventId = computed(() => props.event?.id)

const shareEventTitle = computed(() => props.event?.title)

const address = computed(() => {
  const { singleLineAddress: a, secondarySingleLineAddress: b } = props.event

  return a || b
})
const labels = computed(() => {
  return props.event?.labels || []
})
const category = computed(() => {
  return props.event?.category || ''
})
const hasAccessToPhqRank = computed(() => {
  return props.access.includes('phq')
})
const hasAccessToLocalRank = computed(() => {
  return props.access.includes('local')
})
const hasAccessToPhqAttendance = computed(() => {
  return props.access.includes('phq-attendance')
})
const showPhqRank = computed(() => {
  return props.event.rank.value !== null || !hasAccessToPhqRank.value
})
const showLocalRank = computed(() => {
  return props.event.localRank.value !== null || !hasAccessToLocalRank.value
})
const showPhqAttendanceOnEvent = computed(() => {
  return R.has('phqAttendance', props.event) && props.event.phqAttendance !== null && hasAccessToPhqAttendance.value
})
const phqRank = computed(() => {
  return props.event?.rank?.value
})
const localRank = computed(() => {
  return props.event?.localRank?.value
})
const phqAttendance = computed(() => {
  return props.event?.phqAttendance?.toLocaleString() || 0
})
const tag = computed(() => {
  const tag = props.event.deletedReason || props.event.state
  if (tag in eventTags) return eventTags[tag]
  return null
})

function upgrade(value) {
  const map = {
    'phq-rank': !hasAccessToPhqRank.value,
    'local-rank': !hasAccessToLocalRank.value,
  }

  // If doesn't have access to the rank, then show the modal
  if (map[value]) {
    emit('upgrade', {
      product: 'events',
      item: 'addons',
      value,
    })
  }
}
</script>

<style lang="scss" module src="./event-header.scss" />
