import lang from '@/app/lang'
import en from '@/app/lang/en'

// usage in template: {{ $lang('string.key.name', { foo: 'bar' }) }}
export default {
  install(app) {
    // todo - if we want to support multiple languages just need to change how
    // we load the strings
    lang.setStrings(en)

    app.config.globalProperties.$lang = (string, data = {}) => {
      return lang.format(string, data)
    }
  },
}
