<template>
  <section :class="bem('', [align, columns])">
    <div class="wrap">
      <!-- row -->
      <div :class="bem('row')">
        <div v-for="(item, idx) in tiles" :class="[bem('tile')]" :key="idx">
          <CaseTile v-bind="item" :persistImage="persistImages" />
        </div>
      </div>
      <div :class="bem('footer')" class="v-space-mt-5">
        <AppButton v-if="linkText" :class="bem('button')" :to="linkUrl" variant="secondary">{{ linkText }}</AppButton>
      </div>
    </div>
    <!-- wrap -->
  </section>
</template>
<script>
import AppButton from '@predicthq/vue3.components.app-button'
import CaseTile from '../case-tile'

export default {
  name: 'CaseTiles',
  components: {
    AppButton,
    CaseTile,
  },
  props: {
    align: {
      type: String,
      default: 'left',
      validator: (val) => ['left', 'center', 'right'].includes(val),
    },
    persistImages: {
      type: Boolean,
      default: false,
    },
    tiles: {
      type: Array,
      default() {
        return []
      },
    },
    columns: {
      type: String,
      default: '3',
    },
    linkUrl: String,
    linkText: String,
  },
}
</script>

<style lang="scss" scoped src="./case-tiles.scss" />
