<template>
  <div :class="bem('')">
    <div ref="calendar"></div>
  </div>
</template>

<script>
import pickmeup from 'pickmeup'
import moment from 'moment-timezone'

export default {
  name: 'Calendar',
  data() {
    return {
      prepared: false,
    }
  },
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    tz: {
      type: String,
      required: false,
      default: '',
    },
    disabled: Boolean,
    default() {
      return false
    },
  },
  watch: {
    from: 'prepare',
    to: {
      handler() {
        this.prepare()
      },
      immediate: true,
    },
    tz: 'prepare',
  },
  methods: {
    prepare() {
      let from = null
      let to = null
      let current = null

      if (this.from.length > 0 && this.to.length > 0) {
        if (this.tz !== null && this.tz.length > 0) {
          // Event has a time zone, convert to event time zone
          from = moment.utc(this.from).tz(this.tz).format('YYYY-MM-DD')
          to = moment.utc(this.to).tz(this.tz).format('YYYY-MM-DD')
          current = moment.utc(this.from).tz(this.tz).format('DD-MM-YYYY') // format required for pickmeup
        } else {
          // Event is time zone agnostic
          from = moment.utc(this.from).format('YYYY-MM-DD')
          to = moment.utc(this.to).format('YYYY-MM-DD')
          current = moment.utc(this.from).format('DD-MM-YYYY') // format required for pickmeup
        }
      }

      const options = {
        flat: true,
        calendars: 1,
        mode: 'range',
        select_month: false,
        select_year: false,
        first_day: 0, // Week starts on Sunday
        prev: '',
        next: '',
        current,
        render: (date) => {
          const dateString = moment(date).format('YYYY-MM-DD')
          let selected = false
          let className = ''

          if (moment(dateString).isBetween(from, to, null, '[]')) {
            selected = true
            className = 'pmu-selected'
          }

          return {
            selected,
            class_name: className,
            disabled: this.disabled,
          }
        },
      }

      if (this.prepared) {
        pickmeup(this.$refs.calendar).destroy()
        this.prepared = false
      }

      pickmeup(this.$refs.calendar, options)
      this.prepared = true
    },
  },
  mounted() {
    this.prepare()
  },
}
</script>
<style scoped lang="scss" src="./calendar.scss" />
