<template>
  <div :class="bem('')">
    <div :class="bem('content')">
      <slot />
    </div>
    <span v-if="$slots.action" :class="bem('action')"><slot name="action" /></span>
  </div>
</template>

<script>
export default {
  name: 'InlineHeader',
}
</script>

<style lang="scss" scoped src="./inline-header.scss" />
