<template>
  <div v-if="!hasCompletedSurvey">
    <AlertPanel icon="common/callout" v-if="!hasAnswered">
      Do you find this information useful?
      <template #action>
        <div :class="bem('alert-actions')">
          <AppLink @click="answer('yes')" variant="secondary">Yes</AppLink>
          <AppLink @click="answer('no')" variant="secondary">No</AppLink>
        </div>
      </template>
    </AlertPanel>
    <ModalCard :open="open" @close="open = false">
      <template #header>
        <Typography variant="lg">Can you give us more details?</Typography>
      </template>
      <slot />
      <VForm @submit="submit" v-slot="{ errors }">
        <StatusMessage
          v-if="(error = backendError || firstError(errors))"
          class="v-space-mt-3 v-space-mb-3"
          variant="error"
        >
          {{ error }}
        </StatusMessage>

        <FieldGroup>
          <VField name="email" label="Email" v-model="form.email" rules="required|email" v-slot="{ field }">
            <AppInput
              v-bind="field"
              label="Email"
              type="email"
              :error="'email' in errors"
              @input="backendError = null"
            />
          </VField>
        </FieldGroup>
        <FieldGroup>
          <AppTextarea
            label="More details"
            placeholder="How can we improve your experience"
            v-model="form.details"
          ></AppTextarea>
        </FieldGroup>
        <FieldGroup>
          <AppCheckbox label="Send me updates on this feature" v-model="form.sendUpdates"></AppCheckbox>
        </FieldGroup>
        <AppButton type="submit" :disabled="isLoading" :loading="isLoading" block>Done</AppButton>
      </VForm>
    </ModalCard>
  </div>
</template>

<script>
import { FieldGroup } from '@phq/form'
import { ref, reactive } from 'vue'
import { Field, Form } from 'vee-validate'
import AlertPanel from '@predicthq/vue3.components.alert-panel'
import AppButton from '@predicthq/vue3.components.app-button'
import AppCheckbox from '@predicthq/vue3.components.app-checkbox'
import AppInput from '@predicthq/vue3.components.app-input'
import AppLink from '@predicthq/vue3.components.app-link'
import AppTextarea from '@predicthq/vue3.components.app-textarea'
import axios from 'axios'
import config from '@/app/config'
import getPardotCookies from '@/utils/getPardotCookies'
import ModalCard from '@predicthq/vue3.components.modal-card'
import StatusMessage from '@predicthq/vue3.components.status-message'
import storage from '@/utils/storage'
import Typography from '@predicthq/vue3.components.typography'
import useFormErrors from '@/use/form-errors'

export default {
  name: 'AppSurvey',
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    fieldsMap: {
      type: Object,
      required: true,
    },
    description: String,
  },
  components: {
    AlertPanel,
    AppButton,
    AppCheckbox,
    AppInput,
    AppLink,
    AppTextarea,
    VForm: Form,
    VField: Field,
    FieldGroup,
    ModalCard,
    StatusMessage,
    Typography,
  },
  setup(_) {
    const isLoading = ref(false)
    const open = ref(false)
    const completedSurveys = storage.get('completedSurveys', [])
    const hasAnswered = ref(false)
    const form = reactive({
      answer: '',
      details: '',
      email: '',
      sendUpdates: true,
    })

    const hasCompletedSurvey = completedSurveys.includes(_.endpoint)

    const { hasError, firstError } = useFormErrors()

    const backendError = ref(null)

    async function answer(answer) {
      form.answer = answer
      open.value = true
    }

    async function submit() {
      await sendToPardot({
        [_.fieldsMap.answer]: form.answer,
        [_.fieldsMap.details]: form.details,
        [_.fieldsMap.email]: form.email,
        [_.fieldsMap.sendUpdates]: form.sendUpdates,
      })

      if (backendError.value) return

      open.value = false
      hasAnswered.value = true
      completedSurveys.push(_.endpoint)
      storage.set('completedSurveys', [...new Set(completedSurveys)])
    }

    async function sendToPardot(data) {
      try {
        isLoading.value = true
        const formData = {
          pardot_endpoint: _.endpoint,
          pardot_fields: data,
          pardot_cookies: getPardotCookies(),
        }

        await axios.post(`${config.API_ENDPOINT}/sales-forms/pardot-form`, formData, {
          headers: { 'Content-Type': 'application/json' },
        })
      } catch (e) {
        console.error(e)
        backendError.value = e?.response?.data?.error || e.message
      } finally {
        isLoading.value = false
      }
    }

    return {
      answer,
      isLoading,
      hasAnswered,
      hasCompletedSurvey,
      open,
      submit,
      form,
      hasError,
      firstError,
      backendError,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-survey {
  &__alert-actions {
    display: flex;
    gap: 24px;
  }
}
</style>
