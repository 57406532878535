import * as R from 'ramda'
import { getCountry } from '@/app/countries'
import { getRankDetails } from '@/app/events/helpers/rank'
import { getDurationAsDays, getDurationFromRange } from '@/app/events/helpers/dates'
import PublicApiEventsEventPlaceHierarchy from './event/place-hierarchy'
import PublicApiEventsEventEntity from './event/entity'

function PublicApiEventsBroadcastEvent(broadcastEvent) {
  const data = broadcastEvent?.event
  const event = {
    id: R.has('event_id', data) ? data.event_id : null,
    broadcastId: R.has('broadcast_id', broadcastEvent) ? broadcastEvent.broadcast_id : null,
    broadcastStatus: R.has('broadcast_status', broadcastEvent) ? broadcastEvent.broadcast_status : null,
    state: R.has('state', data)
      ? data.state
      : R.has('record_status', broadcastEvent)
      ? broadcastEvent.record_status
      : null,
    phqViewership: R.has('phq_viewership', broadcastEvent) ? broadcastEvent.phq_viewership : null,
    deletedReason: R.has('deleted_reason', data) ? data.deleted_reason : null,
    cancelled: R.has('cancelled', data) ? data.cancelled : null,
    postponed: R.has('postponed', data) ? data.postponed : null,
    title: R.has('title', data) ? data.title : '',
    description: R.has('description', data) ? data.description : '',
    category: data.category,
    labels: R.has('labels', data) ? [...data.labels].sort() : [],
    start: R.has('dates', broadcastEvent) && R.has('start', broadcastEvent.dates) ? broadcastEvent.dates.start : null,
    startLocal:
      R.has('dates', broadcastEvent) && R.has('start_local', broadcastEvent.dates)
        ? broadcastEvent.dates.start_local
        : null,
    broadcastTimezone:
      R.has('dates', broadcastEvent) && R.has('timezone', broadcastEvent.dates) ? broadcastEvent.dates.timezone : null,
    end: R.has('dates', broadcastEvent) && R.has('end', broadcastEvent.dates) ? broadcastEvent.dates.end : null,
    predictedEnd: R.has('predicted_end', data) ? data.predicted_end : null,
    updated: R.has('updated', broadcastEvent) ? broadcastEvent.updated : null,
    firstSeen: R.has('first_seen', data) ? data.first_seen : null,
    get predictedDuration() {
      let seconds = null
      if (this.predictedEnd) {
        seconds = getDurationFromRange(this.start, this.predictedEnd)
      }

      const days = seconds !== null ? getDurationAsDays(seconds) : null
      return {
        seconds,
        days,
      }
    },
    get duration() {
      const seconds = R.has('duration', data) ? data.duration : null
      const days = seconds !== null ? getDurationAsDays(seconds) : null

      return {
        seconds,
        days,
      }
    },
    tz: R.has('timezone', data) ? data.timezone : null,
    get location() {
      return {
        lat: this.lat,
        lng: this.lng,
        coords: [this.lng, this.lat],
      }
    },
    lat: R.has('location', data) && R.has('geopoint', data.location) ? data.location.geopoint.lat : 0.0,
    lng: R.has('location', data) && R.has('geopoint', data.location) ? data.location.geopoint.lon : 0.0,
    country: R.has('location', data) && R.has('country', data.location) ? data.location.country : null,
    get countryDetails() {
      return getCountry({ alpha2: this.country })
    },
    get placeHierarchies() {
      return R.has('location', data) && R.has('place_hierarchies', data.location)
        ? R.map((hierarchy) => {
            return new PublicApiEventsEventPlaceHierarchy(hierarchy)
          }, data.location.place_hierarchies)
        : []
    },
    get broadcastLocation() {
      return {
        lat: this.broadcastLat,
        lng: this.broadcastLng,
        coords: [this.broadcastLat, this.broadcastLng],
      }
    },
    broadcastLat:
      R.has('location', broadcastEvent) && R.has('geopoint', broadcastEvent.location)
        ? broadcastEvent.location.geopoint.lat
        : 0.0,
    broadcastLng:
      R.has('location', broadcastEvent) && R.has('geopoint', broadcastEvent.location)
        ? broadcastEvent.location.geopoint.lon
        : 0.0,
    broadcastCountry:
      R.has('location', broadcastEvent) && R.has('country', broadcastEvent.location)
        ? broadcastEvent.location.country
        : null,
    get broadcastCountryDetails() {
      return getCountry({ alpha2: this.broadcastCountry })
    },
    get broadcastPlaceHierarchies() {
      return R.has('location', broadcastEvent) && R.has('place_hierarchies', broadcastEvent.location)
        ? R.map((hierarchy) => {
            return new PublicApiEventsEventPlaceHierarchy(hierarchy)
          }, broadcastEvent.location.place_hierarchies)
        : []
    },
    get broadcastPlaces() {
      return R.has('location', broadcastEvent) &&
        R.has('places', broadcastEvent.location) &&
        !R.isEmpty(broadcastEvent.location.places)
        ? broadcastEvent.location.places
        : []
    },
    get formattedAddress() {
      let address = ''
      const venues = R.filter((entity) => ['venue', 'airport'].includes(entity.type), this.entities)

      R.map((entity) => {
        if (address.length === 0 && entity.formattedAddress && entity.formattedAddress.length > 0) {
          address = `${entity.name}\n${entity.formattedAddress}`
        }
      }, venues)

      return address
    },
    get singleLineAddress() {
      return this.formattedAddress.split('\n').join(', ').trim()
    },
    get singleLineLocation() {
      // Get the best available data to describe the location in a single line
      let location = ''

      if (this.singleLineAddress.length > 0) {
        location = this.singleLineAddress
      } else if (this.countryDetails) {
        location = this.countryDetails.name.common
      } else if (this.country) {
        location = this.country
      }

      return location
    },
    scope: R.has('scope', data) ? data.scope : '',
    rank: R.has('rank', data) ? getRankDetails(data.rank) : getRankDetails(null),
    localRank: R.has('local_rank', data) ? getRankDetails(data.local_rank) : getRankDetails(null),
    phqAttendance: R.has('phq_attendance', data) ? data.phq_attendance : null,
    get entities() {
      return R.has('entities', data)
        ? R.map((entity) => {
            return new PublicApiEventsEventEntity(entity)
          }, data.entities)
        : []
    },
    relevance: R.has('relevance', data) ? data.relevance : null, // Only applicable for search results
  }

  return event
}

export default PublicApiEventsBroadcastEvent
