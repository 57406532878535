export const Scroll = {
  beforeMount: (el, binding) => {
    el.scrollCallback = (evt) => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', el.scrollCallback)
      }
    }
  },
  mounted: function (el) {
    window.addEventListener('scroll', el.scrollCallback)
  },
  unmounted: function (el) {
    window.removeEventListener('scroll', el.scrollCallback)
  },
}
