/**
 * Handles maps and coordinates
 * @returns
 */
export default function useMapping() {
  const getCenter = function (arr) {
    const x = arr.map((xy) => xy[0])
    const y = arr.map((xy) => xy[1])
    const cx = (Math.min(...x) + Math.max(...x)) / 2
    const cy = (Math.min(...y) + Math.max(...y)) / 2
    return [cx, cy]
  }

  return { getCenter }
}
