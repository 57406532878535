import * as R from 'ramda'
import { getQueryString } from '@/utils/urlSearchParams'
import distanceConverter from '@/utils/distanceConverter'
import useMapping from '@/utils/useMapping'
import moment from 'moment-timezone'

const today = moment().format('Y-MM-DD')
const ninetyDaysAfterToday = moment().add(90, 'days').format('Y-MM-DD')

const PublicApiSavedLocation = function (data) {
  const location = {
    locationId: data.location_id || null,
    title: data.name || '',
    formattedAddress: data.formatted_address || '',
    phqAttendance: data.phq_attendance || null,
    labels: data.labels || [],
    placeIds: data.place_ids || [],
    places: data.places || [],
    status: data.status || null,
    userId: data.user_id || null,
    subscriptions: data.subscription_valid_types || [],
    createdAt: data.create_dt || null,
    get placeId() {
      return data.place_ids?.length > 0 ? data.place_ids[0] : null
    },
    get place() {
      return data.places?.length > 0 ? data.places[0] : null
    },
    get summaryInsights() {
      const results = data.summary_insights.map((x) => {
        const {
          phq_attendance_sum: phqAttendance,
          attended_event_count: attendedEvents,
          non_attended_event_count: nonAttendedEvents,
          unscheduled_event_count: unscheduledEvents,
          pes_total_sum: predictedEventSpend,
        } = x

        return { phqAttendance, attendedEvents, nonAttendedEvents, unscheduledEvents, predictedEventSpend }
      })

      return results[0]
    },
    get geometry() {
      return data.geojson?.geometry || {}
    },
    get properties() {
      return data.geojson?.properties || {}
    },
    get lat() {
      return this.coords ? this.coords[1] : null
    },
    get lng() {
      return this.coords ? this.coords[0] : null
    },
    get coords() {
      const { getCenter } = useMapping()
      const coords = this.geometry?.coordinates || this.place?.geojson?.geometry?.coordinates

      if (!coords) return ['0.0', '0.0']

      if (Array.isArray(coords[0])) return getCenter(coords[0])

      return coords
    },
    get radiusInMeters() {
      const {
        properties: { radius, radius_unit: unit },
      } = this

      if (!radius || !unit) return null

      if (unit === 'm') return radius

      return distanceConverter(radius, unit, 'm').toFixed()
    },
    get urlParams() {
      const {
        placeId,
        lat,
        lng,
        radiusInMeters,
        properties: { radius, radius_unit },
      } = this
      const coords = lat && lng ? `${lat},${lng}` : null

      const within = radiusInMeters && coords ? `${radiusInMeters}m@${coords}` : null

      const searchEvents = getQueryString({
        'active.gte': today,
        'active.lte': ninetyDaysAfterToday,
        'place.scope': placeId,
        within,
      })

      const notifications = getQueryString({
        name: data.name || '',
        'location.place_ids': placeId,
        'location.origin': coords,
        'location.radius': radius && radius_unit ? `${radius}${radius_unit}` : null,
      })

      return {
        searchEvents,
        notifications,
      }
    },
  }

  return location
}

const PublicApiSavedLocations = function (data) {
  const location = {
    count: R.has('count', data) ? data.count : 0,
    get results() {
      if (R.has('locations', data)) {
        return R.map((e) => {
          return new PublicApiSavedLocation(e)
        }, data.locations)
      }
      return []
    },
  }
  return location
}

export { PublicApiSavedLocations, PublicApiSavedLocation }
