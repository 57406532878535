import axios from 'axios'
import config from '@/app/config'

const baseUrl = `https://${config.CONTENTFUL_HOST}/spaces/${config.CONTENTFUL_SPACE_ID}/environments/${config.CONTENTFUL_ENVIRONMENT}`

async function getEntry(id) {
  return await axios.get(`${baseUrl}/entries/${id}?access_token=${config.CONTENTFUL_ACCESS_TOKEN}`)
}

async function getAsset(id) {
  return await axios.get(`${baseUrl}/assets/${id}?access_token=${config.CONTENTFUL_ACCESS_TOKEN}`)
}

export default { getEntry, getAsset }
