// https://github.com/yahoo/intl-messageformat
// https://formatjs.io/
export default {
  'category.school-holidays.name': 'School holidays',
  'category.school-holidays.description':
    '<a href="https://support.predicthq.com/what-countries-do-you-have-school-holidays-for" target="_blank">See a full list</a> of all the countries we currently have school holiday data for.',
  'category.public-holidays.name': 'Public holidays',
  'category.public-holidays.description':
    'These are special days that usually mean a day off work, like Christmas Day.',
  'category.observances.name': 'Observances',
  'category.observances.description':
    "These are remembrance, awareness and celebration-type days where you don't normally get a day off work, like Valentine's Day.",
  'category.concerts.name': 'Concerts',
  'category.concerts.description':
    'These could be anything from small local gigs at the pub down the road, right through to big-budget international artists.',
  'category.conferences.name': 'Conferences',
  'category.conferences.description':
    'These could be anything from small gatherings to large scale conferences that attract international interest.',
  'category.expos.name': 'Expos',
  'category.expos.description': 'These cover anything from expos, exhibitions, trade shows, trade fairs and the like.',
  'category.terror.name': 'Terror',
  'category.terror.description':
    'This covers confirmed and suspected terrorist attacks mostly in top tourist destinations.',
  'category.sports.name': 'Sports',
  'category.sports.description':
    'These are notable sports matches and championships, like soccer, baseball, tennis, basketball and other major sports.',
  'category.daylight-savings.name': 'Daylight savings',
  'category.daylight-savings.description':
    "If you’re anything like us, you probably like sleeping in. If you're interested we'll tell you when daylight savings begins and ends.",
  'category.airport-delays.name': 'Airport delays',
  'category.airport-delays.description': 'This covers both domestic and international airport delays.',
  'category.severe-weather.name': 'Severe weather',
  'category.severe-weather.description':
    "We're talking cyclones, tornadoes, hurricanes, blizzards, storms, and everything else in between.",
  'category.disasters.name': 'Disasters',
  'category.disasters.description':
    'If disaster strikes in the form of tsunamis, earthquakes, flooding, volcanic eruptions or avalanches, it’s important to be informed.',
  'category.festivals.name': 'Festivals',
  'category.festivals.description':
    'These are event dates for various festivals, carnivals and celebrations happening all around the world.',
  'category.performing-arts.name': 'Performing arts',
  'category.performing-arts.description':
    'These are event dates for plays, theatre performances, musicals, comedy shows and everything in between.',
  'category.community.name': 'Community',
  'category.community.description': 'These cover events such as workshops, classes, lessons and group meetups.',
  'category.politics.name': 'Politics',
  'category.politics.description': 'This covers parliamentary and presidential elections, as well as referendums.',
  'category.academic.name': 'Academic',
  'category.academic.description': '',
  'category.health-warnings.name': 'Health warnings',
  'category.health-warnings.description':
    'This covers warnings about infectious diseases including disease outbreaks and pandemics at the city, region, country or worldwide level.',

  'rank.level1.name': 'Minor',
  'rank.level2.name': 'Moderate',
  'rank.level3.name': 'Important',
  'rank.level4.name': 'Significant',
  'rank.level5.name': 'Major',

  'event.duration.days': '{days, plural, =1 {1 day} other {# days}}',
}
