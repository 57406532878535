import * as R from 'ramda'
import { getCountry } from '@/app/countries'

function PublicApiPlacesPlace(data) {
  const country = R.has('country_alpha2', data) ? data.country_alpha2 : null
  const lat = R.has('location', data) && R.has(1, data.location) ? data.location[1] : 0.0
  const lng = R.has('location', data) && R.has(0, data.location) ? data.location[0] : 0.0
  const countryDetails = getCountry({ alpha2: country })
  const location = {
    lat: lat,
    lng: lng,
    coords: [lng, lat],
  }

  const place = {
    placeId: R.has('id', data) ? data.id : null,
    name: R.has('name', data) ? data.name : '',
    type: R.has('type', data) ? data.type : null,
    region: R.has('region', data) ? data.region : null,
    county: R.has('county', data) ? data.county : null,
    country,
    countryAlpha3: R.has('country_alpha3', data) ? data.country_alpha3 : null,
    countryDetails,
    lat,
    lng,
    location,
  }

  return place
}

export default PublicApiPlacesPlace
